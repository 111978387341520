import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core";
import { MDBCol } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { nextAppointmentSection } from "../../../../redux/actions/data.actions";
/**
 * @author
 * @function Step2
 **/

const Step2 = ({ auth, ...props }) => {
  const [checkStatus, setCheckStatus] = useState(false);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  useEffect(() => {
    if (auth?.userData) {
      setEmail(auth?.userData.user.email);
      setName(auth?.userData.user.email.split("@")[0]);
      setPhone(auth?.phone);
    }
  }, [auth?.userData]);
  const nextSection = () => {
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      alert("Plese enter a valid email address!");
    } else if (name === "") {
      alert("Plese enter a valid Name");
    } else {
      const nextData = { name, email, phone, notes, checkStatus };
      dispatch({ type: "SET_DATA_SUCCESS", payload: nextData });
      //  dispatch(nextAppointmentSection(nextData));
      props.onClick();
    }
  };
  const handleCheck = () => {
    setCheckStatus(!checkStatus);
  };
  const GreenCheckbox = withStyles({
    root: {
      color: "#403A60",
      "&$checked": {
        color: "#403A60",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  return (
    <div>
      <Form>
        <Form.Row className="flex-column flex-md-row">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label className="text-left">Name</Form.Label>
            <Form.Control
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Phone</Form.Label>
            <PhoneInput
              disableDropdown
              autoFormat
              inputClass="react-tel-input"
              country={"us"}
              value={phone}
              onChange={(val) => {
                setPhone(!val.startsWith("1") ? "1" + val : val);
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Label>Notes</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Notes for appointment"
          onChange={(e) => setNotes(e.target.value)}
        />
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={checkStatus}
              onChange={handleCheck}
              name="checkedG"
            />
          }
          label={
            <p className="p-0 m-0">
              I agree to the{" "}
              <a target="_blank" href="/terms">
                terms & conditions
              </a>{" "}
              and{" "}
              <a target="_blank" href="/privacy">
                the privacy policy
              </a>{" "}
            </p>
          }
        />
      </Form>
      <button
        className="cService mt-3 px-3 p-2"
        onClick={nextSection}
        disabled={!checkStatus}
      >
        Continue
      </button>
    </div>
  );
};

export default connect((state) => state)(Step2);
