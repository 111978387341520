import React, { useState } from "react";
import { Card, Modal, Row } from "react-bootstrap";
import cardBack from "../../drawable/cardBackgroundImg.png";
import "./styles.css";
import fullscreen from "../../drawable/fullscreen.png";
import download from "../../drawable/download.png";

import FsLightbox from "fslightbox-react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
/**
 * @author
 * @function ItemCard
 **/

function ShowLightBox({ image, description }) {
  const [toggler, setToggler] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  return (
    <>
      <Card style={{ width: "18rem", marginLeft: "20px", position: "relative", paddingBottom: 10 }}>
        <Card.Img variant="top" src={image} />
        {/* <Card.ImgOverlay className="overlayProperties"> */}
        <div className="transparenButtons">
          <Row>
            <img src={fullscreen} className="fullScreenIcon" alt="back" onClick={() => setToggler(!toggler)} style={{ cursor: "pointer" }} />

            <p className="tItemText">Fullscreen</p>
            <a style={{ color: "#fff" }} className="d-flex" href={image} target="_blank" download>
              <img src={download} className="downloadIcon" alt="back" />
              <p className="tItemText">Download</p>
            </a>
          </Row>
        </div>
        {/* </Card.ImgOverlay> */}
        <Card.Body>
          <Card.Text className="pb-2">{description}</Card.Text>
          {/* <p className="seeMore">see more</p> */}
        </Card.Body>
      </Card>
      <div>{toggler && <Lightbox mainSrc={image} imageCaption={description} onCloseRequest={() => setToggler(false)} />}</div>
    </>
  );
}

const ItemCard = (props) => {
  const { note } = props;
  return (
    <div>
      <ShowLightBox image={note.noteUrl} description={note.description} />
    </div>
  );
};

export default ItemCard;
