import React, { useEffect, useState } from "react";
import { MDBCol, MDBDataTable, MDBIcon } from "mdbreact";
import TogleSwitches from "../../components/TogleSwitches";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addConsultant,
  deleteConsultant,
  getConsultantData,
} from "../../redux/actions/consultant.actions";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { LightService } from "../AllServices";
import { getFormData, getServiceData } from "../../redux/actions/data.actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
/**
 * @author
 * @function DataTable
 **/

const DataTable = (props) => {
  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 270,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Services",
        field: "services",
        sort: "asc",
        width: 100,
      },
    ],

    rows: [],
  };

  const dispatch = useDispatch();
  const [confirmModalShow, setconfirmModalShow] = React.useState(false);
  const [documentId, setDocumenId] = React.useState("");
  const cData = useSelector((state) => state.consultant);
  const [consultantData, setConsultantData] = useState([]);
  const [showDisabled, setShowDisabled] = React.useState(false);
  useEffect(() => {
    dispatch(getConsultantData());
  }, []);
  useEffect(() => {
    setConsultantData(cData.consultantData);
  }, [cData]);

  if (consultantData != null) {
    for (let i = 0; i < consultantData.length; i++) {
      if (consultantData[i].active) {
        data.rows.push({
          clickEvent: (data, other) => {
            const dx = consultantData[i].id;
            props.h.push("/ExpertConsultantdetails", dx);
          },

          name: consultantData[i].name,
          phone: consultantData[i].phone,
          email: consultantData[i].email,
          services: (
            <div className="d-flex flex-row justify-evenly">
              {consultantData[i].services.map((s) => (
                <div className="d-flex flex-row justify-evenly">
                  <p>{s} , </p>
                </div>
              ))}
              <TogleSwitches
                checkStatus={consultantData[i].active}
                docId={consultantData[i].id}
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  setconfirmModalShow(true);
                  setDocumenId(consultantData[i].id);
                }}
              />
            </div>
          ),
        });
      } else if (showDisabled && !consultantData[i].active) {
        data.rows.push({
          clickEvent: (data, other) => {
            const dx = consultantData[i].id;
            props.h.push("/ExpertConsultantdetails", dx);
          },

          name: consultantData[i].name,
          phone: consultantData[i].phone,
          email: consultantData[i].email,
          services: (
            <div className="d-flex flex-row justify-evenly">
              {consultantData[i].services.map((s) => (
                <div className="d-flex flex-row justify-evenly">
                  <p>{s} , </p>
                </div>
              ))}
              <TogleSwitches
                checkStatus={consultantData[i].active}
                docId={consultantData[i].id}
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  setconfirmModalShow(true);
                  setDocumenId(consultantData[i].id);
                }}
              />
            </div>
          ),
        });
      }
    }
  }
  const [addModalShow, setAddModalShow] = React.useState(false);
  return (
    <div>
      <ConfirmModal
        show={confirmModalShow}
        onHide={() => setconfirmModalShow(false)}
        confirm={() => {
          setconfirmModalShow(false);
          dispatch(deleteConsultant(documentId));
          dispatch(getConsultantData());
          setConsultantData(cData.consultantData);
        }}
      />
      <AddNewModal
        show={addModalShow}
        onHide={() => setAddModalShow(false)}
        refreshList={() => {
          dispatch(getConsultantData());
          setConsultantData(cData.consultantData);
        }}
      />
      <Row>
        <p className="serviceStyling p-3">Expert Consultants</p>
        <div className="ml-auto p-3 ">
          <button
            className="ml-auto addNewStyling p-2"
            onClick={() => setAddModalShow(true)}
          >
            Add
          </button>
        </div>
      </Row>
      <Row>
        <Col
          xs={12}
          className="d-flex align-items-center flex-row justify-content-end"
        >
          <Form.Group className="m-0 mr-3">
            <Form.Check
              onChange={(e) => setShowDisabled(e.target.checked)}
              type="checkbox"
              label="Show Disabled Users"
            />
          </Form.Group>
        </Col>
      </Row>
      <MDBDataTable
        striped
        bordered
        small
        hover
        sortable
        data={data}
        clickEvent={(id) => {}}
      />
    </div>
  );
};
function ConfirmModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <p>Are you sure you want to delete?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="pCancel" onClick={props.confirm}>
          Yes
        </button>
        <button className="pApprove" onClick={props.onHide}>
          No
        </button>
      </Modal.Footer>
    </Modal>
  );
}
function AddNewModal(props) {
  const [selected, setSelected] = useState([]);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const dispatch = useDispatch();
  const [service, setService] = useState([]);
  const [showAllServices, setShowAllServices] = useState([]);
  const [toShow, setToShow] = useState(0);
  const [showAll, setShowAll] = useState(0);
  const data = useSelector((state) => state.data);

  useEffect(() => {
    if (data.data !== null) {
      setService(data.data.Services);
    }
    if (data.allServices !== null) {
      const servicesToPush = [];
      data.allServices.forEach((e) => servicesToPush.push(...e.services));
      setShowAllServices(servicesToPush);
    }
  }, [data]);

  const handleSelection = (e) => {
    if (e.target.value == 0) {
      setShowAll(0);
    } else {
      const name = e.target.value;
      const services = data.allServices.find(
        (ser) => ser.name == name
      ).services;
      setService(services);
      setShowAll(name);
    }
  };
  const addInspector = () => {
    const services = selected;
    const nextData = { name, email, phone: "+" + phone, services };
    dispatch(addConsultant(nextData));
    props.onHide();
    props.refreshList();
  };

  useEffect(() => {
    if (toShow === 0) {
      dispatch(getServiceData());
      setToShow(1);
    }
  }, []);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Consultant
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="input"
                placeholder="Enter Name"
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Phone</Form.Label>
              <PhoneInput
                // inputClass="react-tel-input"
                // country={"pk"}
                // value={phone}
                // onChange={(phone) => setPhone(phone)}
                disableDropdown
                autoFormat
                inputClass="react-tel-input"
                country={"us"}
                value={phone}
                onChange={(val) => {
                  setPhone(!val.startsWith("1") ? "1" + val : val);
                }}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className="w-50">
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="input"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <MDBCol>
              <form className="form-inline mr-sm-2">
                <MDBIcon icon="search" />
                <input
                  className="form-control form-control-sm ml-3 w-75"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                />
              </form>
            </MDBCol>
            <Col>
              <Form.Control
                as="select"
                className="mr-sm-2"
                id="inlineFormCustomSelect"
                custom
                onChange={handleSelection}
              >
                <option value="0">Select service Category...</option>
                {data?.allServices?.map((e, i) => (
                  <option value={e.name}>{e.name}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Row>
        </Form>
        <Container className="mt-3" style={{ overflowY: "scroll" }}>
          <Row>
            {showAll === 0
              ? showAllServices?.map((serviceData, i) => (
                  <LightService
                    key={i}
                    selected={selected.includes(serviceData.name)}
                    onClick={() => {
                      const isSelected = selected.includes(serviceData.name);
                      if (isSelected) {
                        setSelected(
                          selected.filter((e) => e !== serviceData.name)
                        );
                      } else {
                        setSelected([...selected, serviceData.name]);
                      }
                    }}
                    data={serviceData}
                  />
                ))
              : service.map((serviceData) => (
                  <LightService
                    selected={selected.includes(serviceData.name)}
                    onClick={() => {
                      const isSelected = selected.includes(serviceData.name);
                      if (isSelected) {
                        setSelected(
                          selected.filter((e) => e !== serviceData.name)
                        );
                      } else {
                        setSelected([...selected, serviceData.name]);
                      }
                    }}
                    data={serviceData}
                  />
                ))}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={props.onHide} className="CloseButton p-2">
          Close
        </button>
        <button className="submitButton p-2" onClick={addInspector}>
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DataTable;
