import React from "react";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";

export default function PhoneNumberInput({ onChange, value }) {
  return (
    <Form.Group className="nameTexts" controlId="formBasicEmail">
      <Form.Label>Phone number</Form.Label>
      <PhoneInput
        disableDropdown
        autoFormat
        inputClass="react-tel-input"
        country={"us"}
        value={value}
        onChange={onChange}
      />
    </Form.Group>
  );
}
