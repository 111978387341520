import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ItemCard from "../../components/itemCards";
import Services from "../../components/services";
import { getAppointmentDetails } from "../../redux/actions/data.actions";
import "./styles.css";

/**
 * @author
 * @function PastAppointments
 **/

const PastAppointments = (props) => {
  const [days, setDays] = useState("");
  const [hours, setHours] = useState("");
  const [mins, setMins] = useState("");
  const [displayDate, setDisplayDate] = useState("");
  const dispatch = useDispatch();
  var toCountdown = useSelector(
    (state) => state.nextAppointment.appointmentDate
  );
  const appointmentServices = useSelector(
    (state) => state.nextAppointment.appointmentServices
  );
  const [services, setServices] = useState([]);
  const appointmentID = useSelector(
    (state) => state.appointment.appointmentIDtoSend
  );
  const appointmentDescription = useSelector(
    (state) => state.appointment.appointmentDescription
  );
  useEffect(() => {
    setDisplayDate(toCountdown);
    dispatch(getAppointmentDetails(appointmentID));
    var countDownDate = new Date(toCountdown).getTime();
    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      if (days < 0) {
        setDays(("0" + -days).slice(-2));
        setHours(("0" + hours).slice(-2));
        setMins(("0" + minutes).slice(-2));
      } else {
        setDays(("0" + days).slice(-2));
        setHours(("0" + hours).slice(-2));
        setMins(("0" + minutes).slice(-2));
      }
    }, 1000);
  }, [toCountdown]);
  useEffect(() => {
    if (appointmentServices !== null) {
      setServices(appointmentServices);
    }
  }, [appointmentServices]);
  return (
    <div>
      {/* main banner */}
      <div className="bannerStyle text-center d-flex flex-column">
        <p className="bannerDate">PastAppointments</p>
        <Row className="text-center align-self-center">
          <Col className="coloumCountStyle">
            <h1 className="countdownStyle">00</h1>
            <p className="days pt-2">days</p>
          </Col>
          <Col className="coloumCountStyle">
            <h1 className="countdownStyle">00 </h1>
            <p className="days pt-2">hours</p>
          </Col>
          <Col className="coloumCountStyle">
            <h1 className="countdownStyle">00</h1>
            <p className="days pt-2">minutes</p>
          </Col>
        </Row>

        <p className="bannerDate">{displayDate}</p>
      </div>
      {/* SERVICES */}
      <div>
        <div>
          <p className="serviceStyling">Services</p>
        </div>
        <row className="d-flex flex-row align-item-center">
          {services != null ? <Services services={services} /> : <></>}
        </row>
      </div>

      <p className="lineSeperater"></p>
      <div>
        <p className="serviceStyling">Description</p>
        <Card.Text className="mx-4">
          {appointmentDescription?.description}
        </Card.Text>
      </div>
      <p className="lineSeperater"></p>
      {/* NOTES */}
      <div className="p-2">
        <p className="serviceStyling">Notes</p>
        <Row>
          {appointmentDescription?.meetingNotes?.map((e) => (
            <ItemCard note={e} />
          ))}
        </Row>
      </div>
      <p className="lineSeperater"></p>
      <div className="p-2">
        <p className="serviceStyling">List</p>
        <ul>
          {appointmentDescription?.list?.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PastAppointments;
