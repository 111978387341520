import * as fire from "firebase";
export const uploadLandingScreenImage = async (uri, uid) => {
  const storage = fire.default.storage;
  const response = await fetch(uri);
  const blob = await response.blob();
  var ref = storage().ref("landing_page_images/").child(uid);
  const file = await ref.put(blob);
  return await storage()
    .ref("landing_page_images/")
    .child(uid)
    .getDownloadURL();
};
const uploadImage = async (uri, uid) => {
  const storage = fire.default.storage;
  const response = await fetch(uri);
  const blob = await response.blob();
  var ref = storage().ref("meeting_notes/").child(uid);
  const file = await ref.put(blob);
  return await storage().ref("meeting_notes/").child(uid).getDownloadURL();
};

export const addNote = async (uid, uri, description) => {
  try {
    const firestore = fire.default.firestore;
    const db = firestore();
    const response = await db.collection("appointments").doc(uid).get();
    const notes = response.data().meetingNotes
      ? response.data().meetingNotes
      : [];
    const noteUrl = await uploadImage(uri, Date.now() + "_" + notes.length);
    notes.push({ description, noteUrl });
    await db
      .collection("appointments")
      .doc(uid)
      .update({ meetingNotes: notes });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
