import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrentDiv from "../../components/ScrolingDivs/CurrentDiv";
import FutureAppointments from "../../components/ScrolingDivs/FutureAppointments";
import NextAppointments from "../../components/ScrolingDivs/NextAppointments";
import PastAppointments from "../../components/ScrolingDivs/PastAppointments";
import {
  getAppointmentDates,
  getAppointments,
} from "../../redux/actions/data.actions";
import moment from "moment";
import * as fire from "firebase";
import Requests from "../../components/ScrolingDivs/Requests";
/**
 * @author
 * @function DashBoard
 **/
const timezone = "America/Chicago";

const DashBoard = (props) => {
  const [appointmentDate, setAppointmentdate] = useState([]);
  const [appointmentIDs, setAppointmentIDs] = useState([]);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.appointment.appointmentData);
  const pendingData = useSelector(
    (state) => state.appointment.appDataForOperator
  );
  const auth = useSelector((state) => state.auth);

  const [timeframe, setTimeframe] = useState(1);
  const [price, setPrice] = useState(1);

  const getMeetingInfo = async () => {
    const db = fire.default.firestore();
    const res = await db.collection("meetingValues").doc("data").get();
    const val = res.data();
    setTimeframe(val.timeframe);
    setPrice(val.price);
  };
  useEffect(() => {
    getMeetingInfo();
  }, []);
  const [futureAppointmentDates, setFutureAppointmentDates] = useState();
  const [past, setPast] = useState();
  const [futureAppointmentID, setFutureAppointmentID] = useState();
  const [pastID, setPastID] = useState();
  var futureApps = [];
  var pastApps = [];
  var futureAppId = [];
  var pastAppID = [];

  useEffect(() => {
    dispatch(getAppointmentDates());
  }, []);
  useEffect(() => {
    dispatch(getAppointments());
  }, []);
  useEffect(() => {
    if (data.dates != null) {
      setAppointmentdate(data.dates);
      setAppointmentIDs(data.ids);
    }
  }, [data]);
  useEffect(() => {
    if (appointmentDate !== null) {
      var today = new Date(); //Today Date

      for (var i = 0; i < appointmentDate.length; i++) {
        var appDate = new Date(appointmentDate[i]);

        if (appDate !== undefined) {
          if (today < appDate) {
            futureApps.push(appointmentDate[i]);
            futureAppId.push(appointmentIDs[i]);
          } else {
            pastApps.push(appointmentDate[i]);
            pastAppID.push(appointmentIDs[i]);
          }
        }
      }
      setFutureAppointmentDates(futureApps);
      setFutureAppointmentID(futureAppId);
      setPast(pastApps);
      setPastID(pastAppID);
    }
  }, [appointmentDate]);
  const currentAppointments = appointmentDate
    ?.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    })
    ?.filter((e) => {
      const aptDate = new Date(e.date);
      const today = new Date();
      const meetingLength = Number(e.meetingLength) * Number(timeframe);
      // const timeCondition = aptDateInSeconds - (60*5) <= todayDateInSeconds &&
      const timeCondition =
        moment(aptDate).subtract(5, "minutes") <= moment() &&
        moment(aptDate).add(meetingLength, "minutes") >= moment();

      return timeCondition;
    });
  const futureApts = appointmentDate
    ?.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    })
    ?.filter((e) => {
      const aptDate = moment(new Date(e.date)).tz(timezone);
      const today = moment().tz(timezone);

      return aptDate.isAfter(today, "day");
    });
  const previousAppointments = appointmentDate
    ?.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    })
    ?.filter((e) => {
      const aptDate = new Date(e.date);
      const today = new Date();
      return aptDate < today;
    });
  const nextAppointments = appointmentDate.filter((e) => {
    const aptDate = moment(new Date(e.date)).tz(timezone);
    const today = moment().tz(timezone);
    const meetingLength = Number(e.meetingLength) * Number(timeframe);

    const timeCondition =
      aptDate.subtract(5, "minutes") <= moment() &&
      aptDate.add(meetingLength, "minutes") >= moment();
    return aptDate.isSame(today, "day") && !timeCondition;
  });
  const reqs =
    auth.userData.user.type == "consultant"
      ? pendingData.filter(
          (e) =>
            moment(new Date(e.date))
              .tz(timezone)
              .isAfter(moment().tz(timezone)) &&
            e?.qualifiedConsultants[0]?.id == auth?.userData.user?.consultantId
        )
      : [];
  return (
    <div>
      <Requests dates={reqs} ids={reqs.map((e) => e.id)} h={props.history} />

      <CurrentDiv
        dates={currentAppointments}
        ids={currentAppointments.map((e) => e.id)}
        h={props.history}
      />
      {/* Todays APPOINTMENTS */}
      <NextAppointments
        dates={nextAppointments}
        ids={currentAppointments.map((e) => e.id)}
        h={props.history}
      />
      {/* FUTURE APPOINTMENTS */}
      <FutureAppointments
        dates={futureApts}
        ids={futureApts.map((e) => e.id)}
        h={props.history}
      />

      {/* PAST APPOINTMENTS */}
      <PastAppointments
        dates={previousAppointments}
        ids={previousAppointments.map((e) => e.id)}
        h={props.history}
      />
    </div>
  );
};

export default DashBoard;
