import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import hammer from "../../drawable/hammer.png";
import Lighthammer from "../../drawable/lightHammer.png";
import "./styles.css";
/**
 * @author
 * @function AllServices
 **/

export const LightService = (props) => {
  return (
    <Col onClick={props.onClick} xs={4} md={3} className="servicePad">
      <Row
        style={{
          height: "100%",
        }}
        className={!props.selected ? "lServiceStyle" : "dServiceStyle"}
      >
        <img src={props.data.image} className="hammerIcon" alt="back" />
        <p className={!props.selected ? "lightText" : "darkText"}>
          {props.data.name}
        </p>
      </Row>
    </Col>
  );
};
export const DarkService = (props) => {
  return (
    <Col xs={4} md={3} className="servicePad">
      <Row className="dServiceStyle">
        <img src={Lighthammer} className="hammerIcon" alt="back" />
        <p className="darkText">Name of Service</p>
      </Row>
    </Col>
  );
};
