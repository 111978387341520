import React, { useEffect, useState } from "react";

import AboutUs from "../../components/LandingPage/AboutUs";
import BookApointment from "../../components/LandingPage/bookAnAppointment";
import CustomCarocel from "../../components/LandingPage/Carosel";
import ContactUs from "../../components/LandingPage/ContactUs";
import Footer from "../../components/LandingPage/Footer";
import OurApp from "../../components/LandingPage/OurApp";
import OurServices from "../../components/LandingPage/OurServices";
import "./styles.css";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { HashLoader } from "react-spinners";
import * as fire from "firebase";
import axios from "axios";
const stripePromise = loadStripe(
  "pk_live_51Hq1l8Hfj76zCsJr1UdK2LN87wOw0cJDN2aXWM6bgLG8R13V2Dj4SyM51EFcd3BYdMbukw1rMVtZJ6rFpRq9yR2q009wumUjF2"
);

/**
 * @author
 * @function LandingPage
 **/
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};
const LandingPage = (props) => {
  const [data, setData] = useState(null);
  const [timeframe, setTimeframe] = useState(1);
  const [price, setPrice] = useState(1);
  const [reset, setReset] = useState(false);
  const getData = async () => {
    const timeout = setTimeout(() => [setReset(true)], 15000);
    const res = await axios.post(
      "https://us-central1-cottage-and-manor.cloudfunctions.net/getContent"
    );
    setData(res.data.content);
    clearTimeout(timeout);
  };
  const restart = () => {
    localStorage.clear();
    window.location.reload();
  };
  const getMeetingInfo = async () => {
    const res = await axios.post(
      "https://us-central1-cottage-and-manor.cloudfunctions.net/getBasicData"
    );
    setTimeframe(res.data.content.baseTime);
    setPrice(res.data.content.basePrice);
  };
  useEffect(() => {
    getData();
    getMeetingInfo();
  }, []);
  return data ? (
    <div className="landing-page">
      <div id="home">
        <CustomCarocel content={data.Banner} />
      </div>
      <div id="aboutUs">
        <AboutUs content={data.about} />
      </div>
      <Elements stripe={stripePromise}>
        <div className="padding-top" id="bookAppointment">
          <AlertProvider template={AlertTemplate} {...options}>
            <BookApointment
              timeframe={timeframe}
              price={price}
              content={data.booking}
            />
          </AlertProvider>
        </div>
      </Elements>

      <div id="ourServices" className="padding-top">
        <OurServices content={data.services} />
      </div>
      <div id="ourApp" className="padding-top">
        <OurApp />
      </div>

      <div id="contactUs" className="padding-top">
        <ContactUs />
      </div>

      <Footer />
    </div>
  ) : (
    <div
      style={{
        width: "100vw",
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <HashLoader size={50} color={"#403a60"} loading={!data} />
      {reset && (
        <div className="d-flex flex-column mt-4">
          <p>
            It seems to be taking longer than expected.{" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#403a60",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={restart}
            >
              Click here to restart
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
