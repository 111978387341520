import { MDBCol, MDBContainer, MDBFooter, MDBRow } from "mdbreact";
import React, { useEffect, useState } from "react";
import * as fire from "firebase";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import socialGroup from "../../../drawable/socialGroup.png";
import "./styles.css";
import { Link } from "react-router-dom";
import axios from "axios";
/**
 * @author
 * @function Footer
 **/

const Footer = (props) => {
  const [data, setData] = useState(null);

  const getData = async () => {
    const res = await axios.post(
      "https://us-central1-cottage-and-manor.cloudfunctions.net/getBasicData"
    );
    const responseData = res.data;
    if (responseData) {
      setData({
        tagline: responseData.tagline,
        facebookLink: responseData.facebookLink,
        twitterLink: responseData.twitterLink,
        phoneNumber: `tel:${responseData.phoneNumber}`,
        whatsappNumber: `whatsapp://send?text=hello&phone=${responseData.whatsAppNumber}`,
        terms: responseData.terms,
        homeText: responseData.homeText,
      });
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <MDBFooter className="footerBox pt-4 mt-4 ">
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          <p className="TOCStyle">
            By using our website, you agree to our{" "}
            <Link to="/terms" className="mx-1" target="_blank">
              Terms & conditions
            </Link>
            and
            <Link to="/privacy" className="mx-1" target="_blank">
              Privacy Policies
            </Link>
          </p>
          <div className="d-flex align-items-center justify-content-center mb-3">
            <a href={data?.linkedInLink} className="mx-1" target="_blank">
              <FaLinkedinIn color={"#fff"} />
            </a>
            <a href={data?.instaLink} className="mx-1" target="_blank">
              <FaInstagram color={"#fff"} />
            </a>
            <a href={data?.fbLink} className="mx-1" target="_blank">
              <FaFacebook color={"#fff"} />
            </a>
            {/* <a href={data?.twitterLink} className="mx-1" target="_blank">
                            <FaTwitter color={'#fff'} />
                        </a> */}
          </div>
          <p className="copyrightStyle">
            © Copyright {new Date().getFullYear()} Cottage and Manor. All Rights
            Reserved
          </p>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default Footer;
