import React from "react";
import { Card, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.css";
/**
 * @author
 * @function PersonCards
 **/
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
const PersonCards = (props) => {
  const dispatch = useDispatch();
  return (
    <Carousel
      renderButtonGroupOutside
      responsive={responsive}
      removeArrowOnDeviceType={["tablet", "mobile"]}
    >
      {props.dataToRender?.map((d) => (
        <Card className="mx-2">
          <Card.Body>
            <Card.Title>
              <Row>
                <p className="pName ml-3">{d.name}</p>
                <p className="pId mx-2">({d.appointmentID})</p>
                <p className="pDate ml-auto">{d.date}</p>
              </Row>
              {d.consultants?.map((c) => (
                <p className="inspec mb-3">{c.name}</p>
              ))}
            </Card.Title>

            <Card.Text>
              <p className="pSeperater"></p>
              {d.description}
            </Card.Text>
            <p className="pSeperater"></p>

            <button
              className="pApprove p-2"
              onClick={() => {
                dispatch({
                  type: "SEND_APP_ID_FOR_MODIFICATION",
                  payload: d.appointmentID,
                });
                props.h.push("/appointmentstoreview");
              }}
            >
              Go to appointment
            </button>
          </Card.Body>
        </Card>
      ))}
    </Carousel>
  );
};

export default PersonCards;
