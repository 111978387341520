import { Container } from "@material-ui/core";
import React from "react";
import DataTable from "../../components/DataTable";

import "./styles.css";
/**
 * @author
 * @function Inspector
 **/

const ExpertConsultant = (props) => {
  return (
    <div fluid className="px-3 w-100 ">
      <DataTable h={props.history} />
    </div>
  );
};

export default ExpertConsultant;
