import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./styles.css";
import logo from "../../../drawable/Logo.png";
import previous from "../../../drawable/Vector.png";

/**
 * @author
 * @function Confermation
 **/

const Confermation = (props) => {
  return (
    <Container fluid>
      <Row>
        <img src={previous} className="mPrevious" alt="back" />
        <Col md="6" xs={1} className="px-3 px-md-0 mainContainer">
          <img src={logo} className="mLogo" alt="logo" />
          <Form className="w-50">
            <Form.Group className="nameTexts" controlId="formBasicEmail">
              <Form.Label>Phone number</Form.Label>
              <Form.Control placeholder="Enter your phone number" />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <button type="button" className="mBtn p-2">
              SEND CODE
            </button>
          </Form>
        </Col>
        <Col className="d-none d-md-block">
          <div className="Login-component"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default Confermation;
