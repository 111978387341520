import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Footer from '../../components/LandingPage/Footer';

const TERMS = [
    {
        title: 'OVERVIEW',
        content: (
            <div>
                <p>
                    Your privacy is important to COTTAGE AND MANOR, LLC (“COTTAGE AND MANOR”, “we”, or “us”). This Privacy Policy describes the personal data we collect, how it’s
                    used and shared, and your choices regarding this data.
                </p>
                <p>
                    This policy describes how COTTAGE AND MANOR and its affiliates collect and use personal data. This policy applies to all users of our apps, websites, features,
                    or other services anywhere in the world (“Users”), unless covered by a separate privacy notice.
                </p>
                <p>
                    {' '}
                    This notice also governs COTTAGE AND MANOR’s collection of personal data in connection with COTTAGE AND MANOR’s services. For example, we may collect the
                    contact information of individuals who use accounts owned by COTTAGE AND MANOR for business customers or hosts, or we may collect other personal data in
                    connection with our mapping technology, video technology, and features.
                </p>
            </div>
        ),
    },
    {
        title: 'Data collections and uses.',
        content: (
            <div>
                <ol type="A">
                    <li className="h6">The data we collect</li>
                    <p>COTTAGE AND MANOR collects:</p>
                    <ul>
                        <li>Data provided by users to COTTAGE AND MANOR, such as during account creation</li>
                        <li>Data created during use of our services, such as location, app usage, video content, and device data</li>
                        <li>Data from other sources, such as COTTAGE AND MANOR partners and third parties that use COTTAGE AND MANOR APIs</li>
                    </ul>
                </ol>

                <p>We will inform you when we collect it whether you are required to provide certain data to us.</p>
                <p>The following data is collected by or on behalf of COTTAGE AND MANOR:</p>
                <ol type="i">
                    <li>
                        <p>
                            <strong className="text-underlined">Data provided by users.</strong>This includes:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <strong>User/User profile:</strong>
                                    We collect data when users create or update their COTTAGE AND MANOR accounts. You are required to provide this information to use COTTAGE AND
                                    MANOR’s services. This may include their name, email, phone number, login name and password, address, and payment or banking information
                                    (including related payment verification information). This also includes vehicle or insurance information of drivers and delivery partners, and
                                    user settings. For more information, please see the section titled “How we use personal data.”
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Demographic data:</strong>
                                    We may collect demographic data about users, including through user surveys.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>User content:</strong>
                                    We collect the information users submit when they contact COTTAGE AND MANOR. This may include feedback, photographs, and recordings collected by
                                    a User.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            <strong className="text-underlined">Data created during use of our services.</strong>This includes:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <strong>Location data:</strong>
                                    We collect precise or approximate location data from a user’s mobile device if enabled by the user to do so. COTTAGE AND MANOR may collect this
                                    data when the COTTAGE AND MANOR app is running in the foreground (app open and on-screen) or background (app open but not on-screen) of their
                                    mobile device. Users may use the COTTAGE AND MANOR apps without enabling COTTAGE AND MANOR to collect location data from their mobile devices.
                                    However, this may affect some functionality available in the COTTAGE AND MANOR apps.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Transaction information:</strong>
                                    We collect transaction information related to the use of our services, including the type of services requested or provided, date and time the
                                    service was provided, amount charged, location information, and payment method.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Usage data:</strong>
                                    We collect data about how users interact with our services. This includes data such as access dates and times, app features or pages viewed, app
                                    crashes and other system activity, type of browser, and third-party sites or services used before interacting with our services. In some cases,
                                    we collect this data through cookies, pixels, tags, and similar tracking technologies that create and maintain unique identifiers. To learn more
                                    about these technologies, please see our Cookie Notice.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Device data:</strong>
                                    We may collect data about the devices used to access our services, including the hardware models, device IP address, operating systems and
                                    versions, software, preferred languages, unique device identifiers, advertising identifiers, serial numbers, device motion data, and mobile
                                    network data.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Communications data:</strong>
                                    We enable users to communicate with each other and COTTAGE AND MANOR through COTTAGE AND MANOR’s mobile apps and websites. To provide this
                                    service, COTTAGE AND MANOR receives and records data, video, and audio information and files. COTTAGE AND MANOR may also use this data for
                                    customer support services (including to resolve disputes), for safety and security purposes, to improve our products and services, and for
                                    analytics.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            <strong className="text-underlined">Data from other sources. </strong>This includes:
                        </p>
                        <ul>
                            <li>
                                <p>User feedback, such as ratings, feedback, or compliments.</p>
                            </li>
                            <li>
                                <p>Users or others providing information in connection with claims or disputes.</p>
                            </li>
                            <li>
                                <p>
                                    COTTAGE AND MANOR business partners through which users create or access their COTTAGE AND MANOR account, such as payment providers, social
                                    media services, or apps or websites that use COTTAGE AND MANOR’s APIs or whose APIs COTTAGE AND MANOR uses.
                                </p>
                            </li>
                            <li>
                                <p>Publicly available sources.</p>
                            </li>
                            <li>
                                <p>Marketing service providers.</p>
                            </li>
                        </ul>{' '}
                        <p>COTTAGE AND MANOR may combine the data collected from these sources with other data in its possession.</p>
                    </li>
                </ol>
            </div>
        ),
    },
];
export default function PrivacyPolicy() {
    return (
        <>
            <Container fluid>
                <Row className="px-3">
                    <Col xs={12}>
                        <p className="nApps">PRIVACY POLICY</p>
                    </Col>
                    <Col xs={12}>
                        <ol>
                            {TERMS.map(e => (
                                <>
                                    <li className=" h4 my-4">{e.title}</li>
                                    {e.content}
                                </>
                            ))}
                        </ol>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
}
