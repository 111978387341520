import React from "react";

import Ticker from "react-ticker";
/**
 * @author
 * @function LandingPageHeadLine
 **/

const LandingPageHeadLine = (props) => {
  return (
    <div className="headLineDiv">
      <Ticker>
        {({ index }) => (
          <div>
            <div>
              <p className="headLineStyling">
                {props.headlines.map((e) => (
                  <p className=" mx-5 my-3">{e}</p>
                ))}
              </p>
              {/* <p className="headLineStyling mx-5 my-3">loda</p> */}
            </div>
          </div>
        )}
      </Ticker>
    </div>
  );
};

export default LandingPageHeadLine;
