import React, { useEffect, useState } from "react";
import clock from "../../drawable/clock.png";
import rightArrow from "../../drawable/arrowRight.png";

import "./styles.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch } from "react-redux";
/**
 * @author
 * @function CurrentDiv
 **/

const CurrentDiv = (props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const dispatch = useDispatch();
  return (
    <div>
      {props.dates.length > 0 ? (
        <p className="welcome p-3">Current Appointments!</p>
      ) : (
        <p className="welcome p-3">
          No appointments available for joining right now
        </p>
      )}

      <Carousel
        renderButtonGroupOutside
        responsive={responsive}
        removeArrowOnDeviceType={["tablet", "mobile"]}
      >
        {props.dates != null ? (
          props.dates?.map((dates) => (
            <div
              className="movingDiv mx-5 p-2 my-5"
              onClick={() => {
                dispatch({ type: "APPOINTMENT_DATE", payload: dates.date });
                dispatch({ type: "APPOINTMENT_ID", payload: dates.id });
                props.h.push("/futuretappointments");
              }}
            >
              <p className="appointmentText px-3 mt-3">Next appointment</p>
              <div className="d-flex justify-between">
                <img src={clock} className="clockIcon" alt="back" />
                <p className="dateText">
                  {new Date(dates.date).toDateString() +
                    " - " +
                    new Date(dates.date).toTimeString()}
                </p>
                <img src={rightArrow} className="arrowIcon" alt="back" />
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </Carousel>
    </div>
  );
};

export default CurrentDiv;
