import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import firebase from "../../../firebase";
import "./styles.css";
import logo from "../../../drawable/Logo.png";
import previous from "../../../drawable/Vector.png";
import ReactCodeInput from "react-code-input";
import { useDispatch, useSelector } from "react-redux";
import { authConstant } from "../../../redux/constants";
import { login, signUp } from "../../../redux/actions/auth.actions";
import { addToDatabase } from "../../../redux/actions/data.actions";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
/**
 * @author
 * @function InputCode
 **/

const InputCode = (props) => {
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [result, setResult] = useState(null);
  const [requestType, setRequestType] = useState("");
  const [loading, setloading] = useState(false);
  const [tempRegStatus, setTempRegStatus] = useState("");
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth);
  const tempAppData = useSelector((state) => state.appointment.tempAppData);
  const userType = useSelector((state) => state.auth.userData);
  const userStatusSignUp = useSelector((state) => state.auth.userAccountSignup);
  const userStatusSignIn = useSelector((state) => state.auth.userAccountLogin);
  const userPhoneNumber = useSelector((state) => state.auth.phone);
  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
    setPhone(userPhoneNumber);
  }, []);
  useEffect(() => {
    if (userStatusSignUp !== "l") {
      if (userStatusSignUp) {
        alert("the account already exists please login instead");
        props.history.push("/login");
      } else if (!userStatusSignUp) {
        props.history.push("/");
      }
    }
  }, [userStatusSignUp]);
  useEffect(() => {
    if (userStatusSignIn !== "l") {
      if (userStatusSignIn) {
        if (userType !== null) {
          if (userType.user?.type == "operator") {
            props.history.push("/OperatorDashboard");
          } else {
            props.history.push("/");
          }
        }
      } else if (!userStatusSignIn) {
        alert("the account does not exist sign up instead");
        props.history.push("/signup");
      }
    }
  }, [userStatusSignIn, userType]);
  useEffect(() => {
    setResult(data.result);
    setRequestType(data.requestType);
    setUserData(data.signUpData);
    setTempRegStatus(data.tempRegistration);
  }, [data]);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const getCode = () => {
    setloading(true);
    result
      .confirm(code)
      .then((e) => {
        if (requestType === "SIGN_UP") {
          if (tempRegStatus == true) {
            dispatch(signUp(userData));
            dispatch(addToDatabase(tempAppData));
          } else {
            dispatch(signUp(userData));
          }
        } else if (requestType === "LOGIN") {
          dispatch(login(userData));
        }
      })
      .catch((e) => {
        setloading(false);
        alert("invalid code entered plese try again!");
        dispatch({
          type: `${authConstant.USER_LOGIN}_FAILURE`,
        });
      });
  };
  const resendCode = async () => {
    setloading(true);
    const res = await firebase
      .auth()
      .signInWithPhoneNumber(phone, window.recaptchaVerifier);
    dispatch({ type: "RESULT", payload: res });
    setloading(false);
  };
  const propss = {
    inputStyle: {
      fontFamily: "monospace",
      margin: "4px",
      MozAppearance: "textfield",
      width: "35px",
      borderRadius: "4px",
      fontSize: "29px",
      height: "36px",
      // paddingLeft: "7px",

      border: "2px solid",
    },
    inputStyleInvalid: {
      fontFamily: "monospace",
      margin: "4px",
      MozAppearance: "textfield",
      width: "15px",
      borderRadius: "3px",
      fontSize: "14px",
      height: "26px",
      paddingLeft: "7px",
      backgroundColor: "black",
      color: "red",
      border: "1px solid red",
    },
  };
  return (
    <Container fluid>
      {loading ? (
        <div style={{ marginTop: "40vh" }}>
          <HashLoader
            css={override}
            size={50}
            color={"#403a60"}
            loading={loading}
          />
        </div>
      ) : (
        <>
          <Row>
            <img
              src={previous}
              className="mPrevious"
              alt="back"
              onClick={() => {
                props.history.goBack();
              }}
              style={{ cursor: "pointer" }}
            />
            <Col md="6" xs={12} className="px-3 px-md-0 mainContainer">
              <img src={logo} className="mLogo mb-4" alt="logo" />
              <Form className="text-center">
                <ReactCodeInput
                  fields={6}
                  type="number"
                  inputStyle={{
                    padding: 0,
                    width: 40,
                    height: 40,
                    marginLeft: 5,
                    marginRight: 5,
                    textAlign: "center",
                    verticalAlign: "center",
                    border: "solid 1px rgba(0,0,0,0.2) ",
                    boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                    borderRadius: 5,
                  }}
                  // className="my-4 w-100"
                  onChange={(e) => setCode(e)}
                  // {...propss}
                />

                <button
                  type="button"
                  className="mBtn p-2 mt-4"
                  onClick={getCode}
                >
                  SUBMIT CODE
                </button>

                <p></p>
                <button
                  type="button"
                  className="mBtnRegister"
                  onClick={resendCode}
                >
                  RESEND
                </button>
              </Form>
            </Col>
            <Col className="d-none d-md-block">
              <div className="Login-component"></div>
            </Col>
          </Row>
        </>
      )}
      <input
        id="recaptcha-container"
        type="button"
        onClick="this.onClick"
        className="d-none"
      />
    </Container>
  );
};

export default InputCode;
