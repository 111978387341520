import React, { useEffect, useState } from "react";
import { MDBCol, MDBIcon } from "mdbreact";
import { Col, Container, Form, Jumbotron, Row } from "react-bootstrap";
import { DarkService, LightService } from "../../../AllServices";
import SearchField from "react-search-field";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormData,
  getServiceData,
} from "../../../../redux/actions/data.actions";
/**
 * @author
 * @function Step1
 **/

const Step1 = (props) => {
  const dispatch = useDispatch();
  const { selected, setSelected } = props;
  const [service, setService] = useState([]);
  const [search, setSearch] = useState("");
  const [showAllServices, setShowAllServices] = useState([]);
  const [toShow, setToShow] = useState(0);
  const [showAll, setShowAll] = useState(0);
  const data = useSelector((state) => state.data);
  useEffect(() => {
    if (data.allServices !== null) {
      const servicesToPush = [];
      data.allServices.forEach((e) => servicesToPush.push(...e.services));
      setShowAllServices(servicesToPush);
    }
  }, [data]);

  useEffect(() => {
    if (toShow === 0) {
      dispatch(getServiceData());
      setToShow(1);
    }
  }, []);
  const handleSelection = (e) => {
    if (e.target.value == 0) {
      setShowAll(0);
    } else {
      const name = e.target.value;
      const services = data.allServices.find(
        (ser) => ser.name == name
      ).services;
      setService(services);
      setShowAll(name);
    }
  };

  const selectService = (serviceData) => {
    const isSelected = selected.includes(serviceData.name);
    let newArr = [...selected];
    if (isSelected) {
      newArr = newArr.filter((e) => e !== serviceData.name);
    } else {
      newArr.push(serviceData.name);
    }
    setSelected(newArr);

    dispatch({ type: "SET_APPOINTMENT_SERVICES", payload: newArr });
  };
  return (
    <div>
      <Container className="mt-3 mx-auto">
        <Form.Row>
          <MDBCol>
            <div
              className="d-flex align-items-center px-2 "
              style={{ border: "solid 1px black", borderRadius: "4px" }}
            >
              <input
                className="form-control form-control-sm"
                type="text"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
                aria-label="Search"
                style={{ border: "none" }}
              />
              <MDBIcon
                icon="search"
                className="ml-2"
                style={{ cursor: "pointer" }}
              />
            </div>
          </MDBCol>
          <Col>
            <Form.Control
              as="select"
              className="mr-sm-2"
              id="inlineFormCustomSelect"
              custom
              onChange={handleSelection}
              style={{ border: "solid 1px black" }}
            >
              <option value="0">All Categories</option>
              {data?.allServices?.map((e, i) => (
                <option value={e.name}>{e.name}</option>
              ))}
            </Form.Control>
          </Col>
        </Form.Row>
        <div>
          <Container
            fluid
            className="mt-2"
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              height: "272px",
            }}
          >
            <Row style={{ justifyContent: "center" }}>
              {showAll === 0
                ? showAllServices
                    .filter((s) => s.name.includes(search))
                    ?.map((serviceData, i) => (
                      <LightService
                        key={i}
                        selected={selected.includes(serviceData.name)}
                        onClick={() => selectService(serviceData)}
                        data={serviceData}
                      />
                    ))
                : service
                    ?.filter((s) => s.name.includes(search))
                    .map((serviceData) => (
                      <LightService
                        selected={selected.includes(serviceData.name)}
                        onClick={() => selectService(serviceData)}
                        data={serviceData}
                      />
                    ))}
            </Row>
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default Step1;
