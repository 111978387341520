import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";

function AccountInfo({ auth }) {
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [phone, setPhone] = useState("");
  useEffect(() => {
    setEmail(auth?.userData.user.email);
    setType(auth?.userData.user?.type);
    setPhone(auth?.phone);
  }, [auth?.userData]);
  useEffect(() => {
    setEmail(auth?.userData.user.email);
    setType(auth?.userData.user?.type);
    setPhone(auth?.phone);
  }, []);
  return (
    <div className="w-100 d-flex flex-column p-5">
      <p className="welcome">Account Information</p>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control disabled value={email} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Phone</Form.Label>
        <Form.Control disabled value={phone} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Role</Form.Label>
        <Form.Control disabled value={type} />
      </Form.Group>
    </div>
  );
}
export default connect((state) => state)(AccountInfo);
