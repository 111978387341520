import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../drawable/Logo.png";
import { logoutUser } from "../../redux/actions/auth.actions";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import LandingPageHeadlineDiv from "../ScrolingDivs/landingPageHeadlineDiv";
import "./styles.css";
import { useLocation, withRouter } from "react-router-dom";
import * as fire from "firebase";
import axios from "axios";
/**
 * @author
 * @function NavBar
 **/

const NavBar = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = fire.default.auth().currentUser;
  const userType = useSelector((state) => state.auth.userData);
  const [type, setType] = useState("");
  const [headlines, setHeadlines] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const getData = async () => {
    const res = await axios.post(
      "https://us-central1-cottage-and-manor.cloudfunctions.net/getHeadlines"
    );
    const data = res.data.data;
    if (data?.data) {
      setHeadlines(data?.data);
    }
  };
  const getPhoneAndEmail = async () => {
    const res = await axios.post(
      "https://us-central1-cottage-and-manor.cloudfunctions.net/getMobileContent"
    );
    const data = res.data.data;
    if (data) {
      setPhone(data?.phone);
      setEmail(data?.email);
    }
  };
  useEffect(() => {
    if (userType !== null) {
      setType(userType?.user?.type);
    }
    getData();
    getPhoneAndEmail();
  }, [userType]);
  const toggle = () => setExpanded((prev) => !prev);
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  if (props.location.pathname.includes("call")) {
    return (
      <Navbar
        style={{
          height: "10vh",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
        className="w-100"
        expand="lg"
      >
        <Navbar.Brand href="/">
          <img
            src={logo}
            style={{ objectFit: "contain", height: 70 }}
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <p className="m-0 p-0">
          This is meeting is powered By Cottage and Manor
        </p>
      </Navbar>
    );
  }
  return props.location.pathname === "/login" ||
    props.location.pathname === "/signup" ||
    props.location.pathname === "/code" ? null : (
    <>
      {type === "client" ? (
        <div className="sticky-navbar-landing-page">
          {props.location.pathname === "/dash" ? null : (
            <div>
              {headlines.length > 0 && (
                <LandingPageHeadlineDiv headlines={headlines} />
              )}
              <div className="d-flex">
                <Row className="ml-auto pb-0 pt-2">
                  <MailIcon
                    className="mt-1 mx-2"
                    style={{ fontSize: "20px", color: "#403A60" }}
                  />
                  <a href={`mailto:${email}`}>
                    <p className="navTStyle mr-5">{email}</p>
                  </a>

                  <PhoneIcon
                    className="mt-1 mx-2"
                    style={{ fontSize: "20px", color: "#403A60" }}
                  />
                  <a href={`tel:${phone}`}>
                    <p className="navTStyle mr-5">{phone}</p>
                  </a>
                </Row>
              </div>
            </div>
          )}

          <Navbar
            style={{ position: "sticky", top: 0 }}
            className="w-100"
            expand="lg"
            expanded={expanded}
          >
            <Navbar.Brand href="/">
              <img
                src={logo}
                width="100"
                height="80"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle onClick={toggle} aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto NavButtonsStyle">
                <Nav.Link
                  onClick={toggle}
                  href="/#home"
                  className="NavButtonsStyle"
                >
                  Home
                </Nav.Link>
                <Nav.Link onClick={toggle} href="/#aboutUs">
                  About
                </Nav.Link>
                <Nav.Link onClick={toggle} href="/#ourServices">
                  How it Works
                </Nav.Link>
                <Nav.Link onClick={toggle} href="/#contactUs">
                  Contact
                </Nav.Link>
                <Nav.Link onClick={toggle} href="/account">
                  Account
                </Nav.Link>
                <Nav.Link onClick={toggle} href="/#bookAppointment">
                  Booking
                </Nav.Link>

                {!isLoggedIn ? (
                  <div className="d-flex flex-column flex-md-row justify-content-center">
                    <button
                      className="loginUpBtn mx-2 my-2 p-2 d-flex align-items-center justify-content-center"
                      onClick={() => {
                        props.history.push("/login");
                      }}
                    >
                      Login
                    </button>
                    <button
                      className="signUpBtn mx-2 my-2 p-2 d-flex align-items-center justify-content-center"
                      onClick={() => {
                        props.history.push("/signup");
                      }}
                    >
                      Sign up
                    </button>
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-md-row justify-content-center">
                    <Nav.Link onClick={toggle} href="/dash">
                      {" "}
                      Appointments
                    </Nav.Link>
                    <Button
                      className="btn btn-danger "
                      onClick={async () => {
                        await dispatch(logoutUser());
                        props.history.push("/login");
                      }}
                    >
                      Logout
                    </Button>
                  </div>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      ) : type === "operator" ? (
        <Navbar
          className="w-100 sticky-navbar-landing-page"
          bg="light"
          expand="lg"
        >
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="100"
              height="80"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link onClick={toggle} href="/OperatorDashboard">
                Dashboard
              </Nav.Link>
              <Nav.Link onClick={toggle} href="/account">
                Account
              </Nav.Link>
              <Nav.Link onClick={toggle} href="/content">
                Content
              </Nav.Link>
              <Nav.Link onClick={toggle} href="/appointments">
                Appointments
              </Nav.Link>

              <NavDropdown drop="left" title="Account" id="basic-nav-dropdown">
                <NavDropdown.Item href="/ExpertConsultant">
                  Expert Consultants
                </NavDropdown.Item>

                <NavDropdown.Item
                  href="/login"
                  onClick={() => {
                    dispatch(logoutUser());
                  }}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      ) : type == "consultant" ? (
        <div className=" sticky-navbar-landing-page">
          {props.location.pathname === "/dash" ? null : (
            <div>
              {headlines.length > 0 && (
                <LandingPageHeadlineDiv headlines={headlines} />
              )}
              <div className="d-flex">
                <Row className="ml-auto pb-0 pt-2">
                  <MailIcon
                    className="mt-1 mx-2"
                    style={{ fontSize: "20px", color: "#403A60" }}
                  />
                  <a href={`mailto:${email}`}>
                    <p className="navTStyle mr-5">{email}</p>
                  </a>

                  <PhoneIcon
                    className="mt-1 mx-2"
                    style={{ fontSize: "25px", color: "#403A60" }}
                  />
                  <a href={`tel:${phone}`}>
                    <p className="navTStyle mr-5">{phone}</p>
                  </a>
                </Row>
              </div>
            </div>
          )}

          <Navbar
            style={{ position: "sticky", top: 0 }}
            className="w-100"
            expand="lg"
          >
            <Navbar.Brand href="/">
              <img
                src={logo}
                width="100"
                height="80"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto NavButtonsStyle">
                <Nav.Link
                  onClick={toggle}
                  href="#home"
                  className="NavButtonsStyle"
                >
                  Home
                </Nav.Link>
                <Nav.Link onClick={toggle} href="/#aboutUs">
                  About
                </Nav.Link>
                <Nav.Link onClick={toggle} href="/#ourServices">
                  How it Works
                </Nav.Link>
                <Nav.Link onClick={toggle} href="/#contactUs">
                  Contact
                </Nav.Link>
                <Nav.Link onClick={toggle} href="/account">
                  Account
                </Nav.Link>
                {user.uid === "notAssigned" ? (
                  <div className="d-flex flex-row justify-content-center">
                    <button
                      className="loginUpBtn mx-2 p-2"
                      onClick={() => {
                        props.history.push("/login");
                      }}
                    >
                      Login
                    </button>
                    <button
                      className="signUpBtn mx-2 p-2"
                      onClick={() => {
                        props.history.push("/signup");
                      }}
                    >
                      Sign up
                    </button>
                  </div>
                ) : (
                  <div className="d-flex flex-row justify-content-center">
                    <Nav.Link onClick={toggle} href="/dash">
                      {" "}
                      Appointments
                    </Nav.Link>
                    <Button
                      className="btn btn-danger"
                      onClick={async () => {
                        await dispatch(logoutUser());
                        props.history.push("/login");
                      }}
                    >
                      Logout
                    </Button>
                  </div>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      ) : null}
    </>
  );
  // );
};

export default withRouter(NavBar);
