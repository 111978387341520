import React from "react";
import { Button, Carousel, Jumbotron } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import mobileImage from "../../../drawable/mobile.png";

import "./styles.css";
/**
 * @author
 * @function customCarocel
 **/

const CustomCarocel = (props) => {
  return (
    <div className="caroselStyling">
      <Jumbotron className="jumboStyling mx-md-5">
        <Row
            className="w-1f00"
        >
          <Col   xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} className="m-auto">
              <div className="mx-md-5">
                  <h1 className="headerStyling">{props.content.head}</h1>
                  <p className="pStyling">{props.content.data}</p>
                  <div className="text-center">
                      <Button
                          className="bStyle p-2 m-0"
                          style={{ backgroundColor: "white", border: "none" }}
                      >
                          <a className="btn" href="#bookAppointment">
                              {props.content.button}
                          </a>
                      </Button>
                  </div>
              </div>
          </Col>
          <Col xs={{ span: 12, order: 1 }}  md={{ span: 4, order: 1 }}>
            <div className="mobile-image">
              <img src={mobileImage} alt="cottageandmanor"/>
            </div>
          </Col>
        </Row>
      </Jumbotron>
    </div>
  );
};

export default CustomCarocel;
