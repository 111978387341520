import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Footer from '../../components/LandingPage/Footer';

const TERMS = [
    {
        title: 'OVERVIEW',
        content: (
            <div>
                <strong>
                    <p>
                        <span className="text-underlined">IMPORTANT, READ CAREFULLY :</span> YOUR USE OF AND ACCESS TO THE APPLICATION, WEBSITE AND PRODUCTS AND SERVICES
                        (COLLECTIVELY, THE "SERVICES") OF COTTAGE AND MANOR, LLC, A TENNESSEE LIMITED LIABILITY COMPANY, AND ITS AFFILIATES (COLLECTIVELY, "COTTAGE AND MANOR” OR
                        “COMPANY”) IS CONDITIONED UPON YOUR COMPLIANCE WITH AND ACCEPTANCE OF THESE TERMS, WHICH INCLUDE YOUR AGREEMENT TO ARBITRATE CLAIMS. PLEASE REVIEW
                        THOROUGHLY BEFORE ACCEPTING. BY CLICKING/CHECKING THE "I AGREE" BUTTON/BOX, ACCESSING THE COTTAGE AND MANOR APPLICATION OR WEBSITE OR BY UTILIZING THE
                        COTTAGE AND MANOR SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND ALL EXHIBITS, FORMS, AND INCORPORATED POLICIES (THE “AGREEMENT”).{' '}
                    </p>
                </strong>
                <p>
                    These COTTAGE AND MANOR Terms and Conditions (“Terms”) are expressly incorporated into and made a part of the COTTAGE AND MANOR request for services (the
                    “Reservation”) (the Reservation and these Terms, collectively, the “Agreement”) between you (“You,” “User,” or “Customer”) and COTTAGE AND MANOR. This Agreement
                    shall govern all provision of Services (as defined herein) in the United States and its Territories.
                </p>
                <strong>
                    <p>
                        IMPORTANT: PLEASE REVIEW THE ARBITRATION AGREEMENT SET FORTH BELOW CAREFULLY, AS IT WILL REQUIRE YOU TO RESOLVE DISPUTES WITH COTTAGE AND MANOR ON AN
                        INDIVIDUAL BASIS THROUGH FINAL AND BINDING ARBITRATION. BY ENTERING INTO THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF
                        THE TERMS OF THIS AGREEMENT AND HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS IMPORTANT DECISION.
                    </p>
                </strong>
                <p>
                    COTTAGE AND MANOR may amend the Terms from time to time. Amendments will be effective upon COTTAGE AND MANOR's posting of such updated Terms at this location or
                    on its website (www.COTTAGEANDMANOR.com). Your continued access or use of the Services after such posting confirms your consent to be bound by the Terms, as
                    amended.{' '}
                </p>
                <p>
                    COTTAGE AND MANOR’s collection and use of personal information in connection with the Services is described in COTTAGE AND MANOR's Privacy Policy located at
                    www.cottageandmanor.com/legal/privacy, and at Exhibit A, incorporated herein.
                </p>
            </div>
        ),
    },
    {
        title: 'ACCESS AND USE OF THE SERVICES',
        content: (
            <div>
                <p>
                    The Services comprise mobile applications and related services (each, an "Application"), which enable users to arrange a private video meeting with
                    consultant/s. Unless otherwise agreed by COTTAGE AND MANOR in a separate written agreement, the Services are made available solely for User’s personal,
                    noncommercial use and are not for use by anyone other than the User. Opinions and advice are given in good faith but are not comprehensive evaluations of any
                    properties or structures. COTTAGE AND MANOR does not visit properties as part of this Agreement, and does not have the information necessary to render
                    decisions; comprehensive home inspections and market advice should be provided by local experts after in-person property visitations. Any opinions expressed are
                    intended only as guidance, with additional in-person advice being strongly advised prior to financial decision-making
                </p>
                <h6>License.</h6>
                <p>
                    Subject to your compliance with these Terms, COTTAGE AND MANOR grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to:
                    (i) access and use the Applications on your personal device solely in connection with your use of the Services; (ii) access and use any content, information and
                    related materials that may be made available through the Services, in each case solely for your personal, noncommercial use, and (iii) purchase and pay for the
                    Services. You may not republish, reproduce, duplicate, copy, display, distribute or otherwise use any material from the Applications for commercial purposes.
                    Any reproduction or unauthorized use of any materials found from the Applications shall constitute infringement. Any rights not expressly granted herein are
                    reserved by COTTAGE AND MANOR.
                </p>
                <h6>User Accounts.</h6>
                <p>
                    In order to use most aspects of the Services, you may register for and maintain an active personal user Services account ("Account"). Account registration
                    requires you to submit to COTTAGE AND MANOR certain personal information, such as your name, mobile phone number, and at least one valid payment method
                    supported by COTTAGE AND MANOR. An Account is required to access any records of consultations by a User. You agree to maintain accurate, complete, and
                    up-to-date information in your Account. Your failure to maintain accurate, complete, and up-to-date Account information, including having an invalid or expired
                    payment method on file, may result in your inability to access or use the Services. You are responsible for all activity that occurs under your Account, and you
                    agree to maintain the security and secrecy of your Account username and password at all times. Unless otherwise permitted by COTTAGE AND MANOR in writing, you
                    may only possess one Account. In its sole and absolute discretion, COTTAGE AND MANOR may terminate your Account or refuse to provide Services, without refund,
                    if You violate these Terms or any applicable law or regulation relating to your use of the Services.
                </p>
                <h6>User Requirements and Conduct.</h6>
                <p>
                    You may not authorize third parties to use your Account. You may not assign or otherwise transfer your Account to any other person or entity. You agree to
                    comply with all applicable laws when accessing or using the Services. You may only access or use the Services for lawful purposes and may not, in your access or
                    use of the Services, cause nuisance or property damage to any property.
                </p>
                <h6>COTTAGE AND MANOR’s Right to Refuse Service.</h6>
                <p>
                    COTTAGE AND MANOR will refuse to serve anyone who uses threatening or abusive behavior towards staff or others. COTTAGE AND MANOR reserves the right to end
                    services at any time if in COTTAGE AND MANOR’s opinion unruly or abusive behavior has arisen or is likely to arise. Decisions to refuse or discontinue services
                    shall be within COTTAGE AND MANOR’s sole and absolute discretion.
                </p>
                <h6>User Provided Content.</h6>
                <p>
                    COTTAGE AND MANOR may, in COTTAGE AND MANOR's sole discretion, permit you from time to time to submit, upload, publish or otherwise make available to COTTAGE
                    AND MANOR through the Services textual, audio, and/or visual content and information, including commentary and feedback related to the Services, initiation of
                    support requests, and submission of entries for competitions and promotions ("User Content"). Any User Content provided by you remains your property. However,
                    by providing User Content to COTTAGE AND MANOR, you grant COTTAGE AND MANOR a worldwide, perpetual, irrevocable, transferable, royalty-free license, with the
                    right to sublicense, to use, copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such
                    User Content in all formats and distribution channels now known or hereafter devised (including in connection with the Services and COTTAGE AND MANORs business
                    and on third-party sites and services), without further notice to or consent from you, and without the requirement of payment to you or any other person or
                    entity.
                </p>
                <p>
                    In providing any User Content to COTTAGE AND MANOR, You represent and warrant that: (i) you either are the sole and exclusive owner of all User Content or you
                    have all rights, licenses, consents and releases necessary to grant COTTAGE AND MANOR the license to the User Content as set forth above; and (ii) neither the
                    User Content, nor your submission, uploading, publishing or otherwise making available of such User Content, nor COTTAGE AND MANOR's use of the User Content as
                    permitted herein will infringe, misappropriate or violate a third party's intellectual property or proprietary rights, or rights of publicity or privacy, or
                    result in the violation of any applicable law or regulation.
                </p>
                <p>
                    You agree to not provide User Content that is defamatory, libelous, hateful, violent, obscene, pornographic, unlawful, or otherwise offensive, as determined by
                    COTTAGE AND MANOR in its sole discretion, whether or not such material may be protected by law. COTTAGE AND MANOR may, but shall not be obligated to, review,
                    monitor, or remove User Content, at COTTAGE AND MANOR's sole discretion and at any time and for any reason, without notice to you.
                </p>
            </div>
        ),
    },
    {
        title: 'NETWORK ACCESS AND DEVICES, INTELLECTUAL PROPERTY',
        content: (
            <div>
                <p>
                    You are responsible for obtaining the data network access necessary to use the Services. Your mobile network's data and messaging rates and fees may apply if
                    you access or use the Services from your device. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the
                    Services and Applications and any updates thereto. COTTAGE AND MANOR does not guarantee that the Services, or any portion thereof, will function on any
                    particular hardware or devices. In addition, the Services may be subject to malfunctions and delays inherent in the use of the Internet and electronic
                    communications.
                </p>
                <h6>Site Content and Intellectual Property.</h6>
                <p>
                    The Services and all rights therein are and shall remain COTTAGE AND MANOR's property or the property of COTTAGE AND MANOR's licensors. Neither these Terms nor
                    your use of the Services convey or grant to you any rights in or related to the Services except for the limited license granted above.
                </p>
                <p>
                    The COTTAGE AND MANOR trade name and COTTAGE AND MANOR logo are trademarks owned by COTTAGE AND MANOR. All other trademarks, product names and company names or
                    logos displayed on the Site are the property of their respective owners. All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos,
                    sounds, music, artwork and computer code (collectively, “Content”), including but not limited to the design, structure, selection, coordination, expression,
                    “look and feel” and arrangement of such Content, contained on the Site is owned, controlled or licensed by COTTAGE AND MANOR or is used with the permission of
                    its respective owners, and is protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights and unfair
                    competition laws, ALL RIGHTS RESERVED.
                </p>
                <p>
                    You may not engage in any of the following prohibited activities: (1) copying, distributing, reproducing, uploading, posting, encoding, translating,
                    transmitting, decompiling, reverse engineering, disassembling, distributing ( including “mirroring”) or disclosing any part of the Site in any medium, including
                    without limitation by any automated or non-automated “scraping”; (2) using any automated system, including without limitation “robots”, “spiders”, or “offline
                    readers”, to access the Site in any manner that sends more request messages to our servers than a human can reasonably produce in the same period of time by
                    using a conventional on-line web browser; (3) transmitting spam, chain letters, or other unsolicited e-mail; (4) using any “bot”, spider, site search/retrieval
                    tool or utility, orhjuzvs any other manual or automated technique, to collect, extract, index, mine, or otherwise seek to obtain any data or information from
                    the Site or any user of the Site, or in any manner compromise, degrade or circumvent the navigational structure or the presentation of the Site, or the display
                    or performance of the Content of the Site; (5) attempting to interfere with, compromise the system integrity or security or decipher any transmissions to or
                    from the servers running the Site; (6) taking any action that imposes, or may impose at our sole discretion an unreasonable or disproportionately large load on
                    our infrastructure; (7) uploading invalid data, viruses, worms, or other software agents through the Site; (8) collecting or harvesting any personally
                    identifiable information from the Site; (9) using the Site for any commercial solicitation purposes; (10) impersonating another person or otherwise
                    misrepresenting your affiliation with a person or entity, conducting fraud, hiding or attempting to hide your identity; (11) interfering with the proper working
                    of the Site; or (12) bypassing the measures we may use to prevent or restrict access to the Site. We may permanently or temporarily terminate, suspend, or
                    otherwise refuse to permit your access to the Site without notice and liability for any reason, including if in our sole determination you violate any provision
                    of these Terms, or for no reason. Upon termination for any reason or no reason, you continue to be bound by these Terms. You further understand and agree that
                    COTTAGE AND MANOR has the right, but not the obligation, to remove in whole or in part any Content related to the Site, including without limitation all data
                    and Content residing on our servers, at any time for any or no reason, with or without notice and with no liability to us of any kind.
                </p>
                <h6>Our Linking Policy.</h6>
                <p>
                    COTTAGE AND MANOR may, from time to time, contain links to websites operated by others (each is a “Third Party Site”; collectively, “Third Party Sites”). The
                    links to and advertisements concerning Third Party Sites are provided for your convenience only. We do not control Third Party Sites and are not responsible for
                    any of the content, materials or services contained or provided on any Third Party Site or for performance of any Third Party Site or for your transactions on
                    or with them. Our inclusion of links to or advertisements for any Third Party Site is not, and does not imply, any endorsement of any content, material, product
                    or services offered on or through such Third Party Site, or any association with their operators.
                </p>{' '}
                <p>
                    We permit you to include on your own website(s) text links to Content on COTTAGE AND MANOR provided that: (1) it must be a text only link clearly marked
                    “COTTAGE AND MANOR,”; (2) you may not use any COTTAGE AND MANOR logo as a link; (3) the link must be to the www.COTTAGEANDMANOR.com home page and you do not
                    remove or obscure, by framing or otherwise, any portion of the home page; (4) the appearance, position, and other aspects of the link and host website must not
                    be misleading, fraudulent, or in any other manner damaging to or dilutive of the goodwill associated with our name, trademarks or service marks, as determined
                    by us in our sole and absolute discretion; (5) the appearance, position and other aspects of the link and host website must not create a false appearance that
                    the host website is sponsored by COTTAGE AND MANOR; (6) the link, when activated by an internet user, must display the Site full-screen and not with a “frame”
                    on the linked website; and (7) you immediately discontinue providing links the Site if requested by us. We reserve the right to revoke our consent to the link
                    at any time, in our sole discretion, upon notice to you or by amending these Terms.
                </p>
                <p>
                    COTTAGE AND MANOR makes no claim or representation, and accepts no responsibility, regarding the quality, nature, or reliability of the sites accessible by
                    hyperlinks from this Site, or websites linking to this Site.
                </p>
                <h6>Electronic Communications.</h6>
                <p>
                    When you visit the Site or send e-mail to us, you are communicating with us electronically. You consent to receive communications from us electronically.
                    Although we may choose to communicate with you by regular mail, we may also choose to communicate with you by e-mail. You agree that all agreements, notices,
                    disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
                </p>
                <h6>Warranties and Disclaimers. </h6>
                <p>
                    COTTAGE AND MANOR controls and operates this website from offices in the United States. COTTAGE AND MANOR does not represent that materials on its website are
                    appropriate or available for use in other locations. Users who choose to access this website from other locations do so on their own initiative and are
                    responsible for compliance with local laws, if and to the extent local laws are applicable.
                </p>
                <p>
                    You expressly agree that your use of Services is at your sole risk. COTTAGE AND MANOR EXPRESSLY DISCLAIMS ALL WARRANTIES AND/OR CONDITIONS, EXPRESS OR IMPLIED,
                    AS TO ANY MATTER, STRUCTURE, OR PROPERTY WHATSOEVER RELATING TO, OR REFERENCED BY, THE SERVICE, INCLUDING, BUT NOT LIMITED TO, THE MERCHANTABILITY OR
                    SATISFACTORY QUALITY OR VALUE OR FITNESS FOR A PARTICULAR PURPOSE. The Service provides opinion only, and does not provide any financial, legal, medical
                    services or advice. COTTAGE AND MANOR does not visit properties as part of these Services and thus cannot understand the value or quality of a property. Live,
                    local professionals are the only effective persons to provide comprehensive understandings and You agree to engage those professionals when appropriate.
                </p>
                <p>
                    COTTAGE AND MANOR DOES NOT GUARANTEE, REPRESENT OR WARRANT THAT THE SITE OR ANY OF THE SITE MATERIALS WILL BE FREE OF INFECTION FROM ANY VIRUSES, WORMS, TROJAN
                    HORSES, TRAP DOORS, BACK DOORS, EASTER EGGS, TIME BOMBS, CANCELBOTS OR OTHER CODE OR COMPUTER PROGRAMMING ROUTINES THAT CONTAIN CONTAMINATING OR DESTRUCTIVE
                    PROPERTIES OR THAT ARE INTENDED TO DAMAGE, DETRIMENTALLY INTERFERE WITH, SURREPTITIOUSLY INTERCEPT OR MISAPPROPRIATE ANY SYSTEM, DATA OR PERSONAL INFORMATION.
                    YOU ARE RESPONSIBLE FOR THE IMPLEMENTATION OF SUFFICIENT PROCEDURES, FIREWALLS AND CHECKPOINTS WHICH SATISFY YOUR PARTICULAR REQUIREMENTS FOR SECURITY,
                    ACCURACY, AND DATA INPUT AND OUTPUT, AND FOR MAINTAINING A MEANS EXTERNAL TO THE SITE OR THIS SERVER, FOR THE RECONSTRUCTION OF ANY LOST DATA, INCLUDING BUT NOT
                    LIMITED TO ANY DOWNLOADS FROM THE SITE OR THIS SERVER.
                </p>
            </div>
        ),
    },
    {
        title: 'PAYMENT',
        content: (
            <div>
                <p>
                    You understand that use of the Services may result in charges to you for the services or goods you receive ("Charges"). COTTAGE AND MANOR will receive and/or
                    enable your payment of the applicable Charges for services or goods obtained through your use of the Services. Charges will be inclusive of applicable taxes
                    where required by law. Charges may include other applicable fees, and/or surcharges which may include booking fees and processing fees. Please visit
                    www.COTTAGEANDMANOR.com for further information on your particular location.
                </p>
                <p>
                    All Charges and payments will be enabled by COTTAGE AND MANOR using the preferred payment method designated in your Account or chosen by You at the time of the
                    transaction from options provided. Charges paid by you are final and non-refundable, unless otherwise determined by COTTAGE AND MANOR.
                </p>
                <p>
                    As between you and COTTAGE AND MANOR, COTTAGE AND MANOR reserves the right to establish, remove and/or revise Charges for any or all services at any time in
                    COTTAGE AND MANOR's sole discretion. Further, you acknowledge and agree that Charges applicable in certain geographical areas may increase substantially during
                    times of high demand. COTTAGE AND MANOR will use reasonable efforts to inform you of Charges that may apply, provided that you will be responsible for Charges
                    incurred under your Account regardless of your awareness of such Charges or the amounts thereof. COTTAGE AND MANOR may from time to time provide certain users
                    with promotional offers and discounts that may result in different amounts charged for the same or similar services or goods obtained through the use of the
                    Services, and you agree that such promotional offers and discounts, unless also made available to you, shall have no bearing on your use of the Services or the
                    Charges applied to you. You may elect to cancel your request for Services at any time prior to the commencement of such Services, in which case you may be
                    charged a cancellation fee. After you have received services or goods obtained through the Service, you will have the opportunity to rate your experience and
                    leave additional feedback. COTTAGE AND MANOR may use the proceeds of any Charges for any purpose, subject to any payment obligations it has agreed to with any
                    Third Party Providers or other third parties.
                </p>
                <p>
                    Since we have a clear and explicit Refund Policy in these Terms that you have agreed to prior to completing the purchase of any Services, we do not tolerate or
                    accept any type of chargeback threat or actual chargeback from your credit card company or payment processor. In the event that a chargeback is placed on a
                    purchase or we receive a chargeback threat during or after your purchase, we reserve the right to report the incident to all three credit reporting agencies or
                    to any other entity for inclusion in any chargeback database or for listing as a delinquent account, which could have a negative impact on your credit report
                    score. The information reported will include your name, email address, order date, order amount, and billing address. Chargeback abusers wishing to be removed
                    from the database shall make the payment for the amount of the chargeback.
                </p>
            </div>
        ),
    },
    {
        title: 'DISCLAIMERS; LIMITATION OF LIABILITY; INDEMNTIY',
        content: (
            <div>
                <h6>DISCLAIMER.</h6>
                <p>
                    THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." COTTAGE AND MANOR DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, NOT
                    EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION,
                    COTTAGE AND MANOR MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, OR AVAILABILITY OF THE SERVICES
                    OR ANY SERVICES REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. COTTAGE AND MANOR DOES NOT GUARANTEE THE
                    QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR
                    GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
                </p>
                <h6>LIMITATION OF LIABILITY.</h6>
                <p>
                    COTTAGE AND MANOR SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA,
                    PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, REGARDLESS OF THE NEGLIGENCE (EITHER
                    ACTIVE, AFFIRMATIVE, SOLE, OR CONCURRENT) OF COTTAGE AND MANOR, EVEN IF COTTAGE AND MANOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>{' '}
                <p>
                    COTTAGE AND MANOR SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO
                    ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF COTTAGE AND MANOR HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES. COTTAGE AND MANOR SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND COTTAGE AND MANOR'S
                    REASONABLE CONTROL.
                </p>
                <p>
                    COTTAGE AND MANOR’S MAXIMUM LIABILITY TO YOU OR ANY OTHER PARTY FOR ANY LOSS OR DAMAGES RESULTING FROM ANY CLAIMS, DEMANDS OR ACTIONS SHALL BE THE TOTAL FEES
                    YOU HAVE ACTUALLY PAID TO COTTAGE AND MANOR FOR SERVICES.
                </p>
                <p>COTTAGE AND MANOR HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY THIRD PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS.</p>
                <p>
                    THE LIMITATIONS AND DISCLAIMER IN THIS SECTION DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE
                    LAW. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES
                    OR JURISDICTIONS, COTTAGE AND MANOR’S LIABILITY SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. THIS PROVISION SHALL HAVE NO EFFECT ON COTTAGE AND MANOR’S
                    CHOICE OF LAW PROVISION SET FORTH BELOW.
                </p>
                <p>
                    COTTAGE AND MANOR will not be liable in damages or equity for any failure to provide, or delay in its provision of, Services where such failure or delay is due
                    to fire or other casualty, inclement weather, earthquake, hurricane, flood, landslide, epidemic, military action, riot, civil commotion, strike, slowdown,
                    boycott or labor dispute, power outage or disruption, breakdown of equipment, or other similar event beyond the reasonable control of COTTAGE AND MANOR. COTTAGE
                    AND MANOR will not be responsible for any loss or damage You sustain in the event of its temporary or permanent inability to provide Services due to such events
                    beyond COTTAGE AND MANOR’s reasonable control. If Services cannot be provided as a result of a force majeure event, there shall be no refund for payments
                    already received by COTTAGE AND MANOR, and COTTAGE AND MANOR will use reasonable efforts to provide the Services at a later date, subject to COTTAGE AND MANOR’s
                    availability. Additional fees may be due COTTAGE AND MANOR as a result of such rescheduling.
                </p>
                <h6>LIMITATION OF LIABILITY.</h6>
                <p>
                    COTTAGE AND MANOR SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA,
                    PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, REGARDLESS OF THE NEGLIGENCE (EITHER
                    ACTIVE, AFFIRMATIVE, SOLE, OR CONCURRENT) OF COTTAGE AND MANOR, EVEN IF COTTAGE AND MANOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <p>
                    COTTAGE AND MANOR SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO
                    ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF COTTAGE AND MANOR HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES. COTTAGE AND MANOR SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND COTTAGE AND MANOR'S
                    REASONABLE CONTROL.
                </p>
                <p>
                    COTTAGE AND MANOR’S MAXIMUM LIABILITY TO YOU OR ANY OTHER PARTY FOR ANY LOSS OR DAMAGES RESULTING FROM ANY CLAIMS, DEMANDS OR ACTIONS SHALL BE THE TOTAL FEES
                    YOU HAVE ACTUALLY PAID TO COTTAGE AND MANOR FOR SERVICES.
                </p>
                <p>COTTAGE AND MANOR HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY THIRD PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS.</p>
                <p>
                    THE LIMITATIONS AND DISCLAIMER IN THIS SECTION DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE
                    LAW. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES
                    OR JURISDICTIONS, COTTAGE AND MANOR’S LIABILITY SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. THIS PROVISION SHALL HAVE NO EFFECT ON COTTAGE AND MANOR’S
                    CHOICE OF LAW PROVISION SET FORTH BELOW.
                </p>
                <p>
                    COTTAGE AND MANOR will not be liable in damages or equity for any failure to provide, or delay in its provision of, Services where such failure or delay is due
                    to fire or other casualty, inclement weather, earthquake, hurricane, flood, landslide, epidemic, military action, riot, civil commotion, strike, slowdown,
                    boycott or labor dispute, power outage or disruption, breakdown of equipment, or other similar event beyond the reasonable control of COTTAGE AND MANOR. COTTAGE
                    AND MANOR will not be responsible for any loss or damage You sustain in the event of its temporary or permanent inability to provide Services due to such events
                    beyond COTTAGE AND MANOR’s reasonable control. If Services cannot be provided as a result of a force majeure event, there shall be no refund for payments
                    already received by COTTAGE AND MANOR, and COTTAGE AND MANOR will use reasonable efforts to provide the Services at a later date, subject to COTTAGE AND MANOR’s
                    availability. Additional fees may be due COTTAGE AND MANOR as a result of such rescheduling.
                </p>
                <h6>INDEMNITY.</h6>
                <ol type="a">
                    <li>
                        To the maximum extent allowed by law, you agree to indemnify and hold harmless COTTAGE AND MANOR, its owners, employees, independent contractors, agents,
                        affiliates, and franchisees (the “Indemnitees”) against any and all losses, damages, liabilities, suits, claims, demands, costs and expenses, including any
                        and all attorneys’ fees and expenses, (collectively, “Losses”) which the Indemnitees may incur by reason of: (i) your use of, or reliance on, the Services
                        or services or goods obtained through your use of the Services; (ii) COTTAGE AND MANOR's use of your User Content; (iii) your violation of the rights of any
                        third party, including Third Party Providers; (iv) your omissions or forbearance related to the Services or arising out of any event where COTTAGE AND MANOR
                        provides Services; and (v) any breach by you of any provision of this Agreement.
                    </li>
                    <li>
                        If any Indemnitee believes that it has suffered or incurred any Losses, COTTAGE AND MANOR shall notify the indemnifying party in writing describing such
                        Losses, the amount thereof, if known, and the method of computation of such Losses, all with reasonable particularity and containing a reference to any
                        provisions of this Agreement regarding which such Losses shall have occurred. If any action at law or suit in equity is instituted by a third party with
                        respect to which COTTAGE AND MANOR intends to claim any liability or expense as Losses under this Section, COTTAGE AND MANOR shall promptly notify the
                        indemnifying party of such action or suit.{' '}
                    </li>
                    <li>
                        COTTAGE AND MANOR shall have the right to defend, conduct, control, or settle, through counsel of its own choosing, any third-party claim, action, or suit,
                        and to recover from the indemnifying party the amount of such settlement or of any judgment and the costs and expenses of such defense, including any and
                        all attorneys’ fees and expenses. COTTAGE AND MANOR may compromise or settle any third-party claim, action, or suit without the prior written consent of the
                        indemnifying party.
                    </li>
                    <li>
                        To the extent any Loss of an Indemnitee is reduced by receipt of payment under insurance policies, such payment, net of the expenses of the recovery thereof
                        (such net payment being referred to herein as a “Reimbursement”), shall be credited against such Losses. If any Reimbursement is obtained subsequent to
                        payment by any of the Indemnifying Persons in respect of Losses, such Reimbursement shall be promptly paid over to the indemnifying party. Further, User
                        agrees that no insurance company shall be entitled to assert a claim for subrogation or otherwise against any Indemnitee relating to the payment by an
                        insurance company to any Indemnitee.
                    </li>
                    <li>
                        Notwithstanding anything contained in this agreement to the contrary, all representations, warranties, covenants and agreements contained in this Agreement
                        shall survive the execution and delivery of this Agreement.
                    </li>
                </ol>
            </div>
        ),
    },
    {
        title: 'OTHER PROVISIONS',
        content: (
            <div>
                <h6>Choice of Law and Venue.</h6>
                <p>
                    These Terms are governed by and construed in accordance with the laws of the State of Tennessee, U.S.A., without giving effect to any conflict of law
                    principles. However, the choice of law provision regarding the interpretation of these Terms is not intended to create any other substantive right to
                    non-Tennesseans to assert claims under Tennessee law whether that be by statute, common law, or otherwise. This provision is only intended to specify the use of
                    Tennessee law to interpret these Terms and the forum for disputes arising under or relating to these Terms, and these provisions shall not be interpreted as
                    generally extending Tennessee law to you if you do not otherwise reside in Tennessee. The state and federal courts in Nashville, Davidson County, Tennessee
                    shall have exclusive jurisdiction over any case or controversy arising from or relating to these Terms. So long as its jurisdictional requirements are met and
                    it is available, the Business Court Docket of the Davidson County Chancery Court shall have exclusive jurisdiction over state court suits. You hereby submit to
                    the exclusive jurisdiction and venue of these courts and consent irrevocably to personal jurisdiction in such courts and waive any defense of forum non
                    conveniens.
                </p>
                <h6>Notice.</h6>
                <p>
                    COTTAGE AND MANOR may give notice by means of a general notice on the Services, electronic mail to your email address in your Account, telephone or text message
                    to any phone number provided in connection with your account, or by written communication sent by first class mail or pre-paid post to any address connected
                    with your Account. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting (if sent by first class mail or
                    pre-paid post) or 12 hours after sending (if sent by email or telephone). You may give notice to COTTAGE AND MANOR, with such notice deemed given when received
                    by COTTAGE AND MANOR, at any time by first class mail or pre-paid post to COTTAGE AND MANOR, LLC 315 Deaderick St. #1550, Nashville, TN 37238.
                </p>
                <h6>General.</h6>
                <p>
                    COTTAGE AND MANOR assumes no responsibility for any personal injury to any person in connection with the performance of Services hereunder, or any damage to any
                    personal property. Responsibility for Your safety and caution when visiting properties, whether You or the Your agents, is solely Yours. All actions shall be at
                    Your sole risk.
                </p>
                <h6>Transmission and Security of Data.</h6>
                <p>
                    COTTAGE AND MANOR’s Services involve the storage and transmission of User’s credit card information and personal identification, and COTTAGE AND MANOR takes
                    security measures to protect such information. Despite its best efforts, You acknowledge that COTTAGE AND MANOR’s security measures may be breached as a result
                    of third-party action during transfer of data to additional data centers or at any time, and result in someone obtaining unauthorized access to our data or our
                    User’s data. Additionally, third parties may attempt to fraudulently induce Users into disclosing sensitive information such as user names, passwords or other
                    information in order to gain access to our data or our customers’ data. Because the techniques used to obtain unauthorized access, or to sabotage systems,
                    change frequently and generally are not recognized until launched against a target, COTTAGE AND MANOR may be unable to anticipate these techniques or to
                    implement adequate preventative measures. In addition, COTTAGE AND MANOR’s Users may authorize third party technology providers to access their financial data.
                    Because COTTAGE AND MANOR does not control the transmissions between our Users and third-party technology providers, or the processing of such data by
                    third-party technology providers, COTTAGE AND MANOR cannot ensure the complete integrity or security of such transmissions or processing.
                    <strong>
                        {' '}
                        You acknowledges and assumes the risks outlined in this paragraph and agree to hold COTTAGE AND MANOR harmless for any third-party misuse of Your financial
                        or personal identification data.
                    </strong>{' '}
                </p>
                <h6>Miscellaneous.</h6>
                <p>
                    In the event that any term or provision of this Agreement shall be held to be invalid, void or unenforceable, then the remainder of this Agreement shall not be
                    affected, impaired or invalidated, and each such term and provision of this Agreement shall be valid and enforceable to the fullest extent permitted by law.
                    This Agreement constitutes the entire agreement of the parties hereto and supersedes all prior and contemporaneous representations, proposals, discussions, and
                    communications, whether oral or in writing. The parties may execute this Agreement in several counterparts, all of which together constitute one agreement
                    between the parties.{' '}
                </p>
                <p>
                    The waiver of any breach of any term, covenant or condition herein contained, or the failure of either party to seek redress for the violation of, or to insist
                    upon the strict performance of, any covenant or condition of this Agreement shall not be deemed to be a waiver of such term, covenant or condition or any
                    subsequent breach of the same. Any modifications or amendments to this Agreement must be in writing signed by both parties to this Agreement, except that
                    COTTAGE AND MANOR may update these Terms, which shall be effective upon COTTAGE AND MANOR's posting of such updated Terms at this location, on its website
                    www.COTTAGEANDMANOR.com, or in the amended policies or supplemental terms on the applicable Service(s). Your continued access or use of the Services after such
                    posting confirms your consent to be bound by the Terms, as amended.
                </p>
                <h6>Disclosure Policy.</h6>
                <p>
                    The website www.COTTAGEANDMANOR.com is written and edited by COTTAGE AND MANOR. COTTAGE AND MANOR does not have relationships that might present a conflict of
                    interest or influence the content of its website.
                </p>
            </div>
        ),
    },
];
export default function TermsAndCondition() {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <p className="nApps">Terms & Conditions</p>
                    </Col>
                    <Col xs={12}>
                        <ol>
                            {TERMS.map(e => (
                                <>
                                    <li className=" h4 my-4">{e.title}</li>
                                    {e.content}
                                </>
                            ))}
                        </ol>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
}
