import * as fire from "firebase";
import axios from "axios";
export const getConsultantData = () => {
  return async (dispatch) => {
    const res = await axios.post(
      "https://us-central1-cottage-and-manor.cloudfunctions.net/getConsultants"
    );
    dispatch({ type: "SET_CONSUSLTANT_DATA", payload: res.data.data });
  };
};

export const addConsultant = (data) => {
  return async (dispatch) => {
    const db = fire.default.firestore();
    var newCityRef = db.collection("consultants").doc();
    var id = newCityRef.id;
    newCityRef
      .set({
        name: data.name,
        phone: data.phone,
        email: data.email,
        active: true,
        services: data.services,
        id: id,
        joined: new Date(),
        availability: {
          monday: {
            from: "08:00",
            to: "20:00",
          },
          tuesday: {
            from: "08:00",
            to: "20:00",
          },
          wednesday: {
            from: "08:00",
            to: "20:00",
          },
          thursday: {
            from: "08:00",
            to: "20:00",
          },
          friday: {
            from: "08:00",
            to: "20:00",
          },
          saturday: {
            from: "08:00",
            to: "20:00",
          },
          sunday: {
            from: "08:00",
            to: "20:00",
          },
        },
      })
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  };
};
export const deleteConsultant = (docId) => {
  return async (dispatch) => {
    const db = fire.default.firestore();
    db.collection("consultants")
      .doc(docId)
      .delete()
      .then(function () {
        console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  };
};
export const getConsultant = (docId) => {
  return async (dispatch) => {
    const db = fire.default.firestore();
    var docRef = db.collection("consultants").doc(docId);

    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          dispatch({ type: "SET_CONSUSLTANT", payload: doc.data() });
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };
};
export const removeConsultantfromAppointment = (docId, consultant, refresh) => {
  return async (dispatch) => {
    const data = [];
    const db = fire.default.firestore();
    db.collection("appointments")
      .doc(docId)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          for (var i = 0; i < doc.data().consultants.length; i++) {
            if (doc.data().consultants[i].id !== consultant) {
              data.push(doc.data().consultants[i]);
            }
          }
          var washingtonRef = db.collection("appointments").doc(docId);
          return washingtonRef
            .update({
              consultants: data,
            })
            .then(function () {
              refresh();
              console.log("Document successfully updated!");
            })
            .catch(function (error) {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };
};
export const updateActiveStatus = (checkStatus, docName) => {
  return async (dispatch) => {
    const db = fire.default.firestore();
    if (!checkStatus) {
      // db.collection("appointments").where("consultants", "array-contains", docName)
      // .get()
      // .then(function(querySnapshot) {
      //     querySnapshot.forEach(function(doc) {
      //         // doc.data() is never undefined for query doc snapshots
      //         console.log(doc.id, " => ", doc.data());
      //     });
      // })
      // .catch(function(error) {
      //     console.log("Error getting documents: ", error);
      // });
    }
    var washingtonRef = db.collection("consultants").doc(docName);
    return washingtonRef
      .update({
        active: checkStatus,
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };
};
export const updateConsultantData = (docName, data) => {
  return async (dispatch) => {
    console.log("The Data given to update = ", data);
    const db = fire.default.firestore();
    var consultantRef = db.collection("consultants").doc(docName);
    return consultantRef
      .update({
        active: data.check,
        name: data.name,
        phone: data.phone,
        email: data.email,
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };
};
export const disableConsultantsOn = (docName, data) => {
  return async (dispatch) => {
    console.log("The Data given to update = ", data);
    const db = fire.default.firestore();
    var consultantRef = db.collection("consultants").doc(docName);
    return consultantRef
      .update({
        notAvailableOn: data,
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };
};

export const updateAvailability = (docName, data) => {
  return async (dispatch) => {
    console.log("The Data given to update = ", data);
    const db = fire.default.firestore();
    var consultantRef = db.collection("consultants").doc(docName);
    return consultantRef
      .update({
        availability: data,
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };
};

export const updateServices = (docName, data) => {
  return async (dispatch) => {
    console.log("The Data given to update = ", data);
    const db = fire.default.firestore();
    var consultantRef = db.collection("consultants").doc(docName);
    return consultantRef
      .update({
        services: data,
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };
};
