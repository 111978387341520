import {
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@material-ui/core";

import React, { useState, useEffect } from "react";
import { Col, Container, Form, Jumbotron, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DarkService, LightService } from "../../AllServices";
import Step1 from "./BookStep1";
import Step2 from "./BookStep2";
import Step3 from "./BookStep3";
import Step4 from "./BookStep4";
import "./styles.css";

/**
 * @author
 * @function BookAPpointment
 **/
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Select Service",
    "Enter Information",
    "Select date and time",
    "Make Payment",
  ];
}

const BookApointment = (props) => {
  const [step, setStep] = useState(1);
  const [moveAlong, setMoveAlong] = useState(false);
  const [selected, setSelected] = useState([]);
  const [moveCalender, setmoveCalender] = useState(false);
  const appointmentData = useSelector((state) => state.data);

  const appDate = useSelector((state) => state.data.appointmentDate);
  useEffect(() => {
    if (appDate !== "") {
      setmoveCalender(true);
    }
  }, [appDate]);
  useEffect(() => {
    if (appointmentData.appointmentServices.length > 0) {
      setMoveAlong(true);
    }
  }, [appointmentData]);
  const NextStep = () => {
    if (step === 4) {
      setStep(1);
      handleReset();
    } else {
      setStep(step + 1);
      handleNext();
    }
  };

  const pay = () => {
    if (step === 4) {
      setStep(1);
      handleReset();
    } else {
      setStep(step + 1);
      handleNext();
    }
  };

  //Stepper logic
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container fluid className="p-3 pt-5 containerBackStyle">
      <Jumbotron className="jTronStyling ">
        <h1 className="headerStyling w-100 text-center mb-5">
          {props.content.head}
        </h1>
        {step === 1 ? (
          <div>
            <Step1 selected={selected} setSelected={setSelected} />
            <button
              className="cService mt-3 px-3 p-2"
              onClick={() => {
                if (selected.length > 0) {
                  NextStep();
                }
              }}
              disabled={!moveAlong}
            >
              Continue
            </button>
          </div>
        ) : step === 2 ? (
          <div>
            <Step2 onClick={NextStep} />
          </div>
        ) : step === 3 ? (
          <div>
            <Step3 />
            <Row className="ml-5 w-100">
              <button
                className="cService mt-3 px-3 p-2"
                onClick={NextStep}
                disabled={!moveCalender}
              >
                <a
                  className="cService btn"
                  style={{ color: "white" }}
                  href="#bookAppointment"
                  disabled={!moveCalender}
                >
                  Continue
                </a>
              </button>
            </Row>
          </div>
        ) : step === 4 ? (
          <div>
            <Step4
              timeframe={props.timeframe}
              price={props.price}
              onClick={pay}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="mt-2 d-none d-md-flex align-items-center justify-content-center">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
      </Jumbotron>
    </Container>
  );
};

export default BookApointment;
