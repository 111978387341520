const initState = {
  consultantData: null,
  consultant: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_CONSUSLTANT_DATA":
      return (state = {
        ...state,
        consultantData: action.payload,
      });
    case "SET_CONSUSLTANT":
      return (state = {
        ...state,
        consultant: action.payload,
      });

    default:
      return state;
  }
};
