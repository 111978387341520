import React from "react";
import "./styles.css";
import { removeConsultantfromAppointment } from "../../redux/actions/consultant.actions";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
/**
 * @author
 * @function PersonServices
 **/
const PersonServices = (props) => {
  const dispatch = useDispatch();
  return (
    <div className="d-flex flex-row">
      {props.toDisplay?.map((d) => (
        <div className="servicePill px-4">
          <div className="d-flex flex-row">
            <p className="servicetext text-center">{d.name}</p>
            <CloseIcon
              className="mt-1 mx-auto"
              style={{ fontSize: "15px" }}
              onClick={() => {
                dispatch(
                  removeConsultantfromAppointment(
                    props.appId,
                    d.id,
                    props.refresh
                  )
                );
              }}
            />
          </div>

          <p className="JobTitle mx-auto mb-0 pb-2">Inspector</p>
        </div>
      ))}
    </div>
  );
};

export default PersonServices;
