import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "../../Pages/LoginPage";
import Confermation from "../../Pages/registration/ConfermationPage";
import InputCode from "../../Pages/registration/InputCodePage";
import SignUp from "../../Pages/registration/SignUpPage";
import ContinueSignUp from "../../Pages/registration/ContinueSignUp";

import DashBoard from "../../Pages/dashBoard";
import AppointmentsCallender from "../../Pages/appointmentsCallender";
import PastAppointments from "../../Pages/pastAppointments";
import FutureAppointments from "../../Pages/futureAppointments";
import OperatorDashboard from "../../Pages/operatorDashboard";
import AppointmentToReview from "../../Pages/appointmentToReview";
import ExpertConsultant from "../../Pages/consultant";

import ExpertConsultantDetails from "../../Pages/consultantDetails";
import LandingPage from "../../Pages/LandingPage";
import React, { useEffect } from "react";
import PrivateRoute from "../../PrivateRoute";
import Videocall from "../../Pages/videocall";
import ServicePage from "../../Pages/ServicesPages/ServicePage";
import AccountInfo from "../../Pages/accountInfo/AccountInfo";

/**
 * @author
 * @function OperatorRoute
 **/

const OperatorRoute = (props) => {
  return (
    <Router>
      <PrivateRoute
        path="/appointments"
        exact
        component={AppointmentsCallender}
      />
      <PrivateRoute path="/account" exact component={AccountInfo} />
      <PrivateRoute path="/content" exact component={ServicePage} />
      <PrivateRoute
        path="/pastappointments"
        exact
        component={PastAppointments}
      />
      <PrivateRoute
        path="/OperatorDashboard"
        exact
        component={OperatorDashboard}
      />
      <PrivateRoute
        path="/appointmentstoreview"
        exact
        component={AppointmentToReview}
      />
      <PrivateRoute
        path="/ExpertConsultant"
        exact
        component={ExpertConsultant}
      />
      <PrivateRoute
        path="/ExpertConsultantDetails"
        exact
        component={ExpertConsultantDetails}
      />
      <PrivateRoute path="/call/:id" exact component={Videocall} />
      {/* <Route path="/" exact component={LandingPage} /> */}
      <PrivateRoute
        path="/futuretappointments"
        exact
        component={FutureAppointments}
      />
    </Router>
  );
};

export default OperatorRoute;
