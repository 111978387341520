const initState = {
  appointmentData: [],
  appointmentIDtoSend: "",
  appointmentDescription: "",
  appDataForOperator: [],
  modRequestData: [],
  requestedMods: [],
  tempAppData: null,
  allAppsData: null,
  appointmentSid: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "ALL_APPOINTMENT_DATA":
      return (state = {
        ...state,
        appointmentData: action.payload,
      });
    case "APPOINTMENT_ID":
      return (state = {
        ...state,
        appointmentIDtoSend: action.payload,
      });
    case "APPOINTMENT_DESCRIPTION":
      return (state = {
        ...state,
        appointmentDescription: action.payload,
      });
    case "APPOINTMENT_DATA_FOR_OPERATOR":
      return (state = {
        ...state,
        appDataForOperator: action.payload,
      });
    case "MOD_REQUEST":
      return (state = {
        ...state,
        modRequestData: action.payload,
      });
    case "SET_APPOINTMENT_SID":
      return (state = {
        ...state,
        appointmentSid: action.payload,
      });
    case "APPOINTMENT_TO_MOD":
      return (state = {
        ...state,
        appointmentData: action.payload,
      });
    case "THE_MODS_REQUESTED":
      return (state = {
        ...state,
        requestedMods: action.payload,
      });
    case "TEMP_APPOINTMENT_TRANSFER":
      return (state = {
        ...state,
        tempAppData: action.payload,
      });
    case "ALL_APPS":
      return (state = {
        ...state,
        allAppsData: action.payload,
      });

    default:
      return state;
  }
};
