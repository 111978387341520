import React from "react";
import hammer from "../../drawable/hammer.png";
import { Row } from "react-bootstrap";

/**
 * @author
 * @function Services
 **/

const Services = (props) => {
  return (
    <div className="d-flex flex-row align-item-center">
      {props.services[0] != null
        ? props.services[0].map((s) => (
            <div className="servicePill p-3 d-flex flex-row align-item-center">
              <img src={hammer} className="hammerIcon" alt="back" />
              <p className="servicetext">{s}</p>
            </div>
          ))
        : null}
    </div>
  );
};

export default Services;
