import React from "react";

import { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./styles.css";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import logo from "../../../drawable/Logo.png";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import firebase from "../../../firebase";

/**
 * @author
 * @function SignUp
 **/
const SignUp = (props) => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
  }, []);
  const openLogin = () => {
    props.history.push("/login");
  };
  const RegisterUser = async () => {
    if (phone === "" || email === "") {
      alert("Email or phone canot be empty");
    } else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      alert("Plese enter a valid email address!");
    } else {
      setloading(true);
      const ph = "+" + phone;
      dispatch({ type: "PHONE_NUMBER", payload: ph });
      const res = await firebase
        .auth()
        .signInWithPhoneNumber(ph, window.recaptchaVerifier);
      const signUpData = { ph, email };
      dispatch({ type: "USER_SIGNUP_DATA", payload: signUpData });
      dispatch({ type: "EMAIL", payload: email });
      dispatch({ type: "RESULT", payload: res });
      dispatch({ type: "SIGN_UP", payload: "SIGN_UP" });
      props.history.push("/code");
    }
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  return (
    <Container fluid>
      {loading ? (
        <div style={{ marginTop: "40vh" }}>
          <HashLoader
            css={override}
            size={50}
            color={"#403a60"}
            loading={loading}
          />
        </div>
      ) : (
        <Row>
          <Col className="px-3 px-md-0 mainContainer">
            <img
              src={logo}
              className="mLogo"
              alt="logo"
              onClick={() => {
                props.history.push("/");
              }}
            />
            <Form className="w-50">
              <Form.Group className="nameTexts" controlId="formBasicEmail">
                <Form.Label>Phone number</Form.Label>
                <PhoneInput
                  disableDropdown
                  autoFormat
                  inputClass="react-tel-input"
                  country={"us"}
                  value={phone}
                  onChange={(val) => {
                    setPhone(!val.startsWith("1") ? "1" + val : val);
                  }}
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>

              <Form.Group className="nameTexts" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="Enter your Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>

              <button type="button" className="mBtn p-2" onClick={RegisterUser}>
                REGISTER
              </button>

              <p></p>
              <button
                type="button"
                className="mBtnRegister p-2"
                onClick={openLogin}
              >
                LOGIN
              </button>
            </Form>
          </Col>
          <Col className="d-none d-md-block">
            <div className="Login-component"></div>
          </Col>
        </Row>
      )}

      <input
        id="recaptcha-container"
        type="button"
        onClick="this.onClick"
        className="d-none"
      />
    </Container>
  );
};

export default SignUp;
