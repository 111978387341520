import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { sendContactEmail } from "../../../redux/actions/email.actions";
import "./styles.css";
/**
 * @author
 * @function ContactUs
 **/

const ContactUs = (props) => {
  const dispatch = useDispatch();
  const [mail, setMail] = useState("");
  const [ph, setPh] = useState("");
  const [mes, setMes] = useState("");
  const contactOperator = () => {
    if (mail === "" || ph === "" || mes === "") {
      alert("Please provide the required fields");
    } else {
      const data = { mail, ph, mes };
      dispatch(sendContactEmail(data));
    }
  };
  return (
    <Container fluid className="p-0 pt-5">
      <h1 className="headerStyling text-center w-100">CONTACT US</h1>

      <Row className="mt-5 pt-5 p-3 w-100 ml-0">
        <Col md="6" className="formColStyle p-3 mx-auto">
          <Form className="py-2">
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="fLabelStyles">Phone number</Form.Label>
              <PhoneInput
                country={"us"}
                value={ph}
                onChange={(phone) => setPh(phone)}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="fLabelStyles">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                onChange={(e) => setMail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label className="fLabelStyles">Message</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter message"
                rows={3}
                onChange={(e) => setMes(e.target.value)}
              />
            </Form.Group>
            <button
              type="button"
              className="cService mt-3 px-3"
              onClick={contactOperator}
            >
              Submit
            </button>
          </Form>
        </Col>
        {/* <Col md="6" className="mapStyling">
          <SimpleMap />
        </Col> */}
      </Row>
    </Container>
  );
};

export default ContactUs;
