import * as fire from "firebase";

import {
  sendApprovedEmailToClient,
  sendEmailToConsultant,
  sendEmailToOperator,
  sendPendingEmailToClient,
} from "./email.actions";
import axios from "axios";
import moment from "moment";

export const sendMail = async (composedEmail) => {
  await axios.post(
    "https://us-central1-cottage-and-manor.cloudfunctions.net/sendMail",
    composedEmail
  );
};
export const sendMessage = async (body, dest) => {
  await axios.post(
    "https://us-central1-cottage-and-manor.cloudfunctions.net/sendMessage",
    { body, dest }
  );
};
export const getFormData = (selection) => {
  return async (dispatch) => {
    dispatch({ type: "GET_DATA_REQUEST" });

    const db = fire.default.firestore();
    var docRef = await db.collection("Services").doc(selection).get();

    dispatch({ type: "GET_DATA_SUCCESS", payload: docRef.data() });
  };
};
export const getServiceData = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_DATA_REQUEST" });
    const data = [];
    const currentUser = fire.default.auth().currentUser;
    const db = fire.default.firestore();
    if (currentUser) {
      db.collection("services")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            data.push(doc.data());
          });
          dispatch({ type: "ALL_SERVICE_DATA", payload: data });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    } else {
      const response = await axios.post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/getServices"
      );

      dispatch({ type: "ALL_SERVICE_DATA", payload: response.data.arr });
    }
  };
};
export const getAppointmentDates = () => {
  return async (dispatch) => {
    const data = {
      dates: [],
      ids: [],
    };
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    if (user) {
      const db = fire.default.firestore();
      switch (user?.type) {
        case "client": {
          db.collection("appointments")
            .where("clientID", "==", user.uid)
            .get()
            .then(function (querySnapshot) {
              querySnapshot.forEach(function (doc) {
                data.dates.push(doc.data());
              });
              dispatch({ type: "ALL_APPOINTMENT_DATA", payload: data });
            })
            .catch(function (error) {
              console.log("Error getting documents: ", error);
            });
          break;
        }
        case "consultant": {
          const res = await db.collection("appointments").get();
          res.forEach((e) => {
            const isUserThere = e
              .data()
              .consultants.find((a) => a.id === user.consultantId);
            if (isUserThere) {
              data.dates.push(e.data());
            }
          });
          dispatch({ type: "ALL_APPOINTMENT_DATA", payload: data });
          break;
        }
      }
    }
  };
};
export const getAppointmentDetails = (ID) => {
  return async (dispatch) => {
    const data = [];
    var description = "";
    const db = fire.default.firestore();
    db.collection("appointments")
      .where("id", "==", ID)
      .get()
      .then(function (querySnapshot) {
        let obj = null;
        querySnapshot.forEach(function (doc) {
          data.push(doc.data().servicesProvided);
          obj = doc.data();
          // description = doc.data().description;
        });
        dispatch({ type: "APPOINTMENT_SERVICES", payload: data });
        dispatch({ type: "APPOINTMENT_DESCRIPTION", payload: obj });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
};
export const getRequestedAppointment = (ID) => {
  return async (dispatch) => {
    const data = [];
    const db = fire.default.firestore();
    db.collection("appointments")
      .where("id", "==", ID)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          data.push(doc.data());
        });
        dispatch({ type: "APPOINTMENT_TO_MOD", payload: data });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
};
export const getModsForAppointment = (ID) => {
  return async (dispatch) => {
    const data = [];
    const db = fire.default.firestore();
    db.collection("modificationRequests")
      .where("appointmentID", "==", ID)
      .where("status", "==", "pending")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          data.push(doc.data());
        });
        dispatch({ type: "THE_MODS_REQUESTED", payload: data });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
};
export const updateAppointmentwithConsultants = (
  appointmentID,
  consultants,
  approval
) => {
  return async (dispatch) => {
    const ap = { appointmentID, consultants, approval };
    const db = fire.default.firestore();

    var appRef = db.collection("appointments").doc(appointmentID);
    appRef
      .get()
      .then(async function (doc) {
        if (doc.exists) {
          const composedEmail = {
            dest: doc.data().email,
            sub: "Your Appointment has been Approved",
            body:
              "Your appointment has been approved for : " +
              moment(new Date(doc.data().date))
                .tz("America/Chicago")
                .format("MMMM Do YYYY, hh:mm a") +
              " Your appointment Id is : " +
              appointmentID +
              "\n you can contact our staff at office@cottageandmanor.com for further details ",
          };
          await sendMail(composedEmail);

          for (var i = 0; i < consultants.length; i++) {
            const composedEmail1 = {
              dest: consultants[i].email,
              sub: "You have a new Appointment",
              body:
                "appointment is approved for : " +
                moment(new Date(doc.data().date))
                  .tz("America/Chicago")
                  .format("MMMM Do YYYY, hh:mm a") +
                "appointment Id is : " +
                appointmentID,
            };
            await sendMail(composedEmail1);
          }

          console.log("Document data:", doc.data());
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

    db.collection("appointments")
      .doc(appointmentID)
      .update({
        consultants: consultants,
        status: approval,
      })
      .then(function () {
        window.location.reload();
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };
};
export const getAppointments = () => {
  return async (dispatch) => {
    const data = [];

    const db = fire.default.firestore();
    db.collection("appointments")
      .where("status", "==", "pending approval")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          data.push(doc.data());
        });
        dispatch({ type: "APPOINTMENT_DATA_FOR_OPERATOR", payload: data });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
};
export const addToDatabase = (data) => {
  return async (dispatch) => {
    const db = fire.default.firestore();
    const consultants = (
      await fire.default.firestore().collection("consultants").get()
    ).docs;
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    if (user) {
      var appointmentReference = db.collection("appointments").doc();
      var id = appointmentReference.id;

      dispatch(sendPendingEmailToClient(data.email, data.date, id));
      dispatch(sendEmailToOperator(data.date, id));
      let qualifiedConsultants = [];
      consultants.forEach((consultant) => {
        const consultantData = consultant.data();
        const doesProvideTheService = data.appointmentServices.every((val) =>
          consultantData.services.includes(val)
        );
        if (!doesProvideTheService) return;
        qualifiedConsultants.push(consultantData);
      });
      qualifiedConsultants = qualifiedConsultants.sort(
        (a, b) =>
          new Date(a?.joined || new Date()) - new Date(b?.joined || new Date())
      );
      if (qualifiedConsultants[0]) {
        dispatch(
          sendEmailToConsultant(
            qualifiedConsultants[0].email,
            "",
            "",
            "New appointment request added for your services",
            `Please visit the app to look at the appointment request.`
          )
        );
      }
      appointmentReference
        .set({
          id: id,
          name: data.name,
          email: data.email,
          phone: data.phone,
          description: data.notes,
          date: data.date,
          servicesProvided: data.appointmentServices,
          meetingLength: data.meetingLength,
          status: "pending approval",
          paymentAmount: data.toPay,
          notes: "",
          consultants: [],
          clientID: user.uid,
          appointmentPaymentID: data.appointmentPaymentID,
          qualifiedConsultants,
        })
        .then(function (docRef) {
          console.log("Document written with ID: ", docRef.id);
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }
  };
};

export const updateApproval = (appointmentID, consultants, approval) => {
  return async (dispatch) => {
    const ap = { appointmentID, consultants, approval };
    const db = fire.default.firestore();

    var appRef = db.collection("appointments").doc(appointmentID);
    appRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          dispatch(
            sendApprovedEmailToClient(
              doc.data().email,
              doc.data().date,
              appointmentID
            )
          );

          for (var i = 0; i < consultants.length; i++) {
            dispatch(
              sendEmailToConsultant(
                consultants[i].email,
                doc.data().date,
                appointmentID
              )
            );
          }
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

    db.collection("appointments")
      .doc(appointmentID)
      .update({
        consultants: consultants,
        status: approval,
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };
};

export const updateQualifiedConsultants = (appointmentID, consultants) => {
  return async (dispatch) => {
    const db = fire.default.firestore();
    if (consultants[0]) {
      const mailBody = {
        dest: consultants[0].email,
        sub: "New appointment request added for your services",
        body: `Please visit the app to look at the appointment request.`,
      };
      await sendMail(mailBody);
    }
    await db
      .collection("appointments")
      .doc(appointmentID)
      .update({
        qualifiedConsultants: consultants,
      })
      .then(function (res) {
        console.log("Document successfully updated!");
        window.location.reload();
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };
};
