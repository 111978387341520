import React, { useEffect, useState } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import { Container, Modal } from "react-bootstrap";
import "./calenderStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  disableConsultantsOn,
  getConsultant,
} from "../../redux/actions/consultant.actions";

/**
 * @author
 * @function ConsultantCalenderComp
 **/
function ConfirmModal(props) {
  const appointmentDate = useSelector((state) => state.data.appointmentDate);
  const [selectedDate, setSelectedDate] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedDate(new Date(appointmentDate));
  }, [appointmentDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const saveTime = () => {
    dispatch({ type: "SET_TIME", payload: selectedDate });
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Time picker"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Modal.Body>
      <Modal.Footer>
        <button className="pApprove" onClick={saveTime}>
          Confirm{" "}
        </button>
        <button className="pCancel" onClick={props.onHide}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}
const ConsultantCalenderComp = (props) => {
  const dispatch = useDispatch();
  const [confirmModalShow, setconfirmModalShow] = useState(false);
  const alreadyDisabledDates = [];
  const consultantData = useSelector((state) => state.consultant.consultant);
  const [consultantNotAvilableOn, setconsultantNotAvilableOn] = useState([]);
  useEffect(() => {
    dispatch(getConsultant(props.appId));
  }, []);
  useEffect(() => {
    if (consultantData !== null) {
      setconsultantNotAvilableOn(consultantData.notAvailableOn);
    }
  }, [consultantData]);
  const handleDateSelect = (selectInfo) => {
    var disableDates = [];
    for (var i = 0; i < consultantNotAvilableOn.length; i++) {
      disableDates.push(consultantNotAvilableOn[i]);
    }
    disableDates.push("" + selectInfo.start);
    let title = "Expert Consultant not available today";
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
      dispatch(disableConsultantsOn(props.appId, disableDates));
      dispatch(getConsultant(props.appId));
    }
  };
  for (var i = 0; i < consultantNotAvilableOn.length; i++) {
    var date = new Date(consultantNotAvilableOn[i]).toISOString();
    alreadyDisabledDates.push({
      title: "Expert Consultant not available today",
      date: date,
      eventTimeFormat: {
        // like '14:30:00'
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        meridiem: false,
      },
    });
  }
  return (
    <Container fluid>
      <ConfirmModal
        show={confirmModalShow}
        onHide={() => setconfirmModalShow(false)}
      />

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        select={handleDateSelect}
        events={alreadyDisabledDates}
        eventContent={renderEventContent}
      />
    </Container>
  );
};
function renderEventContent(eventInfo) {
  return (
    <div onClick={() => {}} className="bg-danger">
      <b>{eventInfo.event.title}</b>
    </div>
  );
}
export default ConsultantCalenderComp;
