import React from "react";
import "./styles.css";
// import cottagesImg from "../../../drawable/newhouses.png";
import { Col, Container, Row } from "react-bootstrap";
/**
 * @author
 * @function AboutUs
 **/

const AboutUs = (props) => {
  return (
    <div>
      <h1 className="aboutStyling mb-5">{props.content.head}</h1>
      <Container fluid>
        <Row>
          <Col sm={6}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/cottage-and-manor.appspot.com/o/landing_page_images%2Fabout.png?alt=media&token=38097222-f560-48db-ba05-0841edbeb950"
              className="w-100 mb-5"
              alt="back"
              // style={{ maxHeight: "40vh", objectFit: "cover" }}
            />
          </Col>
          <Col sm={6}>
            <p className="pt w-100">{props.content.data}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
