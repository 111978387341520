import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import * as fire from "firebase";
const uploadLandingScreenImage = async (uri, uid) => {
  const storage = fire.default.storage;
  const response = await fetch(uri);
  const blob = await response.blob();
  var ref = storage().ref("landing_page_images/").child(uid);
  const file = await ref.put(blob);

  return await storage()
    .ref("landing_page_images/")
    .child(uid)
    .getDownloadURL();
};
export default function ServicePage() {
  const [categories, setCategories] = useState([]);
  const [categoryModal, setCategoryModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceImage, setServiceImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [serviceModal, setServiceModal] = useState(false);
  const [data, setData] = useState(null);
  const [contentData, setContentData] = useState(null);
  const [timeframe, setTimeframe] = useState(1);
  const [price, setPrice] = useState(1);
  const [headlines, setHeadlines] = useState([]);
  const [terms, setTerms] = useState([]);

  const getData = async () => {
    const db = fire.default.firestore();
    const res = await db.collection("content").get();
    const content = {};
    res.forEach((e) => {
      const doc = e.data();
      content[doc.type] = { ...doc };
    });
    if (content?.headlines?.data) {
      setHeadlines(content?.headlines?.data);
    }
    if (content?.Mobile?.terms) {
      setTerms(content?.Mobile?.terms);
    }
    setData(content);
    setContentData(content);
  };
  const getMeetingInfo = async () => {
    const db = fire.default.firestore();
    const res = await db.collection("meetingValues").doc("data").get();
    const val = res.data();
    setTimeframe(val.timeframe);
    setPrice(val.price);
  };
  const getServices = async () => {
    const serviceSnap = await fire.default
      .firestore()
      .collection("services")
      .get();
    const arr = [];
    serviceSnap.forEach((e) => arr.push(e.data()));
    setCategories(arr);
  };
  const toggleCategoryModal = () => setCategoryModal(!categoryModal);
  const addCategory = async () => {
    await fire.default
      .firestore()
      .collection("services")
      .doc(categoryName)
      .set({ name: categoryName, services: [] });
    toggleCategoryModal();
    setCategoryName("");
    await getServices();
  };
  const deleteCategory = async () => {
    const cat = categories[selectedCategory].name;
    await fire.default.firestore().collection("services").doc(cat).delete();
    setSelectedCategory(-1);
    await getServices();
  };
  const addService = async () => {
    const cat = categories[selectedCategory].name;
    var storageRef = fire.default.storage().ref();
    var imageRef = storageRef.child(
      "services/" + Date.now() + "_" + serviceImage.name
    );
    const downloadObj = await imageRef.put(serviceImage);
    await fire.default
      .firestore()
      .collection("services")
      .doc(cat)
      .update({
        name: categories[selectedCategory].name,
        services: [
          ...categories[selectedCategory].services,
          { name: serviceName, image: await downloadObj.ref.getDownloadURL() },
        ],
      });
    // setCategories(categories.map((e, i) => (i === selectedCategory ? { ...e, services: [...e.services, { name: serviceName, image: URL.createObjectURL(serviceImage) }] } : e)));
    toggleServiceModal();
    setServiceName("");
    setServiceImage(null);
    await getServices();
  };
  const selectFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      setServiceImage(file);
    }
  };
  const toggleServiceModal = () => setServiceModal(!serviceModal);
  useEffect(() => {
    getServices();
    getData();
    getMeetingInfo();
  }, []);
  const contentUpdate = async (name) => {
    const section = name.split(".")[0];
    const field = name.split(".")[1];
    const value = contentData[section][field];
    await fire.default
      .firestore()
      .collection("content")
      .doc(section)
      .update({ [field]: value });
    await getData();
  };
  const addHeadline = () => {
    setHeadlines([...headlines, ""]);
  };
  const removeHeadline = (index) => {
    setHeadlines(headlines.filter((e, i) => i !== index));
  };
  const addTerms = () => {
    setTerms([...terms, ""]);
  };
  const removeTerms = (index) => {
    setTerms(terms.filter((e, i) => i !== index));
  };
  const udpateHeadlines = (e) => {
    const index = Number(e.target.name);
    const tempArr = [...headlines];
    tempArr[index] = e.target.value;
    setHeadlines(tempArr);
  };
  const headlinesUpdate = async (name, value) => {
    const section = name.split(".")[0];
    const field = name.split(".")[1];
    await fire.default
      .firestore()
      .collection("content")
      .doc(section)
      .update({ [field]: value });
    await getData();
  };
  const updateTerms = (e) => {
    const index = Number(e.target.name);
    const tempArr = [...terms];
    tempArr[index] = e.target.value;
    setTerms(tempArr);
  };
  const updateContent = (e) => {
    const section = e.target.name.split(".")[0];
    const field = e.target.name.split(".")[1];
    const value = e.target.value;
    setContentData({
      ...contentData,
      [section]: { ...contentData[section], [field]: value },
    });
  };
  const meetingInfoUpdate = async (name, value) => {
    await fire.default
      .firestore()
      .collection("meetingValues")
      .doc("data")
      .update({ [name]: value });
    await getMeetingInfo();
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <p className="nApps">Meeting Information</p>
            <Row className="px-3">
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Timeframe</Form.Label>
                <Form.Control
                  type="number"
                  value={timeframe}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      setTimeframe(e.target.value);
                    }
                  }}
                />
                <Button
                  className="mt-2"
                  onClick={() => meetingInfoUpdate("timeframe", timeframe)}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  value={price}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      setPrice(e.target.value);
                    }
                  }}
                />
                <Button
                  className="mt-2"
                  onClick={() => meetingInfoUpdate("price", price)}
                >
                  Update
                </Button>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="nApps">Terms & Conditions</p>
            {terms.map((e, i) => (
              <Form.Group className="d-flex align-items-center justify-content-between px-3">
                <Form.Control
                  style={{ height: 50, margin: 0 }}
                  value={e}
                  name={i}
                  onChange={updateTerms}
                />
                <Button
                  style={{ height: 50, margin: 0 }}
                  variant="danger"
                  className="ml-2"
                  onClick={() => removeTerms(i)}
                >
                  Delete
                </Button>
              </Form.Group>
            ))}
            <div className="d-flex align-items-center justify-content-end px-3">
              <Button onClick={addTerms}>Add Term</Button>
              <Button
                className="ml-2"
                onClick={() => headlinesUpdate("Mobile.terms", terms)}
              >
                Save Changes
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="nApps">Headlines</p>
          </Col>
          <Col xs={12}>
            {headlines.map((e, i) => (
              <Form.Group className="d-flex align-items-center justify-content-between px-3">
                <Form.Control
                  style={{ height: 50, margin: 0 }}
                  value={e}
                  name={i}
                  onChange={udpateHeadlines}
                />
                <Button
                  style={{ height: 50, margin: 0 }}
                  variant="danger"
                  className="ml-2"
                  onClick={() => removeHeadline(i)}
                >
                  Delete
                </Button>
              </Form.Group>
            ))}
            <div className="d-flex align-items-center justify-content-end px-3">
              <Button onClick={addHeadline}>Add Headline</Button>
              <Button
                className="ml-2"
                onClick={() => headlinesUpdate("headlines.data", headlines)}
              >
                Save Changes
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="nApps">Services</p>
          </Col>
        </Row>
        <Row className="px-3">
          <Col md={6} xs={12}>
            <Card>
              <Card.Body>
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <Card.Title className="m-0 p-0">Categories</Card.Title>
                  <Button onClick={toggleCategoryModal}>Add</Button>
                </div>
                <ListGroup as="ul">
                  {categories.map((e, i) => (
                    <ListGroup.Item
                      style={{ cursor: "pointer" }}
                      as="li"
                      onClick={() =>
                        i == selectedCategory
                          ? setSelectedCategory(-1)
                          : setSelectedCategory(i)
                      }
                      active={i == selectedCategory}
                    >
                      {e.name}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} xs={12}>
            <Card>
              <Card.Body>
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <Card.Title className="m-0 p-0">Services</Card.Title>
                  <div className="d-flex">
                    <Button
                      disabled={selectedCategory == -1}
                      onClick={toggleServiceModal}
                    >
                      {selectedCategory == -1 ? "Select a Category" : "Add"}
                    </Button>
                    <Button
                      className="ml-1"
                      disabled={selectedCategory == -1}
                      variant="danger"
                      onClick={deleteCategory}
                    >
                      {selectedCategory == -1 ? "Select a Category" : "Delete"}
                    </Button>
                  </div>
                </div>
                {selectedCategory !== -1 && (
                  <ListGroup as="ul">
                    {categories[selectedCategory].services.map((e, i) => (
                      <ListGroup.Item
                        style={{ cursor: "pointer" }}
                        as="li"
                        className="d-flex align-items-center justify-content-between"
                        //   onClick={() => (i == selectedCategory ? setSelectedCategory(-1) : setSelectedCategory(i))}
                        //   active={i == selectedCategory}
                      >
                        <img
                          src={e.image}
                          style={{ width: 20, height: 20, marginRight: 20 }}
                        />
                        <p style={{ flex: 1, margin: 0, padding: 0 }}>
                          {e.name}
                        </p>
                        <Button
                          onClick={async () => {
                            const cat = categories[selectedCategory];
                            await fire.default
                              .firestore()
                              .collection("services")
                              .doc(cat.name)
                              .update({
                                name: cat.name,
                                services: cat.services.filter(
                                  (s, si) => si !== i
                                ),
                              });
                            await getServices();
                          }}
                          variant="danger"
                        >
                          Delete
                        </Button>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="nApps">Landing Page Images</p>
            <Row className="px-3">
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Banner Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={async (e) => {
                    await uploadLandingScreenImage(
                      URL.createObjectURL(e.target.files[0]),
                      "banner.png"
                    );
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>About Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={async (e) => {
                    await uploadLandingScreenImage(
                      URL.createObjectURL(e.target.files[0]),
                      "about.png"
                    );
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Booking Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={async (e) => {
                    await uploadLandingScreenImage(
                      URL.createObjectURL(e.target.files[0]),
                      "booking.jpeg"
                    );
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Download App Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={async (e) => {
                    await uploadLandingScreenImage(
                      URL.createObjectURL(e.target.files[0]),
                      "download.jpg"
                    );
                  }}
                />
              </Form.Group>
            </Row>

            <p className="nApps">Landing Page Content</p>
            <Row className="px-3">
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Banner Heading</Form.Label>
                <Form.Control
                  value={contentData?.Banner?.head}
                  name="Banner.head"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("Banner.head")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Banner Text</Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ height: 200 }}
                  value={contentData?.Banner?.data}
                  name="Banner.data"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("Banner.data")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Banner Button Text</Form.Label>
                <Form.Control
                  value={contentData?.Banner?.button}
                  name="Banner.button"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("Banner.button")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>About Heading</Form.Label>
                <Form.Control
                  value={contentData?.about?.head}
                  name="about.head"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("about.head")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>About Text</Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ height: 200 }}
                  value={contentData?.about?.data}
                  name="about.data"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("about.data")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Booking Heading</Form.Label>
                <Form.Control
                  value={contentData?.booking?.head}
                  name="booking.head"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("booking.head")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Services Heading</Form.Label>
                <Form.Control
                  value={contentData?.services?.head}
                  name="services.head"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("services.head")}
                >
                  Update
                </Button>
              </Form.Group>
            </Row>
            <p className="nApps">Other Content</p>
            <Row className="px-3">
              <Form.Group as={Col} xs={12} md={12}>
                <Form.Label>Youtube Video Link</Form.Label>
                <Form.Control
                  value={contentData?.services?.youtube}
                  name="services.youtube"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("services.youtube")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={12}>
                <Form.Label>Mobile Homepage Text</Form.Label>
                <Form.Control
                  value={contentData?.Mobile?.homeText}
                  name="Mobile.homeText"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("Mobile.homeText")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Tagline</Form.Label>
                <Form.Control
                  value={contentData?.Mobile?.tagline}
                  name="Mobile.tagline"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("Mobile.tagline")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Facebook Link</Form.Label>
                <Form.Control
                  value={contentData?.Mobile?.fbLink}
                  name="Mobile.fbLink"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("Mobile.fbLink")}
                >
                  Update
                </Button>
              </Form.Group>
              {/* <Form.Group as={Col} xs={12} md={6}>
                                <Form.Label>Twitter Link</Form.Label>
                                <Form.Control value={contentData?.Mobile?.twitterLink} name="Mobile.twitterLink" onChange={updateContent} />
                                <Button className="mt-2" onClick={() => contentUpdate('Mobile.twitterLink')}>
                                    Update
                                </Button>
                            </Form.Group> */}
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  value={contentData?.Mobile?.phone}
                  name="Mobile.phone"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("Mobile.phone")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>WhatsApp Number</Form.Label>
                <Form.Control
                  value={contentData?.Mobile?.whatsAppNumber}
                  name="Mobile.whatsAppNumber"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("Mobile.whatsAppNumber")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>LinkedIn Link</Form.Label>
                <Form.Control
                  value={contentData?.Mobile?.linkedInLink}
                  name="Mobile.linkedInLink"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("Mobile.linkedInLink")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Instagram Link</Form.Label>
                <Form.Control
                  value={contentData?.Mobile?.instaLink}
                  name="Mobile.instaLink"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("Mobile.instaLink")}
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Form.Label>Contact Email</Form.Label>
                <Form.Control
                  value={contentData?.Mobile?.email}
                  name="Mobile.email"
                  onChange={updateContent}
                />
                <Button
                  className="mt-2"
                  onClick={() => contentUpdate("Mobile.email")}
                >
                  Update
                </Button>
              </Form.Group>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal show={categoryModal} onHide={toggleCategoryModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              placeholder="Enter Category Name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleCategoryModal}>
            Close
          </Button>
          <Button variant="primary" onClick={addCategory}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={serviceModal} onHide={toggleServiceModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {serviceImage && (
            <img
              src={URL.createObjectURL(serviceImage)}
              style={{ width: 100, height: 100 }}
            />
          )}
          <Form.Group>
            <Form.Label>Service Image</Form.Label>
            <Form.Control type="file" onChange={selectFile} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Service Name</Form.Label>
            <Form.Control
              placeholder="Enter Service Name"
              value={serviceName}
              onChange={(e) => setServiceName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleServiceModal}>
            Close
          </Button>
          <Button
            disabled={!serviceImage}
            variant="primary"
            onClick={addService}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
