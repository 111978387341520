import { authConstant } from "../constants";

const initState = {
  name: "",
  email: "",
  phone: "",
  signUpData: null,
  authenticating: false,
  authenticated: false,
  error: null,
  result: null,
  requestType: "SIGN_UP",
  tempRegistration: false,
  userAccountSignup: "l",
  userAccountLogin: "l",
  userData: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case `${authConstant.USER_LOGIN}_REQUEST`:
      return (state = {
        ...state,
        authenticating: true,
      });
    case `${authConstant.USER_LOGIN}_SUCCESS`:
      return (state = {
        ...state,
        authenticating: false,
        authenticated: true,
        userData: action.payload,
      });

    case `${authConstant.USER_LOGIN}_FAILURE`:
      return (state = {
        ...state,
        authenticated: false,
        authenticating: true,
      });
    case "SIGNED_OUT":
      return (state = {
        ...initState,
      });
    case "PHONE_NUMBER":
      return (state = {
        ...state,
        phone: action.payload,
      });
    case "USER_ACCOUNT_STATUS_FOR_SIGNUP":
      return (state = {
        ...state,
        userAccountSignup: action.payload,
      });
    case "USER_ACCOUNT_STATUS_FOR_LOGIN":
      return (state = {
        ...state,
        userAccountLogin: action.payload,
      });
    case "RESULT":
      return (state = {
        ...state,
        result: action.payload,
      });
    case "EMAIL":
      return (state = {
        ...state,
        email: action.payload,
      });
    case "LOGIN":
      return (state = {
        ...state,
        requestType: action.payload,
      });
    case "SIGN_UP":
      return (state = {
        ...state,
        requestType: action.payload,
      });
    case "USER_SIGNUP_DATA":
      return (state = {
        ...state,
        signUpData: action.payload,
      });
    case "TEMP_REG":
      return (state = {
        ...state,
        tempRegistration: true,
      });

    default:
      return state;
  }
};
