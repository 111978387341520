import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch } from "react-redux";
import {
  updateApproval,
  getAppointments,
} from "../../redux/actions/data.actions";

/**
 * @author
 * @function TypeHeadComp
 **/

const TypeHeadComp = (props) => {
  const dispatch = useDispatch();
  const [multiSelections, setMultiSelections] = useState([]);
  const arr = props.consultantName?.filter((e) => {
    return props.selectedServices.every((val) => e.services.includes(val));
  });
  const deleteAppointment = () => {
    dispatch(updateApproval(props.appointmentId, [], "rejected"));
    dispatch(getAppointments());
    setMultiSelections([]);
  };

  const updateApprovedAppointment = () => {
    if (multiSelections.length > 0) {
      dispatch(
        updateApproval(props.appointmentId, multiSelections, "approved")
      );
      dispatch(getAppointments());
      setMultiSelections([]);
    }
  };
  return (
    <div>
      {" "}
      <Form.Group style={{ marginTop: "20px" }} className="w-100">
        <Form.Label>Choose a consultant</Form.Label>
        <Typeahead
          id="basic-typeahead-multiple"
          labelKey="name"
          multiple
          selectHintOnEnter
          onChange={(e) => {
            setMultiSelections(e);
          }}
          options={arr}
          placeholder="Select"
          selected={multiSelections}
          size="lg"
        />
      </Form.Group>
      <div>
        <button className="pCancel p-2 mx-auto" onClick={deleteAppointment}>
          Cancel
        </button>
        <button
          className="pApprove p-2 mx-auto my-auto"
          onClick={updateApprovedAppointment}
        >
          Approve
        </button>
      </div>
    </div>
  );
};

export default TypeHeadComp;
