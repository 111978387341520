import React, { useState, useEffect } from "react";
import { Button, Col, Form, FormControl, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addToDatabase } from "../../../../redux/actions/data.actions";
import { sendPendingEmailToClient } from "../../../../redux/actions/email.actions";
import checkoutError from "../../../checkoutError";
import axios from "axios";
import { css } from "@emotion/react";
import DotLoader from "react-spinners/DotLoader";
import { useAlert } from "react-alert";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import * as fire from "firebase";

/**
 * @author
 * @function Step4
 **/
const db = fire.default.firestore();
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const Step4 = (props) => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [cardholderName, setCardholderName] = useState("");
  const [zipCode, setzipCode] = useState();
  const [meetingLength, setMeetingLength] = useState("0");
  const [address, setAddress] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [CVV, setCVV] = useState();
  const alert = useAlert();
  const [expiryDate, setExpiryDate] = useState();
  const stripe = useStripe();

  const elements = useElements();
  const [isProcessing, setProcessingTo] = useState(false);
  const [disableCard, setDisableCard] = useState(false);
  const [showCardsModal, setShowCardsModal] = useState(false);
  const [customer_id, setCustomer_id] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [cardOtherInfo, setcardOtherInfo] = useState(false);
  const [cards, setCards] = useState([]);
  const [checkoutError, setCheckoutError] = useState();
  const appointmentDate = useSelector((state) => state.data.appointmentDate);
  const appointmentTime = useSelector((state) => state.data.appointmentTime);
  const appointmentServices = useSelector(
    (state) => state.data.appointmentServices
  );
  const appointmentData = useSelector(
    (state) => state.data.nextAppointmentData
  );
  useEffect(() => {
    getCustomer();
  }, []);
  const NextStep = async (appointmentPaymentID) => {
    setProcessingTo(false);
    const cardData = {
      cardholderName,
      zipCode,
      meetingLength,
      address,
    };
    dispatch({ type: "CARD_DATA_SET", payload: cardData });
    const name = appointmentData.name;
    const email = appointmentData.email;
    const phone = appointmentData.phone;
    const notes = appointmentData.notes;
    const date = appointmentTime.toISOString();
    var toPay = "indecisive";
    switch (meetingLength) {
      case "1":
        toPay = "$" + props.price;
        break;
      case "2":
        toPay = "$" + props.price * 2;
        break;
      case "3":
        toPay = "$" + props.price * 3;
        break;
      case "4":
        toPay = "$" + props.price * 4;
        break;
      default:
        toPay = "indecisive";
    }
    const servicesToAdd = [];
    appointmentServices.forEach(
      (e) => servicesToAdd.includes(e) || servicesToAdd.push(e)
    );
    const saveToDb = {
      name,
      email,
      phone,
      notes,
      date,
      cardholderName,
      zipCode,
      meetingLength,
      address,
      cardNumber,
      CVV,
      expiryDate,
      appointmentServices: servicesToAdd,
      toPay,
      appointmentPaymentID,
    };
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    if (user) {
      dispatch(addToDatabase(saveToDb));
      props.onClick();
    } else {
    }
  };
  const handleSelection = (e) => {
    setMeetingLength(e.target.value);
  };

  const iframeStyles = {
    base: {
      color: "black",
      fontSize: "16px",
      iconColor: "black",
      "::placeholder": {
        color: "#403a60",
      },
    },
    invalid: {
      iconColor: "#FF0000",
      color: "#FF0000",
    },
    complete: {
      iconColor: "#7CFC00",
      color: "#403a60",
    },
  };

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };
  const handleFormSubmit = async (ev) => {
    ev.preventDefault();

    const billingDetails = {
      name: appointmentData.name,
      email: appointmentData.email,
      address: {
        city: address,
        line1: address,
        state: address,
        postal_code: zipCode,
      },
    };
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    const userId = user?.uid;
    const customerCreated = await axios.post(
      "https://us-central1-cottage-and-manor.cloudfunctions.net/createCustomer",
      { name: appointmentData.name, email: appointmentData.email }
    );
    await db
      .collection("users")
      .doc(userId)
      .update({ customerId: customerCreated.data.id });
    setloading(true);
    setProcessingTo(true);

    const cardElement = elements.getElement("card");
    var meetingPrice = 0.1;
    if (meetingLength === "1") {
      meetingPrice = props.price * 1;
    } else if (meetingLength === "2") {
      meetingPrice = props.price * 2;
    } else if (meetingLength === "3") {
      meetingPrice = props.price * 3;
    } else if (meetingLength === "4") {
      meetingPrice = props.price * 4;
    }
    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails,
      });
      await axios.post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/attachPaymentToCustomer",
        {
          customer: customerCreated.data.id,
          payment_method: paymentMethodReq.paymentMethod.id,
        }
      );
      const { data: clientSecret } = await axios.post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/stripePayment",
        {
          amount: meetingPrice * 100,
          customer: customerCreated.data.id,
          source: paymentMethodReq.paymentMethod.id,
        }
      );

      // const paymentMethodReq = await stripe.createSource({
      //   type: "card",
      //   card: cardElement,
      //   billing_details: billingDetails,
      // });
      // console.log({ paymentMethodReq });
      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const appointmentPaymentID = clientSecret.id;
      const { error } = await stripe.confirmCardPayment(
        clientSecret.client_secret,
        {
          payment_method: paymentMethodReq.paymentMethod.id,
        }
      );

      if (error) {
        alert.error("There has been a problem processing your request");
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }
      // alert("Payment Successful");
      alert.success("Payment Successfull");
      NextStep(appointmentPaymentID);
    } catch (err) {
      alert.error("There has been a problem processing your request");
      setCheckoutError(err.message);
    }
  };
  const handleFormSubmitOther = async (ev) => {
    ev.preventDefault();

    var meetingPrice = 0.1;
    if (meetingLength === "1") {
      meetingPrice = props.price * 1;
    } else if (meetingLength === "2") {
      meetingPrice = props.price * 2;
    } else if (meetingLength === "3") {
      meetingPrice = props.price * 3;
    } else if (meetingLength === "4") {
      meetingPrice = props.price * 4;
    }
    try {
      const { data: clientSecret } = await axios.post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/stripePayment",
        {
          amount: meetingPrice * 100,
          customer: customer_id,
          source: paymentMethod,
        }
      );

      const appointmentPaymentID = clientSecret.id;
      const { error } = await stripe.confirmCardPayment(
        clientSecret.client_secret,
        {
          payment_method: paymentMethod,
        }
      );

      if (error) {
        alert.error("There has been a problem processing your request");
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }
      // alert("Payment Successful");
      alert.success("Payment Successfull");
      NextStep(appointmentPaymentID);
    } catch (err) {
      alert.error("There has been a problem processing your request");
      setCheckoutError(err.message);
    }
  };
  const getCustomer = async () => {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    const userId = user.uid;
    const updatedUser = await (
      await db.collection("users").doc(userId).get()
    ).data();
    if (updatedUser.customerId) {
      const cus = await axios.post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/getCustomer",
        { customer: updatedUser.customerId }
      );
      setCustomer_id(updatedUser.customerId);
      const tempCards = cus.data.paymentMethods.data.map((e) => ({
        id: e.id,
        brand: e.card.brand,
        exp_month: e.card.exp_month,
        exp_year: e.card.exp_year,
        last4: e.card.last4,
      }));
      setCards(tempCards);

      setShowCardsModal(true);
    }
  };
  return (
    <div>
      {loading && !checkoutError ? (
        <>
          <DotLoader
            css={override}
            size={50}
            color={"#403a60"}
            loading={loading}
          />
        </>
      ) : (
        <>
          <Form>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="text-left">Cardholder's Name</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Name"
                  onChange={(e) => setCardholderName(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Zip Code (Billing)</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter zip code"
                  onChange={(e) => setzipCode(e.target.value)}
                />
              </Form.Group>
              <div className="d-flex flex-column w-100">
                <Form.Control
                  as="select"
                  className={`mr-sm-2 ${meetingLength == 0 && "error"}`}
                  id="inlineFormCustomSelect"
                  custom
                  onChange={handleSelection}
                >
                  <option value="0">Meeting Length</option>
                  <option value="1">{props.timeframe * 1} minutes</option>
                  <option value="2">{props.timeframe * 2} minutes</option>
                  <option value="3">{props.timeframe * 3} minutes</option>
                  <option value="4">{props.timeframe * 4} minutes</option>
                </Form.Control>
                {meetingLength == 0 && (
                  <div className="error-text my-2 ml-1">
                    Please fill this field
                  </div>
                )}
              </div>
            </Form.Row>
            <Form.Label>Address (Billing)</Form.Label>
            <Form.Control
              as="textarea"
              className="w-50"
              rows={1}
              placeholder="Enter Address"
              onChange={(e) => setAddress(e.target.value)}
            />

            <Form.Label className="mt-2">Enter Card details</Form.Label>
          </Form>
        </>
      )}

      {!paymentMethod ? (
        <div className="w-100 mt-2" style={{ borderRadius: "4px" }}>
          <CardElement
            options={{
              iconStyle: "solid",
              style: iframeStyles,
              hidePostalCode: true,
            }}
            onChange={handleCardDetailsChange}
          />
        </div>
      ) : (
        <div>{cardOtherInfo}</div>
      )}
      {checkoutError && <checkoutError>{checkoutError}</checkoutError>}
      <Modal
        show={showCardsModal}
        onHide={() => setShowCardsModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select a card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl
            onChange={(e) => {
              setPaymentMethod(e.target.value);
              const card = cards.find((c) => c.id == e.target.value);
              setcardOtherInfo(
                `${card.brand} card ending with ${card.last4}, expiry ${card.exp_month}/${card.exp_year}`
              );
            }}
            as="select"
          >
            <option value={null} disabled selected>
              Select a card
            </option>
            {cards.map((e) => (
              <option
                value={e.id}
              >{`${e.brand.toUpperCase()} card ending with ${e.last4}, expiry ${
                e.exp_month
              }/${e.exp_year}`}</option>
            ))}
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCardsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <button
        className="cService mt-3 px-3 p-2"
        onClick={paymentMethod ? handleFormSubmitOther : handleFormSubmit}
        // onClick={() => NextStep("something")}
        disabled={isProcessing || !stripe || meetingLength === "0"}
      >
        {meetingLength === "0"
          ? "Select meeting length"
          : meetingLength === "1"
          ? `Pay $${props.price * 1} for the appointment`
          : meetingLength === "2"
          ? `Pay $${props.price * 2} for the appointment`
          : meetingLength === "3"
          ? `Pay $${props.price * 3} for the appointment`
          : meetingLength === "4"
          ? `Pay $${props.price * 4} for the appointment`
          : null}
      </button>
    </div>
  );
};

export default Step4;
