import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import "./calenderStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { getConsultantData } from "../../../../redux/actions/consultant.actions";
import { Typeahead } from "react-bootstrap-typeahead";
import tz from "moment-timezone";
import * as firebase from "firebase";
import moment from "moment";
export const timezone = "America/Chicago";
// const timezone = "Asia/Karachi";
/**
 * @author
 * @function Step3
 **/
const DateInput = ({ dates, onSelect }) => {
  return (
    <Form.Group>
      <Form.Label>Select Date</Form.Label>
      <Typeahead
        id="basic-typeahead-single"
        labelKey="name"
        onChange={onSelect}
        options={dates}
        placeholder="Choose a date"
        // selected={singleSelections}
      />
    </Form.Group>
  );
};
const TimeInput = ({ times, onSelect }) => {
  return (
    <Form.Group>
      <Form.Label>Select Time</Form.Label>
      <Typeahead
        id="basic-typeahead-single"
        labelKey="name"
        onChange={onSelect}
        options={times
          .map((time) => {
            const value = tz().tz(timezone);
            const [hours, minutes] = time.split(":");
            value.hours(Number(hours));
            value.minutes(Number(minutes));

            return {
              id: time,
              name: value.local().format("HH:mm"),
              sortBy: Number(hours) * 60 + Number(minutes),
            };
          })
          .sort((a, b) => a.sortBy - b.sortBy)}
        placeholder="Choose a time"
        // selected={singleSelections}
      />
    </Form.Group>
  );
};

const Step3 = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [datesToShow, setDatesToShow] = useState([]);
  const [timesToShow, setTimesToShow] = useState([]);
  const selectedServices = useSelector(
    (state) => state.data.appointmentServices
  );
  const allConsultantsData = useSelector(
    (state) => state.consultant.consultantData
  );
  const dispatch = useDispatch();
  const datesForNextWeeks = (numberOfWeeks) => {
    const dates = [];
    const today = tz.utc().tz(timezone);

    for (let i = 0; i < numberOfWeeks * 7; i++) {
      dates.push(tz.utc(today).tz(timezone));
      today.add(1, "d");
    }
    setDatesToShow(dates);
    setTimesToShow([]);
  };
  const [confirmModalShow, setconfirmModalShow] = useState(false);
  useEffect(() => {
    datesForNextWeeks(4);
    dispatch(getConsultantData());
  }, []);

  const onDateSelect = async (e) => {
    const ds = datesToShow.find(
      (d) => d.format("ddd MMM DD YYYY").toUpperCase() == e
    );
    if (ds) {
      setSelectedDate(ds.toISOString());
      let day = null;
      switch (ds.day()) {
        case 0: {
          day = "sunday";
          break;
        }
        case 1: {
          day = "monday";
          break;
        }
        case 2: {
          day = "tuesday";
          break;
        }
        case 3: {
          day = "wednesday";
          break;
        }
        case 4: {
          day = "thursday";
          break;
        }
        case 5: {
          day = "friday";
          break;
        }
        case 6: {
          day = "saturday";
          break;
        }
      }
      const timesTemp = [];
      const auth = firebase.default.auth();
      const firestore = firebase.default.firestore;
      if (!auth.currentUser) {
        await auth.signInAnonymously();
      }
      const appontments = (await firestore().collection("appointments").get())
        .docs;
      const consultants = (await firestore().collection("consultants").get())
        .docs;

      consultants
        .filter((c) => c.data().active)
        ?.forEach((consult) => {
          const consultant = consult.data();
          const doesProvideTheService = selectedServices.every((val) =>
            consultant.services.includes(val)
          );

          if (doesProvideTheService) {
            const aptsForConsultantForToday = appontments.filter((aa) => {
              const apt = aa.data();
              let isForConsultant = false;
              let selectedAptDate = moment(ds).tz(timezone);
              let aptDateTemp = moment(new Date(apt.date)).tz(timezone);
              if (
                selectedAptDate.format("ddd MMM DD YYYY") !==
                aptDateTemp.format("ddd MMM DD YYYY")
              ) {
                return false;
              }
              apt.consultants
                .filter((c) => c.active)
                .forEach((c) => {
                  if (String(c.id) == String(consultant.id)) {
                    isForConsultant = true;
                  }
                });
              return isForConsultant;
            });

            const otherDatesOccupied = [];
            const timesBooked = aptsForConsultantForToday.map((aa) => {
              const apt = aa.data();
              console.log("apt: ", apt);
              let aptDateTemp = tz(new Date(apt.date)).tz(timezone);
              const minutesToAdd =
                aptDateTemp.minutes() < 10
                  ? "0" + aptDateTemp.minutes()
                  : aptDateTemp.minutes();
              const hoursToAdd =
                aptDateTemp.hours() < 10
                  ? "0" + aptDateTemp.hours()
                  : aptDateTemp.hours();
              const temp = hoursToAdd + ":" + minutesToAdd;
              const counterDate = tz(new Date(apt.date)).tz(timezone);

              for (let index = 0; index < Number(apt.meetingLength); index++) {
                counterDate.add(15, "minutes");
                let tempHours2 = counterDate.hours();
                let tempMins2 = counterDate.minutes();
                const minutesToAdd2 =
                  tempMins2 < 10 ? "0" + tempMins2 : tempMins2;
                const hoursToAdd2 =
                  tempHours2 < 10 ? "0" + tempHours2 : tempHours2;
                otherDatesOccupied.push(hoursToAdd2 + ":" + minutesToAdd2);
              }
              return temp;
            });
            const hoursForToday = consultant.availability[day];
            const from = hoursForToday.from.split(":");
            const to = hoursForToday.to.split(":");
            const todayDateTime = new Date();

            if (
              parseInt(to[0]) <=
                parseInt(todayDateTime.toLocaleTimeString().split(":")[0]) &&
              ds.day() === todayDateTime.getDay()
            ) {
              return timesTemp.push("00:00");
            }

            if (
              parseInt(from[0]) <=
                parseInt(todayDateTime.toLocaleTimeString().split(":")[0]) &&
              ds.day() === todayDateTime.getDay()
            ) {
              from[0] = (
                parseInt(todayDateTime.toLocaleTimeString().split(":")[0]) + 1
              ).toString();
              const myDateTimeMinutes = Number(
                todayDateTime.toLocaleTimeString().split(":")[1]
              );
              if (myDateTimeMinutes < 15) {
                from[1] = "00";
              } else if (myDateTimeMinutes < 30) {
                from[1] = "15";
              } else if (myDateTimeMinutes < 45) {
                from[1] = "30";
              } else {
                from[1] = "45";
              }
            }

            const fromHours = Number(from[0]);
            const fromMins = Number(from[1]);

            const toHours = Number(to[0]);
            const toMins = Number(to[1]);

            let counterDate = tz(ds).tz(timezone).startOf("day");

            //   let counterDate = new Date();
            counterDate.hours(fromHours);
            counterDate.minutes(fromMins);
            let toDate = tz(ds).tz(timezone).startOf("day");
            toDate.hours(toHours);
            toDate.minutes(toMins);
            const todayDate = tz(ds).tz(timezone).startOf("day");
            const minDate = tz().tz(timezone).add(1, "hour");

            if (
              todayDate.format("MMM DD YYYY") ===
              tz().tz(timezone).format("MMM DD YYYY")
            ) {
              todayDate.add(1, "hour");
            } else {
              todayDate.hours(fromHours);
              todayDate.minutes(fromMins);
            }
            for (
              let index = 0;
              counterDate.toDate() <= toDate.toDate();
              index++
            ) {
              if (counterDate.isAfter(minDate)) {
                let tempHours = counterDate.hours();
                let tempMins = counterDate.minutes();
                const minutesToAdd = tempMins < 10 ? "0" + tempMins : tempMins;
                const hoursToAdd = tempHours < 10 ? "0" + tempHours : tempHours;
                const temp = hoursToAdd + ":" + minutesToAdd;

                if (
                  !timesTemp.includes(temp) &&
                  // counterDate.toDate() > todayDate.toDate() &&
                  !timesBooked.includes(temp) &&
                  !otherDatesOccupied.includes(temp)
                ) {
                  timesTemp.push(temp);
                }
              }

              counterDate.add(15, "minutes");
            }
          }
        });
      setTimesToShow(timesTemp);
      dispatch({ type: "ADDING_DATE", payload: ds });
    }
  };
  return (
    <Container fluid>
      {/* <p className="text-small">Note: All times are US Central</p> */}
      <DateInput
        dates={datesToShow.map((e) =>
          e.format("ddd MMM DD YYYY").toUpperCase()
        )}
        onSelect={onDateSelect}
      />
      <TimeInput
        times={timesToShow}
        onSelect={(e) => {
          const ds = timesToShow.find((d) => d === e[0].id);
          if (selectedDate && ds) {
            const hours = Number(ds.split(":")[0]);
            const minutes = Number(ds.split(":")[1]);
            const newDate = tz(selectedDate).utc().tz(timezone);
            newDate.hours(hours);
            newDate.minutes(minutes);
            setSelectedDate(newDate);
            dispatch({ type: "SET_TIME", payload: newDate });
          }
        }}
      />
    </Container>
  );
};
export default Step3;
