import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import dataReducer from "./data.reducer";
import nextAppointmentReducer from "./next.appointment.reducer";
import consultantReducer from "./consultant.reducer";
import appointmentReducer from "./appointment.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  data: dataReducer,
  nextAppointment: nextAppointmentReducer,
  consultant: consultantReducer,
  appointment: appointmentReducer,
});

export default rootReducer;
