import firebase from "firebase";

const config = {
  apiKey: "AIzaSyAO5DzEWCDujBu5vbcK2B8sp8SxnFPGFmM",
  authDomain: "cottage-and-manor.firebaseapp.com",
  databaseURL: "https://cottage-and-manor.firebaseio.com",
  projectId: "cottage-and-manor",
  storageBucket: "cottage-and-manor.appspot.com",
  messagingSenderId: "681625367382",
  appId: "1:681625367382:web:06eed1c0854297e08e5cf7",
  measurementId: "G-D7XM0SZGYD",
};
firebase.initializeApp(config);
export default firebase;
