import { BrowserRouter as Router, Route } from "react-router-dom";
import NavBar from "./components/navBar";
import React, { useEffect, useState } from "react";
import * as firebase from "firebase";
import { useDispatch, useSelector } from "react-redux";
import { isLogedIn } from "./redux/actions/auth.actions";
import ClientRoute from "./components/userTypes/Client";
import OperatorRoute from "./components/userTypes/Operator";
import {
  Confermation,
  ContinueSignUp,
  InputCode,
  LandingPage,
  Login,
  PrivacyPolicy,
  SignUp,
  TermsAndCondition,
} from "./Pages";
function App() {
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const userType = useSelector((state) => state.auth.userData);
  useEffect(() => {
    firebase.default.auth().signInAnonymously();
    if (!auth.authenticated) {
      dispatch(isLogedIn());
    }
  }, []);
  useEffect(() => {
    if (userType !== null) {
      setType(userType.user?.type);
    }
  }, [userType]);
  return (
    <Router>
      <NavBar />
      <Route path="/login" exact component={Login} />
      <Route path="/confermation" exact component={Confermation} />
      <Route path="/code" exact component={InputCode} />
      <Route path="/signup" exact component={SignUp} />
      <Route path="/continueSignUp" exact component={ContinueSignUp} />
      <Route path="/terms" exact component={TermsAndCondition} />
      <Route path="/privacy" exact component={PrivacyPolicy} />
      <Route path="/" exact component={LandingPage} />
      {type === "client" || type === "consultant" ? (
        <ClientRoute />
      ) : type === "operator" ? (
        <OperatorRoute />
      ) : (
        <></>
      )}
    </Router>
  );
}

export default App;
