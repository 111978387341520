const initState = {
  data: null,
  setting: false,
  nextAppointmentData: null,
  appointmentDate: "",
  appointmentTime: "",
  cardData: null,
  allServices: null,
  allAppointmentDates: null,
  appointmentServices: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action) => {
  switch (action.type) {
    case "GET_DATA_REQUEST":
      return state;
    case "GET_DATA_SUCCESS":
      return (state = {
        ...state,
        data: action.payload,
      });
    case "SET_DATA_REQUEST":
      return state;
    case "SET_DATA_SUCCESS":
      return (state = {
        ...state,
        setting: true,
        nextAppointmentData: action.payload,
      });
    case "ADDING_DATE":
      return (state = {
        ...state,
        setting: true,
        appointmentDate: action.payload,
      });
    case "SET_TIME":
      return (state = {
        ...state,
        setting: true,
        appointmentTime: action.payload,
      });
    case "CARD_DATA_SET":
      return (state = {
        ...state,
        setting: true,
        cardData: action.payload,
      });
    case "ALL_SERVICE_DATA":
      return (state = {
        ...state,
        setting: true,
        allServices: action.payload,
      });
    case "ALL_APPOINTMENT_DATES":
      return (state = {
        ...state,
        setting: true,
        allAppointmentDates: action.payload,
      });
    case "SET_APPOINTMENT_SERVICES":
      return {
        ...state,
        appointmentServices: action.payload,
      };
    default:
      return state;
  }
};
