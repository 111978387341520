import * as fire from "firebase";
import {
  sendCanceledEmailToClient,
  sendCancelEmailToConsultant,
  sendEmailToConsultant,
  sendUpdateEmailToClient,
  sendUpdateToConsultant,
} from "./email.actions";
import axios from "axios";

export const modificationRequest = (data) => {
  return async (dispatch) => {
    const db = fire.default.firestore();
    var docRef = db.collection("appointments").doc(data.appointmentID);
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          var modRef = db.collection("modificationRequests").doc();
          var id = modRef.id;
          modRef
            .set({
              name: doc.data().name,
              appointmentID: data.appointmentID,
              date: doc.data().date,
              consultants: doc.data().consultants,
              description: data.modDescription,
              id: id,
              status: "pending",
            })
            .then(function (docRef) {
              console.log("Document written with ID: ", docRef);
              window.location.reload();
            })
            .catch(function (error) {
              console.error("Error adding document: ", error);
            });
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };
};
export const getModRequests = () => {
  return async (dispatch) => {
    const db = fire.default.firestore();
    const data = [];
    db.collection("modificationRequests")
      .where("status", "==", "pending")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          data.push(doc.data());
        });
        dispatch({ type: "MOD_REQUEST", payload: data });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
};
export const closeModificationRequest = (docName) => {
  return async (dispatch) => {
    const db = fire.default.firestore();
    var washingtonRef = db.collection("modificationRequests").doc(docName);
    return washingtonRef
      .update({
        status: "Closed",
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };
};
export const updateAppointmentwithConsultants = (
  docName,
  newConsultants,
  date
) => {
  return async (dispatch) => {
    for (var i = 0; i < newConsultants.length; i++) {
      dispatch(sendEmailToConsultant(newConsultants[i].email, date, docName));
    }

    const db = fire.default.firestore();
    var washingtonRef = db.collection("appointments").doc(docName);
    return washingtonRef
      .update({
        consultants: newConsultants,
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };
};
export const updateAppointmentDate = (docName, date) => {
  return async (dispatch) => {
    const db = fire.default.firestore();

    var appRef = db.collection("appointments").doc(docName);
    appRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          dispatch(
            sendUpdateEmailToClient(
              doc.data().email,
              doc.data().date,
              date,
              docName
            )
          );

          for (var i = 0; i < doc.data().consultants.length; i++) {
            dispatch(
              sendUpdateToConsultant(
                doc.data().consultants[i].email,
                doc.data().date,
                date,
                docName
              )
            );
          }

          console.log("Document data:", doc.data());
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

    var appointmentRef = db.collection("appointments").doc(docName);
    return appointmentRef
      .update({
        date: "" + date,
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };
};
export const getEveryAppointment = () => {
  return async (dispatch) => {
    const data = [];

    const db = fire.default.firestore();
    db.collection("appointments")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          data.push(doc.data());
        });
        dispatch({ type: "ALL_APPS", payload: data });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
};
export const APPOINTMENTS_ACTION_START_MEETING = (roomName) => {
  return async (dispatch) => {
    try {
      const db = fire.default.firestore();
      const res = await db.collection("appointments").doc(roomName).get();
      const result = res.data();
      if (result.roomSid) {
        dispatch({
          type: "SET_APPOINTMENT_SID",
          payload: result.roomSid,
        });
      } else {
        const response = await axios.post(
          "https://us-central1-cottage-and-manor.cloudfunctions.net/createRoom",
          {
            roomName: roomName,
          }
        );
        console.log(response.data);
        await db.collection("appointments").doc(roomName).update({
          roomSid: response.data.newRoom.sid,
        });
        dispatch({
          type: "SET_APPOINTMENT_SID",
          payload: response.data.newRoom.sid,
        });
      }
    } catch (error) {
      console.log(error);
      throw "Invalid code or phone number";
    }
  };
};
export const deleteAppointment = (docId) => {
  return async (dispatch) => {
    const db = fire.default.firestore();

    var appRef = db.collection("appointments").doc(docId);
    appRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          dispatch(sendCanceledEmailToClient(doc.data().email, docId));

          for (var i = 0; i < doc.data().consultants.length; i++) {
            dispatch(
              sendCancelEmailToConsultant(
                doc.data().consultants[i].email,
                docId
              )
            );
          }

          console.log("Document data:", doc.data());
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

    db.collection("appointments")
      .doc(docId)
      .delete()
      .then(function () {
        console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  };
};
