import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import CustomService from "./customService";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";

/**
 * @author
 * @function OurServices
 **/

const OurServices = (props) => {
  const [services, setServices] = useState([]);
  const data = useSelector((state) => state.data);
  useEffect(() => {
    if (data.allServices !== null) {
      const servicesToPush = [];
      data.allServices.forEach((e) => servicesToPush.push(...e.services));
      setServices(servicesToPush);
    }
  }, [data]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const youtubeId = props.content.youtube
    .replaceAll("https://www.youtube.com/watch?v=", "")
    .split("&")[0];
  return (
    <Container className="mt-5">
      <Container className="mt-5">
        <h1 className="headerStyling text-center w-100">
          {props.content.head}
        </h1>
        <div className="d-flex flex-column flex-md-row align-items-start justify-content-between mt-4">
          <Step
            label={"i"}
            description={
              "Download the app (for free) and register using only an email and phone number"
            }
          />
          <Step
            label={"ii"}
            description={
              "Book and pay for an appointment online or on the app (10min blocks of time). Each block is only $55"
            }
          />
          <Step
            label={"iii"}
            description={
              "Open the app and join the meeting shortly before the start. Return later to review the notes"
            }
          />
        </div>
        <p
          className="pSeperater mx-auto"
          style={{ width: "50%", textAlign: "center" }}
        ></p>
      </Container>
    </Container>
  );
};

const Step = ({ label, description }) => (
  <div className="d-flex flex-column p-4" style={{ flex: 1 }}>
    <div className="w-50 mx-auto">
      <h2
        style={{
          width: "100%",
          textAlign: "center",
          borderBottom: "1px solid #000",
          lineHeight: "0.1em",
          margin: "10px 0 20px",
        }}
      >
        <span
          style={{
            background: "#fff",
            padding: "0 20px",
          }}
        >
          {label}
        </span>
      </h2>
    </div>

    <div className="text-center pt-4">{description}</div>
  </div>
);

export default OurServices;
