import { MDBCol, MDBIcon } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PersonServices from "../../components/personServices";
import hammer from "../../drawable/hammer.png";
import { Edit } from "@material-ui/icons";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  getRequestedAppointment,
  getModsForAppointment,
} from "../../redux/actions/data.actions";
import AddIcon from "@material-ui/icons/Add";
import "./styles.css";
import ModApprovalCard from "../../components/modApprovalPersonCard";
import { getConsultantData } from "../../redux/actions/consultant.actions";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  updateAppointmentDate,
  updateAppointmentwithConsultants,
  deleteAppointment,
} from "../../redux/actions/appointment.actions";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";

/**
 * @author
 * @function NextAppointments
 **/

const AppointmentToReview = (props) => {
  const dispatch = useDispatch();
  const [days, setDays] = useState("");
  const [hours, setHours] = useState("");
  const [mins, setMins] = useState("");
  const [displayDate, setDisplayDate] = useState("asdasd");
  const [services, setServices] = useState([]);
  const [client, setClient] = useState("");
  const [consultants, setConsultants] = useState([]);
  const [mods, setMods] = useState([]);
  const [paymentID, setPaymentID] = useState("");
  const [consultantName, setConsultantName] = useState([]);
  const [confirmModalShow, setconfirmModalShow] = React.useState(false);

  const [DaT, setDat] = React.useState(false);
  const [askConfirm, setAskConfirm] = React.useState(false);
  const appData = useSelector((state) => state.appointment.appointmentData[0]);
  const appointmentID = useSelector(
    (state) => state.nextAppointment.appointmentId
  );
  const requestedMods = useSelector((state) => state.appointment.requestedMods);
  const consultantData = useSelector(
    (state) => state.consultant.consultantData
  );
  useEffect(() => {
    dispatch(getRequestedAppointment(appointmentID));
    dispatch(getModsForAppointment(appointmentID));
    dispatch(getConsultantData());
  }, []);
  useEffect(() => {
    setConsultantName(consultantData);
  }, [consultantData]);

  useEffect(() => {
    setMods(requestedMods);
  }, [requestedMods]);
  useEffect(() => {
    if (appData !== undefined) {
      setDisplayDate(appData.date);
      setPaymentID(appData.appointmentPaymentID);
      setServices(appData.servicesProvided);
      setClient(appData.name);
      setConsultants(appData.consultants);
      var countDownDate = new Date(appData.date).getTime();
      var x = setInterval(function () {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

        setDays(("0" + days).slice(-2));
        setHours(("0" + hours).slice(-2));
        setMins(("0" + minutes).slice(-2));
      }, 1000);
      return () => {
        clearInterval(x);
      };
    }
  }, [appData]);

  const refresh = () => {
    dispatch(getRequestedAppointment(appointmentID));
    dispatch(getModsForAppointment(appointmentID));
    dispatch(getConsultantData());
  };
  const addInspector = () => {
    setconfirmModalShow(true);
  };
  const changeDateAndTime = () => {
    setDat(true);
  };
  const CancelAppointment = () => {
    setAskConfirm(true);
  };
  return (
    <div>
      {/* main banner */}
      <ConfirmModal
        show={confirmModalShow}
        onHide={() => setconfirmModalShow(false)}
        consultantName={consultantName}
        selectedServices={services}
        appointmentId={appointmentID}
        appConsultants={consultants}
        aD={displayDate}
      />
      <SetDateAndTime
        show={DaT}
        onHide={() => setDat(false)}
        currentDate={displayDate}
        appointmentId={appointmentID}
      />
      <AskConfermation
        show={askConfirm}
        onHide={() => setAskConfirm(false)}
        h={props.history}
        appointmentId={appointmentID}
        payId={paymentID}
      />
      <div className="bannerStyle text-center d-flex flex-column">
        <div className="d-flex flex-row justify-content-center w-100 position-relative">
          <p className="bannerDate mx-auto">Appointment to review</p>
          <button
            className="cancelButtonDesign p-2"
            style={{ position: "absolute", right: 0 }}
            onClick={CancelAppointment}
          >
            Cancel appointment
          </button>
          {/* <button
            className="cancelButtonDesign p-2"
            style={{ position: "absolute", left: 0 }}
            onClick={() => {
              props.history.push("/call/" + appointmentID);
            }}
          >
            Join meeting
          </button> */}
        </div>

        <Row className="text-center align-self-center">
          <Col className="coloumCountStyle">
            <h1 className="countdownStyle">{days}</h1>
            <p className="days pt-2">days</p>
          </Col>
          <Col className="coloumCountStyle">
            <h1 className="countdownStyle">{hours}</h1>
            <p className="days pt-2">hours</p>
          </Col>
          <Col className="coloumCountStyle">
            <h1 className="countdownStyle">{mins}</h1>
            <p className="days pt-2">minutes</p>
          </Col>
        </Row>

        <div className="d-flex flex-row justify-content-center align-items-center pb-4">
          <div className="bannerDate">{displayDate}</div>
          <Edit
            className="mt-4"
            style={{ color: "#ffffff" }}
            onClick={changeDateAndTime}
          />
        </div>
      </div>
      {/* SERVICES */}
      <div>
        <div>
          <p className="serviceStyling">Services</p>
        </div>
        <Row>
          {services?.map((s) => (
            <div className="servicePill p-3 d-flex flex-row align-item-center">
              <img src={hammer} className="hammerIcon" alt="back" />
              <p className="servicetext">{s}</p>
            </div>
          ))}
        </Row>
      </div>

      <p className="lineSeperater"></p>

      <div>
        <p className="serviceStyling">Members</p>
        <Row>
          <div className="servicePill px-4">
            <p className="servicetext text-center">{client}</p>
            <p className="JobTitle mx-auto mb-0 pb-2">Client</p>
          </div>

          <PersonServices
            toDisplay={consultants}
            appId={appointmentID}
            refresh={refresh}
          />

          <div className="servicePill px-4">
            <AddIcon
              className="mt-1 mx-auto"
              fontSize={"large"}
              onClick={addInspector}
            />
          </div>
        </Row>
      </div>
      <p className="lineSeperater"></p>
      <div>
        <Row>
          <p className="serviceStyling">Modification Requests</p>

          <MDBCol md="6" className="mx-auto">
            <form className="form-inline mt-4 mb-4">
              <input
                className="form-control form-control-sm ml-3 w-75"
                type="text"
                placeholder="Search"
                aria-label="Search"
              />
              <MDBIcon icon="search" />
            </form>
          </MDBCol>
        </Row>

        <Row>
          <ModApprovalCard dataToRender={mods} refresh={refresh} />
        </Row>
      </div>
    </div>
  );
};
function ConfirmModal(props) {
  const dispatch = useDispatch();
  const [multiSelections, setMultiSelections] = useState([]);
  const arr = props.consultantName?.filter((e) =>
    e.services.some((val) => props.selectedServices.includes(val))
  );
  const setApp = () => {
    const allConsultants = props.appConsultants;
    for (var i = 0; i < multiSelections.length; i++) {
      allConsultants.push(multiSelections[i]);
    }
    dispatch(
      updateAppointmentwithConsultants(
        props.appointmentId,
        allConsultants,
        props.aD
      )
    );
    props.onHide();
    //dispatch(getRequestedAppointment(props.appointmentID));
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Form.Group style={{ marginTop: "20px" }} className="w-100">
          <Form.Label>Choose a consultant</Form.Label>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="name"
            multiple
            selectHintOnEnter
            onChange={(e) => {
              setMultiSelections(e);
            }}
            options={arr}
            placeholder="Select"
            selected={multiSelections}
            size="lg"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button className="pApprove" onClick={setApp}>
          save
        </button>
        <button className="pApprove" onClick={props.onHide}>
          No
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function SetDateAndTime(props) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  useEffect(() => {
    setSelectedDate(new Date(props.currentDate));
  }, [props.currentDate]);
  const setApp = () => {
    dispatch(updateAppointmentDate(props.appointmentId, selectedDate));
    props.onHide();
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {" "}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Pick a date"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Pick a time"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Modal.Body>
      <Modal.Footer>
        <button className="pApprove" onClick={setApp}>
          save
        </button>
        <button className="pApprove" onClick={props.onHide}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}
function AskConfermation(props) {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const setApp = async () => {
    setloading(true);
    try {
      const { data: refund } = await axios.post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/stripeRefund",
        {
          payment_intent: props.payId,
        }
      );
      alert("Refund Successfull");
      //alert.success("Refund Successfull");
    } catch (err) {
      alert("There has been a problem processing your request", err);
      //alert.error("There has been a problem processing your request");
    }
    dispatch(deleteAppointment(props.appointmentId));
    props.h.push("/appointments");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {loading ? (
          <div>
            <HashLoader
              css={override}
              size={50}
              color={"#403a60"}
              loading={loading}
            />
          </div>
        ) : (
          <p>Are you sure?</p>
        )}
      </Modal.Body>

      <Modal.Footer>
        {loading ? (
          <div></div>
        ) : (
          <>
            <button className="pApprove" onClick={setApp}>
              Yes
            </button>
            <button className="pApprove" onClick={props.onHide}>
              Cancel
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
export default AppointmentToReview;
