import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Services from "../../components/services";
import { getAppointmentDetails } from "../../redux/actions/data.actions";
import { modificationRequest } from "../../redux/actions/appointment.actions";
import moment from "moment";
import "./styles.css";

/**
 * @author
 * @function FutureAppointments
 **/
// appointmentIDtoSend
const FutureAppointments = (props) => {
  const [days, setDays] = useState("");
  const [hours, setHours] = useState("");
  const [mins, setMins] = useState("");
  const [displayDate, setDisplayDate] = useState("");
  const [modDescription, setModDescription] = useState("");
  const dispatch = useDispatch();
  var toCountdown = useSelector(
    (state) => state.nextAppointment.appointmentDate
  );
  const appointmentServices = useSelector(
    (state) => state.nextAppointment.appointmentServices
  );
  const [services, setServices] = useState([]);
  const appointmentID = useSelector(
    (state) => state.appointment.appointmentIDtoSend
  );
  const appointment = useSelector((state) => state.appointment);
  useEffect(() => {
    setDisplayDate(toCountdown);
    dispatch(getAppointmentDetails(appointmentID));
    var countDownDate = new Date(toCountdown).getTime();
    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      setDays(("0" + (days >= 0 ? days : 0)).slice(-2));
      setHours(("0" + (hours >= 0 ? hours : 0)).slice(-2));
      setMins(("0" + (minutes >= 0 ? minutes : 0)).slice(-2));
    }, 1000);
    return () => {
      clearInterval(x);
    };
  }, [toCountdown]);
  useEffect(() => {
    if (appointmentServices !== null) {
      setServices(appointmentServices);
    }
  }, [appointmentServices]);
  const requestModification = () => {
    const data = { modDescription, appointmentID };
    dispatch(modificationRequest(data));
  };

  const checkIfMeetingIsNow = () => {
    const aptDate = new Date(displayDate);
    const timeCondition = moment(aptDate).subtract(5, "minutes") <= moment();

    return timeCondition;
  };
  const shouldJoin = checkIfMeetingIsNow();
  return (
    <Container fluid className="p-0">
      {/* main banner */}
      <div
        className="bannerStyle text-center d-flex flex-column"
        style={{ position: "relative", marginBottom: 50, paddingBottom: 50 }}
      >
        <p className="bannerDate">Next Appointments</p>
        <button
          disabled={!shouldJoin}
          className={"cancelButtonDesign p-2 join-meeting-button "}
          onClick={() => {
            props.history.push("/call/" + appointmentID);
          }}
        >
          <div
            style={{ background: shouldJoin ? "#403a60" : "grey" }}
            className="join-meeting-inner"
          >
            Join meeting
          </div>
        </button>
        <Row className="text-center align-self-center">
          <Col className="coloumCountStyle">
            <h1 className="countdownStyle">{days}</h1>
            <p className="days pt-2">days</p>
          </Col>
          <Col className="coloumCountStyle">
            <h1 className="countdownStyle">{hours}</h1>
            <p className="days pt-2">hours</p>
          </Col>
          <Col className="coloumCountStyle">
            <h1 className="countdownStyle">{mins}</h1>
            <p className="days pt-2">minutes</p>
          </Col>
        </Row>
        <p className="bannerDate">
          {moment(displayDate).format("MMMM DD, YYYY hh:mm a")}
        </p>
      </div>
      {/* SERVICES */}
      <div>
        <div>
          <p className="serviceStyling">Services</p>
        </div>
        <row className="d-flex flex-row align-item-center">
          {services != null ? <Services services={services} /> : <></>}
        </row>
      </div>
      <p className="lineSeperater"></p>
      <div>
        <div>
          <p className="serviceStyling">Meeting Notes</p>
        </div>
        <row className="d-flex flex-row px-4">
          {appointment?.appointmentDescription?.description}
        </row>
      </div>

      <p className="lineSeperater"></p>
      {/* REQUEST MODIFICATION */}
      <div>
        <p className="serviceStyling">Request Modification</p>
        <div className="tDiv">
          <p className="dText">Description</p>
          <Form.Control
            className="textAreaStyle w-50"
            as="textarea"
            rows={3}
            onChange={(e) => setModDescription(e.target.value)}
          />
          <button
            type="button"
            className="modBtn p-2"
            onClick={requestModification}
          >
            Request modification
          </button>
        </div>
      </div>
    </Container>
  );
};

export default FutureAppointments;
