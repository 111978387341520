import React, { useState } from "react";
import { Button, Card, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.css";
import { closeModificationRequest } from "../../redux/actions/appointment.actions";
import { sendMail, sendMessage } from "../../redux/actions/data.actions";
import * as firebase from "firebase";
/**
 * @author
 * @function ModApprovalCard
 **/
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
const ModApprovalCard = (props) => {
  const dispatch = useDispatch();
  const [selectedMod, setSelectedMod] = useState(null);
  const [message, setMessage] = useState("");
  return (
    <div className="d-flex flex-row p-3">
      {props.dataToRender?.map((d) => (
        <Card className="mx-2">
          <Card.Body>
            <Card.Title>
              <Row>
                <p className="pName ml-3">{d.name}</p>
                <p className="pId mx-2">({d.id})</p>
                <p className="pDate ml-auto">{d.date}</p>
              </Row>
              {d.consultants?.map((c) => (
                <p className="inspec mb-3">{c.name}</p>
              ))}
            </Card.Title>

            <Card.Text>
              <p className="pSeperater"></p>
              {d.description}
            </Card.Text>
            <p className="pSeperater"></p>

            <button
              className="pApprove p-2"
              onClick={() => {
                dispatch(closeModificationRequest(d.id));
                props.refresh();
              }}
            >
              Mark Done
            </button>
            <button
              className="pApprove p-2 ml-4 bg-danger"
              onClick={() => {
                setSelectedMod(d);
              }}
            >
              Cancel
            </button>
          </Card.Body>
        </Card>
      ))}
      <Modal
        show={Boolean(selectedMod)}
        centered
        onHide={() => setSelectedMod(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send message to client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Message</Form.Label>
            <Form.Control
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              as="textarea"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="pApprove p-2"
            onClick={async () => {
              dispatch(closeModificationRequest(selectedMod.id));
              const res = await firebase.default
                .firestore()
                .collection("appointments")
                .doc(selectedMod.appointmentID)
                .get();
              const response = res.data();
              sendMail({
                body: message,
                sub: "Modification Request Response",
                dest: response.email,
              });
              sendMessage(message, response.phone);
              setSelectedMod(null);
              setMessage("");
              props.refresh();
            }}
          >
            Mark as done & Send
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModApprovalCard;
