import React, { useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PersonCards from "../../components/personCards";
import { getAppointments } from "../../redux/actions/data.actions";
import {
  getEveryAppointment,
  getModRequests,
} from "../../redux/actions/appointment.actions";
import hammer from "../../drawable/hammer.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.css";
import { getConsultantData } from "../../redux/actions/consultant.actions";
import "react-bootstrap-typeahead/css/Typeahead.css";
import TypeHeadComp from "../../components/typeHeadComponent";
import CurrentDiv from "../../components/ScrolingDivs/CurrentDiv";
import FutureAppointments from "../../components/ScrolingDivs/FutureAppointments";
import PastAppointments from "../../components/ScrolingDivs/PastAppointments";
import * as fire from "firebase";
import moment from "moment-timezone";
/**
 * @author
 * @function NewAppointments
 **/
export const TIMEZONE = "America/Chicago";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
const OperatorDashboard = (props) => {
  const dispatch = useDispatch();
  const [consultantName, setConsultantName] = useState([]);
  const [consultantId, setConsultantId] = useState([]);
  const data = useSelector((state) => state.appointment.appDataForOperator);
  const consultantData = useSelector(
    (state) => state.consultant.consultantData
  );
  const [modData, setModData] = useState([]);
  const modRequests = useSelector((state) => state.appointment);
  const appointmentRef = useSelector((state) => state.appointment.allAppsData);
  const appointmentsToShow = [];
  const [timeframe, setTimeframe] = useState(1);
  const [price, setPrice] = useState(1);

  useEffect(() => {
    dispatch(getEveryAppointment());
    dispatch(getAppointments());
    dispatch(getConsultantData());
    dispatch(getModRequests());
    getMeetingInfo();
  }, []);

  const getMeetingInfo = async () => {
    const db = fire.default.firestore();
    const res = await db.collection("meetingValues").doc("data").get();
    const val = res.data();
    setTimeframe(val.timeframe);
    setPrice(val.price);
  };
  useEffect(() => {
    var tempName = [];
    var tempId = [];
    if (consultantData !== null) {
      for (var i = 0; i < consultantData.length; i++) {
        tempName.push(consultantData[i].name);
        tempId.push(consultantData[i].id);
      }
      setConsultantName(consultantData);
      setConsultantId(tempId);
    }
  }, [consultantData]);
  useEffect(() => {
    setModData(modRequests.modRequestData);
  }, [modRequests]);
  const nextAppointments = appointmentRef
    ?.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    })
    ?.filter((e) => {
      const aptDate = new Date(e.date);
      const today = new Date();
      const meetingLength = Number(e.meetingLength) * Number(timeframe);
      // const timeCondition = aptDateInSeconds - (60*5) <= todayDateInSeconds &&
      const timeCondition =
        moment(aptDate).tz(TIMEZONE).subtract(5, "minutes") <= moment() &&
        moment(aptDate).tz(TIMEZONE).add(meetingLength, "minutes") >= moment();

      return timeCondition;
    });
  const futureApts = appointmentRef
    ?.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    })
    ?.filter((e) => {
      const aptDate = new Date(e.date);
      const today = new Date();
      const meetingLength = Number(e.meetingLength) * Number(timeframe);
      // const timeCondition = aptDateInSeconds - (60*5) <= todayDateInSeconds &&
      const timeCondition =
        moment(aptDate).tz(TIMEZONE).subtract(5, "minutes") <= moment() &&
        moment(aptDate).tz(TIMEZONE).add(meetingLength, "minutes") >= moment();

      return aptDate > today && !timeCondition;
    });
  const previousAppointments = appointmentRef
    ?.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    })
    ?.filter((e) => {
      const aptDate = new Date(e.date);
      const today = new Date();
      return aptDate < today;
    });
  return (
    <div className="p-4">
      {nextAppointments?.length > 0 ? (
        <CurrentDiv
          dates={nextAppointments}
          ids={nextAppointments.map((e) => e.id)}
          h={props.history}
        />
      ) : (
        <></>
      )}
      {/* FUTURE APPOINTMENTS */}
      {/* <NextAppointments dates={nextAppointments} /> */}
      {/* FUTURE APPOINTMENTS */}
      {futureApts?.length > 0 && (
        <FutureAppointments
          dates={futureApts}
          ids={futureApts.map((e) => e.id)}
          h={props.history}
        />
      )}

      {/* PAST APPOINTMENTS */}
      {previousAppointments?.length > 0 ? (
        <PastAppointments
          dates={previousAppointments}
          ids={previousAppointments.map((e) => e.id)}
          h={props.history}
        />
      ) : (
        <></>
      )}
      <p className="nApps">New Appointments</p>
      {data ? (
        data.length > 0 ? (
          <Carousel
            renderButtonGroupOutside
            responsive={responsive}
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            {data ? (
              data?.map((d, i) => (
                <Card key={i} className="m-2" style={{ height: "500px" }}>
                  <Card.Body>
                    <Card.Title>
                      <Row>
                        <p className="pName">{d.name}</p>
                        <p className="pDate ml-auto">{d.date}</p>
                      </Row>
                      <p className="pSeperater"></p>
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      <div
                        className="p-3 d-flex flex-row align-item-center"
                        style={{ overflowX: "scroll" }}
                      >
                        {d?.servicesProvided?.length > 0 ? (
                          d.servicesProvided.map((sName) => (
                            <div
                              className="servicePill p-2 d-flex  align-item-center "
                              style={{ minWidth: "185px" }}
                            >
                              <div className="d-flex flex-row align-item-center w-100 justify-content-center">
                                <img
                                  src={hammer}
                                  className="hammerIcon"
                                  alt="back"
                                />
                                <p className="servicetext">{sName}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </Card.Subtitle>

                    <Card.Text>
                      <p className="pSeperater"></p>
                      {d.description}
                    </Card.Text>
                    <p className="pSeperater"></p>

                    <TypeHeadComp
                      consultantName={consultantName}
                      appointmentId={d.id}
                      selectedServices={d.servicesProvided}
                    />
                  </Card.Body>
                </Card>
              ))
            ) : (
              <></>
            )}
          </Carousel>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {/* <p className="lineSeperater"></p>
      <p className="nApps">Appointments calendar</p>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        selectMirror={true}
        dayMaxEvents={true}
      /> */}
      <p className="lineSeperater"></p>
      <p className="nApps">Modification Requests</p>

      <PersonCards dataToRender={modData} h={props.history} />
    </div>
  );
};

export default OperatorDashboard;
