import React from "react";
import { Container, Jumbotron, Row, Col } from "react-bootstrap";
import MobileStoreButton from "react-mobile-store-button";
import appImg from "../../../drawable/multiImg.png";
import "./styles.css";
/**
 * @author
 * @function OurApp
 **/

const OurApp = (props) => {
  const iOSUrl = "https://apps.apple.com/us/app/cottage-manor/id1557473330";
  const androidUrl =
    "https://play.google.com/store/apps/details?id=com.cottageandmanor";
  return (
    <Container fluid>
      <Jumbotron className="ourAppDiv">
        <div className="ourAppDetailsStyle mt-5 pt-5">
          <div className="d-flex flex-lg-row flex-column align-items-center">
            <div style={{ flex: 1 }}>
              <h1 className="dOA pl-5">DOWNLOAD OUR APP</h1>
              <Row
                className=" pl-5 w-100"
                // style={{ position: "absolute", marginBottom: "500px" }}
              >
                <MobileStoreButton
                  width="145px"
                  store="android"
                  url={androidUrl}
                  linkProps={{ title: "iOS Store Button" }}
                />
                <div className="mt-2 ios-app-button">
                  <MobileStoreButton
                    store="ios"
                    url={iOSUrl}
                    linkProps={{ title: "iOS Store Button" }}
                    width="134px"
                  />
                </div>
              </Row>
            </div>

            <img
              src={appImg}
              className="w-25  mx-auto p-2"
              alt="back"
              style={{}}
            />
          </div>
        </div>
      </Jumbotron>
    </Container>
  );
};

export default OurApp;
