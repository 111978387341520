import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./styles.css";
import logo from "../../../drawable/Logo.png";
import { useDispatch, useSelector } from "react-redux";
/**
 * @author
 * @function ContinueSignUp
 **/

const ContinueSignUp = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth);
  useEffect(() => {
    setUserData(data.signUpData);
  }, [data]);

  const continueSignup = () => {
    if (password === confirmPassword) {
      console.log("ok");

      //props.history.push("/login");
    } else {
      console.log("not okay");
    }
  };
  return (
    <Container fluid>
      <Row>
        <Col className="px-3 px-md-0 mainContainer">
          <img src={logo} className="mLogo" alt="logo" />
          <Form className="w-50">
            <Form.Group className="nameTexts" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="nameTexts" controlId="formBasicPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            <button type="button" className="mBtn p-2" onClick={continueSignup}>
              CONTINUE
            </button>
          </Form>
        </Col>
        <Col className="d-none d-md-block">
          <div className="Login-component"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContinueSignUp;
