const initState = {
  appointmentDate: null,
  appointmentServices: null,
  appointmentId: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action) => {
  switch (action.type) {
    case "APPOINTMENT_DATE":
      return (state = {
        ...state,
        appointmentDate: action.payload,
      });
    case "APPOINTMENT_SERVICES":
      return (state = {
        ...state,
        appointmentServices: action.payload,
      });
    case "SEND_APP_ID_FOR_MODIFICATION":
      return (state = {
        ...state,
        appointmentId: action.payload,
      });

    default:
      return state;
  }
};
