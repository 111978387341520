import { authConstant } from "../constants";
import * as fire from "firebase";
import firebase from "../../firebase";

export const signUp = (data) => {
  return async (dispatch) => {
    var user = firebase.auth().currentUser;
    const db = fire.default.firestore();

    if (user) {
      var docRef = db.collection("users").doc(user.uid);
      docRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            console.log("user already Exists");
            dispatch({ type: "USER_ACCOUNT_STATUS_FOR_SIGNUP", payload: true });
          } else {
            dispatch({
              type: "USER_ACCOUNT_STATUS_FOR_SIGNUP",
              payload: false,
            });
            db.collection("users")
              .doc(user.uid)
              .set({
                email: data.email,
                uid: user.uid,
                phone: user.phoneNumber,
                createdAt: new Date(),
                type: "client",
              })
              .then(() => {
                const logedInUser = {
                  email: data.email,
                  uid: user.uid,
                  type: "client",
                };
                localStorage.setItem("user", JSON.stringify(logedInUser));
                dispatch({
                  type: `${authConstant.USER_LOGIN}_SUCCESS`,
                  payload: { user: logedInUser },
                });
              })
              .catch((e) => {
                console.log("Error while writing to database => " + e);
                dispatch({
                  type: `${authConstant.USER_LOGIN}_FAILURE`,
                  payload: { e },
                });
              });
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    } else {
      console.log("Not signed in");
    }
  };
};

export const login = (data) => {
  return async (dispatch) => {
    var user = firebase.auth().currentUser;
    console.log("user logged in", user);
    const phone = user.phoneNumber;
    const db = fire.default.firestore();
    var docRef = db.collection("users").doc(user.uid);
    await docRef
      .get()
      .then((doc) => {
        //check if user already exists
        if (doc.exists) {
          //if user already exists
          dispatch({ type: "USER_ACCOUNT_STATUS_FOR_LOGIN", payload: true });
          const logedInUser = {
            email: doc.data().email,
            uid: user.uid,
            type: doc.data().type,
            phone,
            consultantId: doc.data().consultantId,
          };
          localStorage.setItem("user", JSON.stringify(logedInUser));
          dispatch({
            type: `${authConstant.USER_LOGIN}_SUCCESS`,
            payload: { user: logedInUser },
          });
        } else {
          //if not then check if a consultant with the phone exists
          db.collection("consultants")
            .where("phone", "==", phone)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.size > 0) {
                //A consultant is found
                let result = null;
                querySnapshot.forEach(function (document, index) {
                  if (!result) {
                    const data = document.data();
                    result = data;
                  }
                });
                console.log({ result });
                //A consultant is found
                db.collection("users")
                  .doc(user.uid)
                  .set({
                    email: result.email,
                    uid: user.uid,
                    phone: phone,
                    createdAt: new Date(),
                    type: "consultant",
                    consultantId: result.id,
                  })
                  .then(() => {
                    const logedInUser = {
                      email: result.email,
                      uid: user.uid,
                      phone: phone,
                      type: "consultant",
                      consultantId: result.id,
                    };
                    console.log({ logedInUser });
                    localStorage.setItem("user", JSON.stringify(logedInUser));
                    dispatch({
                      type: `${authConstant.USER_LOGIN}_SUCCESS`,
                      payload: { user: logedInUser },
                    });
                    dispatch({
                      type: "USER_ACCOUNT_STATUS_FOR_LOGIN",
                      payload: true,
                    });
                  })
                  .catch((e) => {
                    console.log("user does not exist please sign up");
                    dispatch({
                      type: "USER_ACCOUNT_STATUS_FOR_LOGIN",
                      payload: false,
                    });
                  });
              } else {
                db.collection("users")
                  .doc(user.uid)
                  .set({
                    email: "",
                    uid: user.uid,
                    phone: phone,
                    createdAt: new Date(),
                    type: "client",
                  })
                  .then(() => {
                    const logedInUser = {
                      email: "",
                      uid: user.uid,
                      type: "client",
                    };
                    dispatch({
                      type: `${authConstant.USER_LOGIN}_SUCCESS`,
                      payload: { user: logedInUser },
                    });
                    dispatch({
                      type: "USER_ACCOUNT_STATUS_FOR_LOGIN",
                      payload: true,
                    });
                    // dispatch(APPOINTMENTS_ACTION_GET_APPOINTMENTS(logedInUser.uid, logedInUser.type));
                  })
                  .catch((e) => {
                    console.log("user does not exist please sign up");
                    dispatch({
                      type: "USER_ACCOUNT_STATUS_FOR_LOGIN",
                      payload: false,
                    });
                  });
              }
            })
            .catch((err) => {
              console.log(err);
              dispatch({
                type: "USER_ACCOUNT_STATUS_FOR_LOGIN",
                payload: false,
              });
            });
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };
};

export const isLogedIn = () => {
  return async (dispatch) => {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    if (user) {
      dispatch({
        type: `${authConstant.USER_LOGIN}_SUCCESS`,
        payload: { user },
      });
    } else {
      const tempUser = { email: "", type: "client", uid: "notAssigned" };
      localStorage.setItem("user", JSON.stringify(tempUser));
      dispatch({
        type: `${authConstant.USER_LOGIN}_SUCCESS`,
        payload: { user: tempUser },
      });
    }
  };
};
export const logoutUser = () => {
  return async (dispatch) => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        localStorage.clear();
        const tempUser = { email: "", type: "client", uid: "notAssigned" };
        localStorage.setItem("user", JSON.stringify(tempUser));
        alert("Signed out");
        dispatch({ type: "SIGNED_OUT" });
      })
      .catch(function (error) {
        console.log("error signing out", error);
      });
  };
};
