import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./styles.css";
import logo from "../../drawable/Logo.png";
import firebase from "../../firebase";
import { useDispatch } from "react-redux";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";
/**
 * @author
 * @function Login
 **/
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const Login = (props) => {
  const [loading, setloading] = useState(false);
  const [phone, setPhone] = useState("");
  const dispatch = useDispatch();
  const openRegistration = () => {
    props.history.push("/signup");
  };
  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
  }, []);
  const loginUser = async () => {
    setloading(true);
    const ph = "+" + phone;
    dispatch({ type: "PHONE_NUMBER", payload: ph });
    const res = await firebase
      .auth()
      .signInWithPhoneNumber(ph, window.recaptchaVerifier);
    dispatch({ type: "RESULT", payload: res });
    dispatch({ type: "LOGIN", payload: "LOGIN" });
    props.history.push("/code");
  };
  return (
    <Container fluid>
      {loading ? (
        <div
          style={{
            marginTop: "40vh",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <HashLoader css size={50} color={"#403a60"} loading={loading} />
        </div>
      ) : (
        <>
          <Row>
            <Col className="px-3 px-md-0 mainContainer">
              <img
                src={logo}
                className="mLogo mx-auto"
                alt="logo"
                onClick={() => {
                  props.history.push("/");
                }}
              />
              <Row className="w-100 justify-content-center mt-4">
                <Form as={Col} sm={12} md={6}>
                  <PhoneNumberInput
                    value={phone}
                    onChange={(val) =>
                      setPhone(!val.startsWith("1") ? "1" + val : val)
                    }
                  />
                  <button
                    type="button"
                    className="mBtn p-2"
                    onClick={loginUser}
                  >
                    LOGIN
                  </button>

                  <button
                    type="button"
                    className="mBtnRegister p-2"
                    onClick={openRegistration}
                  >
                    REGISTER NOW
                  </button>
                </Form>
              </Row>
            </Col>
            <Col className="d-none d-md-block p-0">
              <div className="Login-component"></div>
            </Col>
          </Row>
        </>
      )}
      <input
        id="recaptcha-container"
        type="button"
        onClick="this.onClick"
        className="d-none"
      />
    </Container>
  );
};

export default Login;
