import React from "react";
import clock from "../../drawable/darkClock.png";
import rightArrow from "../../drawable/darkArrow.png";
import * as firebase from "firebase";
import "./styles.css";
import "./styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { CancelRounded, CheckCircle } from "@material-ui/icons";
import {
  updateAppointmentwithConsultants,
  updateQualifiedConsultants,
} from "../../redux/actions/data.actions";
/**
 * @author
 * @function Requests
 **/

const Requests = (props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  return (
    <div>
      {props.dates.length > 0 ? (
        <p className="welcome p-3">Pending Requests</p>
      ) : (
        <p className="welcome p-3">No requests for now</p>
      )}

      <Carousel
        renderButtonGroupOutside
        responsive={responsive}
        removeArrowOnDeviceType={["tablet", "mobile"]}
      >
        {props.dates?.map((dates) => (
          <div
            // onClick={() => {
            //   dispatch({ type: "APPOINTMENT_DATE", payload: dates.date });
            //   dispatch({ type: "APPOINTMENT_ID", payload: dates.id });
            //   props.h.push("/futuretappointments");
            // }}
            className="futureDiv mx-5 p-2 my-5"
          >
            <div className="d-flex justify-between mb-2 h-100 ">
              <img src={clock} className="clockIcon" alt="back" />
              <p className="fDateText">
                {new Date(dates.date).toDateString() +
                  " - " +
                  new Date(dates.date).toTimeString()}
              </p>
              <div className="d-flex flex-column h-100 pr-1">
                <div className="mt-2">
                  <CheckCircle
                    onClick={async () => {
                      const consultant = await firebase.default
                        .firestore()
                        .collection("consultants")
                        .doc(auth.userData.user.consultantId)
                        .get();
                      dispatch(
                        updateAppointmentwithConsultants(
                          dates.id,
                          [consultant.data()],
                          "approved"
                        )
                      );
                    }}
                    style={{ color: "green" }}
                  />
                </div>
                <div className="mt-5">
                  {" "}
                  <CancelRounded
                    onClick={async () => {
                      const arr = [...dates.qualifiedConsultants].filter(
                        (e) => e.id !== auth.userData.user.consultantId
                      );
                      dispatch(updateQualifiedConsultants(dates.id, arr));
                    }}
                    style={{ color: "red" }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Requests;
