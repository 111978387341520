import deleteIcon from "../../drawable/deleteIcon.png";

import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import FormHelperText from "@material-ui/core/FormHelperText";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useDispatch } from "react-redux";
import { updateActiveStatus } from "../../redux/actions/consultant.actions";
/**
 * @author
 * @function TogleSwitches
 **/
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,

    "& + $track": {
      backgroundColor: "#B9B9B9",
    },
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#403A60",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const TogleSwitches = (props) => {
  const [check, setCheck] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setCheck(props.checkStatus);
  }, []);
  const handleCheck = () => {
    setCheck(!check);
    dispatch(updateActiveStatus(!check, props.docId));
  };
  return (
    <div className="ml-auto">
      <FormGroup className="d-flex flex-row">
        <FormControlLabel
          control={
            <IOSSwitch checked={check} onChange={handleCheck} name="checkedA" />
          }
        />
        <DeleteOutlineIcon
          className="mt-1"
          fontSize={"large"}
          style={{ color: "rgba(255, 0, 0, 0.4)", cursor: "pointer" }}
          onClick={props.onClick}
        />
      </FormGroup>
      <FormHelperText>{check ? "active" : "Disabled"}</FormHelperText>
    </div>
  );
};

export default TogleSwitches;
