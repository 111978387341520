import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import "./styles.css";
import { FormControlLabel, FormHelperText } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getConsultant,
  updateAvailability,
  updateConsultantData,
  updateServices,
} from "../../redux/actions/consultant.actions";
import ConsultantCalenderComp from "../../components/consultantCalender";
import { getServiceData } from "../../redux/actions/data.actions";
import { MDBCol, MDBIcon } from "mdbreact";
import { LightService } from "../../components/AllServices";
/**
 * @author
 * @function InspectorDetails
 **/
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,

    "& + $track": {
      backgroundColor: "#B9B9B9",
    },
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#403A60",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const ExpertConsultantDetails = (props) => {
  const consultantId = props.location.state;
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [services, setServices] = useState([]);
  const [check, setCheck] = useState(false);
  const [availability, setAvailability] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.consultant.consultant);
  const [selected, setSelected] = useState([]);
  const [service, setService] = useState([]);
  const [search, setSearch] = useState("");
  const [showAllServices, setShowAllServices] = useState([]);
  const [toShow, setToShow] = useState(0);
  const [showAll, setShowAll] = useState(0);
  const datas = useSelector((state) => state.data);
  useEffect(() => {
    if (datas.allServices !== null) {
      const servicesToPush = [];
      datas.allServices?.forEach((e) => servicesToPush.push(...e.services));
      setShowAllServices(servicesToPush);
    }
  }, [datas]);

  useEffect(() => {
    if (toShow === 0) {
      dispatch(getServiceData());
      setToShow(1);
    }
  }, []);
  const handleSelection = (e) => {
    if (e.target.value == 0) {
      setShowAll(0);
    } else {
      const name = e.target.value;
      const services = datas.allServices.find(
        (ser) => ser.name == name
      ).services;
      setService(services);
      setShowAll(name);
    }
    // if (e.target.value == 1) {
    //   dispatch(getFormData("Plumbing"));
    //   setShowAll(1);
    // } else if (e.target.value == 2) {
    //   dispatch(getFormData("electrician"));
    //   setShowAll(1);
    // }
  };
  useEffect(() => {
    dispatch(getConsultant(consultantId));
  }, []);

  useEffect(() => {
    if (data !== null) {
      setName(data.name);
      setPhone(data.phone);
      setEmail(data.email);
      setCheck(data.active);
      setAvailability(data.availability);
      setServices(data.services);
    }
  }, [data]);
  const updateConsultant = () => {
    const updatedData = { name, email, phone, check };
    dispatch(updateConsultantData(consultantId, updatedData));
  };
  const handleCheck = () => {
    setCheck(!check);
  };
  return (
    <Container fluid>
      <p className="MainName">Expert Consultant / {name} </p>
      <Form>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label className="text-left">Name</Form.Label>
            <Form.Control
              type="email"
              placeholder={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="email"
              placeholder={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
        </Form.Row>
      </Form>
      <Row>
        <div className="p-3">
          <FormControlLabel
            control={
              <IOSSwitch
                checked={check}
                onChange={handleCheck}
                name="checkedA"
              />
            }
          />
          <FormHelperText>{check ? "active" : "Disabled"}</FormHelperText>
        </div>
        <button className="deleteButton ml-auto p-2" onClick={updateConsultant}>
          Confirm
        </button>
      </Row>
      <p className="pSeperater"></p>
      <p className="MainName">Consultant Services </p>
      <Container className="mt-3 mx-auto">
        <Form.Row>
          <MDBCol>
            <div
              className="d-flex align-items-center px-2 "
              style={{ border: "solid 1px black", borderRadius: "4px" }}
            >
              <input
                className="form-control form-control-sm"
                type="text"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
                aria-label="Search"
                style={{ border: "none" }}
              />
              <MDBIcon
                icon="search"
                className="ml-2"
                style={{ cursor: "pointer" }}
              />
            </div>
          </MDBCol>
          <Col>
            <Form.Control
              as="select"
              className="mr-sm-2"
              id="inlineFormCustomSelect"
              custom
              onChange={handleSelection}
              style={{ border: "solid 1px black" }}
            >
              <option value="0">All Categories</option>
              {datas?.allServices?.map((e, i) => (
                <option value={e.name}>{e.name}</option>
              ))}
            </Form.Control>
          </Col>
        </Form.Row>
        <div>
          <Container
            fluid
            className="mt-2"
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              height: "272px",
            }}
          >
            <Row>
              {showAll === 0
                ? showAllServices
                    .filter((s) => s.name.includes(search))
                    ?.map((serviceData, i) => (
                      <LightService
                        key={i}
                        selected={services.includes(serviceData.name)}
                        onClick={() => {
                          const isSelected = services.includes(
                            serviceData.name
                          );
                          if (isSelected) {
                            setServices(
                              services.filter((e) => e !== serviceData.name)
                            );
                          } else {
                            setServices([...services, serviceData.name]);
                          }
                        }}
                        data={serviceData}
                      />
                    ))
                : service
                    ?.filter((s) => s.name.includes(search))
                    .map((serviceData) => (
                      <LightService
                        selected={services.includes(serviceData.name)}
                        onClick={() => {
                          const isSelected = services.includes(
                            serviceData.name
                          );
                          if (isSelected) {
                            setServices(
                              services.filter((e) => e !== serviceData.name)
                            );
                          } else {
                            setServices([...services, serviceData.name]);
                          }
                        }}
                        data={serviceData}
                      />
                    ))}
            </Row>
          </Container>
        </div>
      </Container>
      <Container fluid>
        <Row>
          <Col
            className="d-flex flex-row align-items-center justify-content-end"
            xs={12}
          >
            <button
              className="deleteButton p-2"
              onClick={async () => {
                dispatch(updateServices(consultantId, services));
              }}
            >
              Save Changes
            </button>
          </Col>
        </Row>
      </Container>
      <p className="pSeperater"></p>
      <p className="MainName">Consultant Availibility </p>
      {availability &&
        [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ].map((e) => {
          return (
            <Row>
              <Col md={4}>{e.charAt(0).toUpperCase() + e.slice(1)}</Col>
              <Col md={8} className="d-flex">
                <div className="p-2" style={{ flex: 1 }}>
                  <Form.Control
                    onChange={(event) => {
                      const obj = { ...availability };
                      obj[e].from = event.target.value;
                      setAvailability(obj);
                    }}
                    value={availability[e].from}
                  />
                </div>
                <div className="p-2" style={{ flex: 1 }}>
                  <Form.Control
                    onChange={(event) => {
                      const obj = { ...availability };
                      obj[e].to = event.target.value;
                      setAvailability(obj);
                    }}
                    value={availability[e].to}
                  />
                </div>
              </Col>
            </Row>
          );
        })}
      <div className="justify-content-end d-flex pt-4">
        <button
          className="deleteButton p-2"
          onClick={async () => {
            dispatch(updateAvailability(consultantId, availability));
          }}
        >
          Save Changes
        </button>
      </div>
      {/* <ConsultantCalenderComp appId={consultantId} /> */}
    </Container>
  );
};

export default ExpertConsultantDetails;
