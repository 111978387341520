import React, { useEffect, useState } from "react";

import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getEveryAppointment } from "../../redux/actions/appointment.actions";
/**
 * @author
 * @function AppointmentsCallender
 **/

const AppointmentsCallender = (props) => {
  const dispatch = useDispatch();
  const [allAppointments, setAllAppointments] = useState([]);
  const appointmentRef = useSelector((state) => state.appointment.allAppsData);
  const appointmentsToShow = [];
  useEffect(() => {
    dispatch(getEveryAppointment());
  }, []);
  useEffect(() => {
    if (appointmentRef != null) {
      setAllAppointments(appointmentRef);
    }
  }, [appointmentRef]);
  const handleDateSelect = (selectInfo) => {
    let title = "the title";
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };
  for (var i = 0; i < allAppointments.length; i++) {
    try {
      var date = new Date(allAppointments[i].date).toISOString();
      appointmentsToShow.push({
        id: allAppointments[i].id,
        title: "  Appointment by : " + allAppointments[i].name,
        date: date,
        eventTimeFormat: {
          // like '14:30:00'
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          meridiem: false,
        },
        realDate: allAppointments[i].date,
      });
    } catch (error) {
      console.log(error, allAppointments[i]);
    }
  }
  return (
    <div className="p-4">
      <p className="welcome">Appointments Calender</p>
      <div>{/* <Calendar fullscreen={true} /> */}</div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        select={handleDateSelect}
        eventContent={renderEventContent}
        events={appointmentsToShow}
        eventClick={(info) => {
          var today = new Date(); //Today Date
          var appDate = new Date(info.event.extendedProps.realDate);
          dispatch({
            type: "APPOINTMENT_DATE",
            payload: info.event.extendedProps.realDate,
          });
          if (today < appDate) {
            dispatch({
              type: "SEND_APP_ID_FOR_MODIFICATION",
              payload: info.event.id,
            });
            props.history.push("/appointmentstoreview");
          } else {
            dispatch({ type: "APPOINTMENT_ID", payload: info.event.id });
            props.history.push("/pastappointments");
          }
        }}
      />
      {/* <button type="button" className="mCallenderBtn p-2">
        Book an appointment
      </button> */}
    </div>
  );
};
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}
export default AppointmentsCallender;
