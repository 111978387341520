import React, { useState, useEffect } from "react";
import axios from "axios";
import Video from "twilio-video";
import Participant from "../../components/VideoCall/Participant";
import { connect, useSelector } from "react-redux";
import { APPOINTMENTS_ACTION_START_MEETING } from "../../redux/actions/appointment.actions";
import { useParams, withRouter } from "react-router-dom";
import { v4 } from "uuid";
import ReactSlick from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import DotLoader from "react-spinners/BeatLoader";
import { FcAddImage, FcCameraAddon, FcEndCall } from "react-icons/fc";
import Camera from "react-dom-camera";
import { Button, Form, Modal } from "react-bootstrap";
import { addNote } from "./AddNote";
import * as fire from "firebase";
import moment from "moment";
import twilioconfig from "../../twilioconfig";
const VideoCall = ({ joinMeeting, ...props }) => {
  const id = props.match.params.id;
  // const id = "vpn0mOAVwhkF7bTa08xc";
  const [roomObj, setRoomObj] = useState(null);
  const [connected, setConnected] = useState(false);
  const [fullWidth, setFullWidth] = useState(null);
  const [takeNoteModal, setTakeNoteModal] = useState(false);
  const [imageTaken, setImageTaken] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [secondsLeft, setSecondsLeft] = useState(1000);
  const [token, setToken] = useState(null);
  const [currentApt, setCurrentApt] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [deviceNotFound, setDeviceNotFound] = useState(null);
  const sid = useSelector((state) => state.appointment.appointmentSid);
  const username = useSelector((state) => state.auth.userData.user.email);
  const type = useSelector((state) => state.auth.userData.user?.type);
  const remoteParticipants = participants.map(
    (participant) =>
      fullWidth?.sid !== participant.sid && (
        <Participant
          setFullWidth={() => setFullWidth(participant)}
          key={participant.sid}
          participant={participant}
        />
      )
  );
  const init = async () => {
    await joinMeeting(id);
  };
  const joinRoom = async () => {
    if (sid) {
      const res = await axios.post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/createUserToken",
        {
          userName: v4() + "---" + username + "-" + type,
          roomName: id,
          apiKey: twilioconfig.apiKey,
          apiSecret: twilioconfig.apiSecret,
        }
      );

      setToken(res.data.result.token);
    }
  };
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    joinRoom();
  }, [sid]);

  const [timeframe, setTimeframe] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [price, setPrice] = useState(1);

  const getMeetingInfo = async () => {
    const db = fire.default.firestore();
    const res = await db.collection("meetingValues").doc("data").get();
    const val = res.data();
    const res2 = await db.collection("appointments").doc(id).get();
    const val2 = res2.data();
    setTimeframe(val.timeframe);
    setCurrentApt(val2);
    setPrice(val.price);
    timer(val2, val.timeframe);
    setLoaded(true);
  };
  useEffect(() => {
    getMeetingInfo();
  }, []);

  const timer = (val, tt) => {
    const aptDate = new Date(val?.date);
    aptDate.setMinutes(aptDate.getMinutes() + 1);
    const today = new Date();
    const meetingLength = Number(val?.meetingLength) * Number(tt);
    const timeCondition =
      moment(aptDate).subtract(5, "minutes") <= moment() &&
      moment(aptDate).add(meetingLength, "minutes") >= moment();
    if (timeCondition) {
      const seconds = moment(aptDate)
        .add(meetingLength, "minutes")
        .diff(moment(), "seconds");
      setSecondsLeft(seconds);
      if (seconds <= 0 && loaded) {
        disconnect();
      }
    } else {
      if (loaded) {
        disconnect();
      }
    }
    setTimeout(() => timer(val, tt), 1000);
  };
  //runs when we join the room
  useEffect(() => {
    try {
      if (token) {
        const participantConnected = (participant) => {
          setParticipants((prevParticipants) => {
            const participantIdsAndTypes = prevParticipants.map(
              (e) => e.identity.split("---")[1]
            );
            let shouldAddParticipant = !participantIdsAndTypes.includes(
              participant.identity.split("---")[1]
            );
            if (shouldAddParticipant) {
              shouldAddParticipant =
                participant.identity.split("---")[1] !== username + "-" + type;
            }
            return shouldAddParticipant
              ? [...prevParticipants, participant]
              : prevParticipants;
          });
        };
        const participantDisconnected = (participant) => {
          setParticipants((prevParticipants) => {
            return prevParticipants.filter((p) => p !== participant);
          });
        };
        Video.connect(token, {
          name: id,
        })
          .then((r) => {
            setRoomObj(r);
            r.on("participantConnected", participantConnected);
            r.on("participantDisconnected", participantDisconnected);
            r.participants.forEach(participantConnected);
            setFullWidth(r.localParticipant);
          })
          .catch((videoError) => {
            setDeviceNotFound(true);
          });
      } else {
        setParticipants([]);
      }
    } catch (error) {
      console.log(error);
    }
    window.addEventListener(
      "beforeunload",
      async () => {
        setRoomObj((currentRoom) => {
          if (
            currentRoom &&
            currentRoom.localParticipant.state === "connected"
          ) {
            currentRoom.localParticipant.tracks.forEach(function (
              trackPublication
            ) {
              trackPublication.track.stop();
            });
            currentRoom.disconnect();
            return null;
          } else {
            return currentRoom;
          }
        });
      },
      false
    );
    window.addEventListener(
      "onunload",
      async () => {
        setRoomObj((currentRoom) => {
          if (
            currentRoom &&
            currentRoom.localParticipant.state === "connected"
          ) {
            currentRoom.localParticipant.tracks.forEach(function (
              trackPublication
            ) {
              trackPublication.track.stop();
            });
            currentRoom.disconnect();
            return null;
          } else {
            return currentRoom;
          }
        });
      },
      false
    );
    return () => {
      setRoomObj((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [token]);

  const disconnect = async () => {
    setConnected(false);
    setToken(null);
    roomObj?.disconnect();
    props.history.goBack();
  };
  return deviceNotFound ? (
    <div
      style={{
        height: "90vh",
        width: "100vw",
        background: "rgba(0,0,0,0.9)",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>Please check if you have a working mic and camera and then refresh</p>
    </div>
  ) : roomObj && loaded ? (
    <div
      className="app"
      style={{ background: "rgba(0,0,0,0.9)", position: "relative" }}
    >
      <p className="text-center text-white">
        {secondsLeft <= 120 && secondsLeft > 60
          ? `${secondsLeft - 60} seconds left`
          : secondsLeft <= 60
          ? "meeting will end soon"
          : `${Number((secondsLeft - 60) / 60).toFixed(0)} : ${
              (secondsLeft - 60) % 60
            } minutes left`}
      </p>
      <div
        style={{
          height: "90vh",
          width: "100vw",
          overflowX: "hidden",
          paddingBottom: "10vh",
        }}
      >
        {fullWidth && (
          <Participant
            setFullWidth={() => setFullWidth(fullWidth)}
            fullWidth
            local={fullWidth?.sid == roomObj.localParticipant.sid}
            key={fullWidth?.sid}
            participant={fullWidth}
          />
        )}
        {remoteParticipants.length > 3 ? (
          <ReactSlick
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
            swipeToSlide
            className="w-100"
            infinite={false}
            dots={false}
            slidesToShow={4}
            slidesToScroll={1}
          >
            {fullWidth?.sid !== roomObj?.localParticipant?.sid ? (
              roomObj ? (
                <Participant
                  setFullWidth={() => setFullWidth(roomObj.localParticipant)}
                  local
                  key={roomObj.localParticipant.sid}
                  participant={roomObj.localParticipant}
                />
              ) : null
            ) : null}
            {remoteParticipants}
          </ReactSlick>
        ) : (
          <div className="d-flex">
            {fullWidth?.sid !== roomObj?.localParticipant?.sid ? (
              roomObj ? (
                <Participant
                  setFullWidth={() => setFullWidth(roomObj.localParticipant)}
                  local
                  key={roomObj.localParticipant.sid}
                  participant={roomObj.localParticipant}
                />
              ) : null
            ) : null}
            {remoteParticipants}
          </div>
        )}
        <p className="text-white text-center w-100 small">
          Swipe to see more people in the call
        </p>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          left: 0,
          height: "10vh",
          zIndex: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          background: "rgba(0,0,0,0.3)",
        }}
      >
        <button
          className="video-call-button"
          onClick={disconnect}
          type="button"
        >
          <FcEndCall size={30} />
        </button>
        {type == "client" && (
          <button
            className="video-call-button"
            onClick={() => setTakeNoteModal(true)}
            type="button"
          >
            <FcAddImage size={30} />
          </button>
        )}
        {/* <button
            className="video-call-button"
            onClick={() => setTakeNoteModal(true)}
            type="button"
          >
            <FcAddImage size={30} />
          </button>
              <ImVolumeMute onClick={toggleTrack} /> */}

        {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) && (
          <>
            <button
              className="video-call-button"
              onClick={async () => {
                roomObj.localParticipant.videoTracks
                  .entries()
                  .next()
                  .value[1].track.restart({ facingMode: "user" });
              }}
              type="button"
            >
              Front
            </button>
            <button
              className="video-call-button"
              onClick={async () => {
                roomObj.localParticipant.videoTracks
                  .entries()
                  .next()
                  .value[1].track.restart({ facingMode: "environment" });
              }}
              type="button"
            >
              Back
            </button>
          </>
        )}
      </div>
      <Modal show={takeNoteModal} onHide={() => setTakeNoteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Note Photo</p>
          {imageTaken ? (
            <>
              <img className="w-100" src={URL.createObjectURL(imageTaken)} />
              <Button onClick={() => setImageTaken(false)}>
                Take Image Again
              </Button>
            </>
          ) : (
            <Camera
              onTakePhoto={async (image) => {
                const blob = await fetch(image).then((r) => r.blob());
                setImageTaken(blob);
              }}
            />
          )}
          <Form.Group>
            <Form.Label>Enter Description</Form.Label>
            <Form.Control
              value={noteText}
              as="textarea"
              onChange={(e) => setNoteText(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setTakeNoteModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              const res = await addNote(
                id,
                URL.createObjectURL(imageTaken),
                noteText
              );
              if (res) {
                setImageTaken(null);
                setNoteText("");
                setTakeNoteModal(false);
              } else {
                window.alert("Image could not be uploaded");
              }
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "90vh",
        background: "rgba(0,0,0,0.9)",
      }}
    >
      <DotLoader size={50} color={"#fff"} loading={true} />
    </div>
  );
};

export default connect((state) => state, {
  joinMeeting: APPOINTMENTS_ACTION_START_MEETING,
})(withRouter(VideoCall));
