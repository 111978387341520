import axios from "axios";

export const sendPendingEmailToClient = (email, date, id) => {
  return async (dispatch) => {
    console.log({ email });
    const composedEmail = {
      dest: email,
      sub: "Your Appointment has been added",
      body:
        "Your appointment is pending approval for : " +
        date +
        " Your appointment Id is : " +
        id +
        "\n you can contact our staff at office@cottageandmanor.com for further details ",
    };

    axios
      .post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/sendMail",
        composedEmail
      )
      .then((res) => console.log(res.data));
    const secondEmail = {
      dest: "office@cottageandmanor.com",
      sub: "An appointment has been request",
      body:
        "An appointment is pending approval for : " +
        date +
        " The appointment Id is : " +
        id,
    };

    axios
      .post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/sendMail",
        secondEmail
      )
      .then((res) => console.log(res.data));
  };
};

export const sendApprovedEmailToClient = (email, date, id) => {
  return async (dispatch) => {
    console.log({ email });
    const composedEmail = {
      dest: email,
      sub: "Your Appointment has been Approved",
      body:
        "Your appointment has been approved for : " +
        date +
        " Your appointment Id is : " +
        id +
        "\n you can contact our staff at office@cottageandmanor.com for further details ",
    };

    axios
      .post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/sendMail",
        composedEmail
      )
      .then((res) => console.log(res.data));
  };
};

export const sendCanceledEmailToClient = (email, date, id) => {
  return async (dispatch) => {
    console.log({ email });
    const composedEmail = {
      dest: email,
      sub: "Your Appointment has been canceled",
      body:
        "Your appointment on : " +
        date +
        " with Id : " +
        id +
        "\n Has been canceled. \nyou can contact our staff at office@cottageandmanor.com for further details ",
    };

    axios
      .post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/sendMail",
        composedEmail
      )
      .then((res) => console.log(res.data));
  };
};

export const sendUpdateEmailToClient = (email, oldDate, newDat, id) => {
  return async (dispatch) => {
    console.log({ email });
    const composedEmail = {
      dest: email,
      sub: "Your Appointment has been moved",
      body:
        "Your appointment on : " +
        oldDate +
        "has been moved to : " +
        newDat +
        " with Id : " +
        id +
        "\nyou can contact our staff at office@cottageandmanor.com for further details ",
    };

    axios
      .post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/sendMail",
        composedEmail
      )
      .then((res) => console.log(res.data));
  };
};
export const sendEmailToOperator = (date, id) => {
  return async (dispatch) => {
    const composedEmail = {
      dest: "mohsin.rana.399990@gmail.com",
      sub: "A new appointment has been added",
      body:
        "appointment is pending approval for : " +
        date +
        "appointment Id is : " +
        id,
    };

    axios
      .post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/sendMail",
        composedEmail
      )
      .then((res) => console.log(res.data));
  };
};
export const sendContactEmail = (data) => {
  return async (dispatch) => {
    const composedEmail = {
      dest: "office@cottageandmanor.com",
      sub: "Customer support email ",
      body:
        "Email from customer : " +
        data.mes +
        " Customer info is, EMAIL : " +
        data.mail +
        " Phone number : " +
        data.ph,
    };

    axios
      .post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/sendMail",
        composedEmail
      )
      .then((res) => console.log(res.data));
  };
};
export const sendEmailToConsultant = (email, date, id, sub, body) => {
  return async (dispatch) => {
    const composedEmail = {
      dest: email,
      sub: sub || "You have a new Appointment",
      body:
        body ||
        "appointment is pending approval for : " +
          date +
          "appointment Id is : " +
          id,
    };

    axios
      .post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/sendMail",
        composedEmail
      )
      .then((res) => console.log(res.data));
  };
};
export const sendUpdateToConsultant = (email, oldDate, newDat, id) => {
  return async (dispatch) => {
    const composedEmail = {
      dest: email,
      sub: "You appointment has been moved",
      body:
        "Your appointment on : " +
        oldDate +
        " has been moved to :" +
        newDat +
        "appointment Id is : " +
        id,
    };

    axios
      .post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/sendMail",
        composedEmail
      )
      .then((res) => console.log(res.data));
  };
};
export const sendCancelEmailToConsultant = (email, date, id) => {
  return async (dispatch) => {
    const composedEmail = {
      dest: email,
      sub: "Your appointment has been canceled",
      body:
        "Your appointment on : " +
        date +
        "with Id : " +
        id +
        "Has been canceled. contact operator@cottagesMannor.com for further details",
    };

    axios
      .post(
        "https://us-central1-cottage-and-manor.cloudfunctions.net/sendMail",
        composedEmail
      )
      .then((res) => console.log(res.data));
  };
};
