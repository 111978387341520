import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "../../Pages/LoginPage";
import Confermation from "../../Pages/registration/ConfermationPage";
import InputCode from "../../Pages/registration/InputCodePage";
import SignUp from "../../Pages/registration/SignUpPage";
import ContinueSignUp from "../../Pages/registration/ContinueSignUp";
import DashBoard from "../../Pages/dashBoard";
import PastAppointments from "../../Pages/pastAppointments";
import FutureAppointments from "../../Pages/futureAppointments";

import Videocall from "../../Pages/videocall";
import LandingPage from "../../Pages/LandingPage";
import React from "react";
import PrivateRoute from "../../PrivateRoute";
import AccountInfo from "../../Pages/accountInfo/AccountInfo";

/**
 * @author
 * @function ClientRoute
 **/

const ClientRoute = (props) => {
  return (
    <div>
      <PrivateRoute path="/dash" exact component={DashBoard} />
      <PrivateRoute path="/account" exact component={AccountInfo} />

      <PrivateRoute
        path="/pastappointments"
        exact
        component={PastAppointments}
      />
      {/* <Route path="/" exact component={LandingPage} /> */}

      <PrivateRoute path="/call/:id" exact component={Videocall} />
      <PrivateRoute
        path="/futuretappointments"
        exact
        component={FutureAppointments}
      />
    </div>
  );
};

export default ClientRoute;
