import React from "react";
import clock from "../../drawable/darkClock.png";
import rightArrow from "../../drawable/darkArrow.png";

import "./styles.css";
import "./styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
/**
 * @author
 * @function FutureAppointments
 **/

const FutureAppointments = (props) => {
  const auth = useSelector((state) => state.auth);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const dispatch = useDispatch();

  return (
    <div>
      {props.dates.length > 0 ? (
        <p className="welcome p-3">Future appointments</p>
      ) : (
        <p className="welcome p-3">No Future appointments</p>
      )}
      <Carousel
        renderButtonGroupOutside
        responsive={responsive}
        removeArrowOnDeviceType={["tablet", "mobile"]}
      >
        {props.dates?.map((dates) => (
          <div
            onClick={() => {
              if (auth.userData.user.type == "operator") {
                dispatch({
                  type: "SEND_APP_ID_FOR_MODIFICATION",
                  payload: dates.id,
                });
                props.h.push("/appointmentstoreview");
              } else {
                dispatch({ type: "APPOINTMENT_DATE", payload: dates.date });
                dispatch({ type: "APPOINTMENT_ID", payload: dates.id });
                props.h.push("/futuretappointments");
              }
            }}
            className="futureDiv mx-5 p-2 my-5"
          >
            <div className="d-flex justify-between mb-2">
              <img src={clock} className="clockIcon" alt="back" />
              <p className="fDateText">
                {new Date(dates.date).toDateString() +
                  " - " +
                  new Date(dates.date).toTimeString()}
              </p>
              <img src={rightArrow} className="arrowIcon" alt="back" />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default FutureAppointments;
