import React from "react";
import { Redirect, Route } from "react-router-dom";
import * as fire from "firebase";
import { connect } from "react-redux";
/**
 * @author
 * @function PrivateRoute
 **/

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={(props) => {
        const user = rest.userData;
        if (user) {
          return <Component {...props} />;
        } else {
          return <Redirect to={`/login`} />;
        }
      }}
    />
  );
};

export default connect((state) => state.auth)(PrivateRoute);
