import React, { useState, useEffect, useRef } from "react";
import { BsFullscreen } from "react-icons/bs";

const Participant = ({ participant, local, fullWidth, setFullWidth }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [muted, setMuted] = useState(true);
  const videoRef = useRef();
  const audioRef = useRef();
  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);
  useEffect(() => {
    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((tracks) => [...tracks, track]);
      } else {
        setAudioTracks((tracks) => [...tracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((tracks) => tracks.filter((v) => v !== track));
      } else {
        setAudioTracks((tracks) => tracks.filter((a) => a !== track));
      }
    };

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));
    console.log(participant);
    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);
  useEffect(() => {
    try {
      const videoTrack = videoTracks[0];
      if (videoTrack) {
        videoTrack.attach(videoRef.current);
        return () => {
          videoTrack.detach();
        };
      }
    } catch (error) {
      console.log(error);
    }
  }, [videoTracks]);
  useEffect(() => {
    try {
      const audioTrack = audioTracks[0];
      if (audioTrack) {
        setMuted(false);
        audioTrack.attach(audioRef.current);
        return () => {
          setMuted(true);
          audioTrack.detach();
        };
      }
    } catch (error) {
      console.log(error);
    }
  }, [audioTracks]);
  return (
    <div
      style={{
        height: fullWidth ? "80vh" : "25vh",
        width: "100%",
        maxWidth: fullWidth ? "100%" : 300,
        position: "relative",
      }}
      className={"d-flex align-items-center justify-content-center p-3 "}
    >
      {!fullWidth && (
        <div
          style={{
            position: "absolute",
            top: 30,
            right: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0,0,0,0.3)",
            padding: 10,
            zIndex: 19,
            borderRadius: 3,
          }}
        >
          <BsFullscreen onClick={setFullWidth} color="#fff" />
        </div>
      )}
      <video
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          maxWidth: 1000,
          borderRadius: fullWidth ? 20 : 10,
          boxShadow: "0 0 10px rgba(255,255,255,0.1)",
        }}
        ref={videoRef}
        autoPlay={true}
        muted={muted}
      />
      <audio ref={audioRef} autoPlay={true} muted={local ? true : muted} />
    </div>
  );
};
export default Participant;
